<template>
  <div class="videoBg">
    <div class="video">
      <video controls autoplay loop muted playsinline :src="video"></video>
      <div class="video-footer">
        <div class="turkiye">
          <img src="images/icn-tr.svg" alt="" /><span>Türkiye</span>
        </div>
        <div class="kartlar">
          <img src="images/icn-visa.svg" alt="visa" />
          <img src="images/icn-mastercard.svg" alt="icn-mastercard" />
          <img src="images/icn-troy.svg" alt="troy" />
          <img src="images/icn-bkm.svg" alt="express" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Video',
  data() {
    return {
      video: 'video.mp4?v=1',
    }
  },
}
</script>
