<template>
  <div class="zamanBg">
    <div class="container container2">
        
        <!-- newProd2023 -->
        <div class="newProds2023">
            
        <template v-for="(item, index) in packets">

            <div class="item"  :key="index">
                <div class="visual" :style="{'background-image': 'url(http://crm.flyzoneturkey.com/upload/'+item[0].ProductImage2+ ')',}">
                    {{ item[0].ProductName }}
                </div>

                <div class="textInfo">
                    <strong @click="mobileAccordion">{{ item[0].ProductName }}<i></i></strong>
                    <p>
                         {{ item[0].ProductDsc }}
                    </p>
                </div>

                <div class="priceInfo">
                    <div class="pricePanel" v-if="!['Party Flight', 'Group Flight', 'Event Flight'].includes(item[0].ProductName)">
                        <div class="oldPrice">{{ item[0].Price }} ₺</div>
                        <div class="rate">%10 İndirim</div>
                        <div class="discountPrice">{{ calculateDiscount(item[0].Price) }} ₺</div>
                    </div>
                    <div class="pricePanel" v-else >
                        <div class="rate">&nbsp;</div>
                        <div class="rate">&nbsp;</div>
                        <div class="discountPrice">&nbsp;</div>
                    </div>
                    <div class="btnPanel" v-if="['Party Flight', 'Group Flight', 'Event Flight'].includes(item[0].ProductName)">
                        <a @click="paketSec(item)">Bilgi Al</a>
                    </div>
                    <div class="btnPanel" v-else>
                        <a @click="paketSec(item)">SEÇ</a>
                    </div>
                </div>

            </div>
        </template>
       
        </div>
        <!-- /newProd2023 -->
        <!-- Packages2023 --><!--v-if="extremePackets.length > 0"-->
        <div class="newPack2023" >
            <div class="title">
                <i><img src="/images/paketler/packIco.svg" alt=""></i>
                Paketler
            </div>

        <template v-for="cmp in extremePackets">

            <div class="packItem"  :key="cmp.CampaignId">
                <div class="title">
                    {{ cmp.Name }}                    
                    <div class="priceTag">
                        <span class="oldPrice">{{ calculateExtremeOldTotal(cmp.CampaignId) }} ₺</span>
                        <div>{{  calculateExtremeTotal(cmp.CampaignId) }}  ₺</div>
                    </div>
                </div>
                <div class="textInfo">
                    <div class="feature">
                        
                        <template v-for="cmpd in cmp.CampaignDetailList">

                            <div class="item"  :key="cmpd.CampaignDetailId">
                                {{ cmpd.ProductName }}
                                <br> 
                                <span>({{ cmpd.ProductNameExt }})</span>
                            </div>
                        </template>
                        <!-- <div class="item">
                            ROPEPLAY<br>
                            <span>(60dk)</span>
                        </div>
                        <div class="item">
                            MAX SLIDE<br>
                            <span>3 Kullanım</span>
                        </div> -->
                    </div>
                    <div class="btn">
                        <a @click="kampanyaSec(cmp)">SEÇ</a>
                    </div>
                </div>
            </div>
        </template>
             <div class="packItem">
                <div class="title">Flyzone Yılıık Sınırsız Kart 
                    <div class="priceTag" style="right: 0; bottom:10px; background:unset; width:unset; height:unset;">
                       <img src="/images/kartlaryeni.png" width="200">
                    </div>
                </div>
                <div class="textInfo">
                    <div class="feature">
                        <div class="item">
                            4 Giriş Fiyatına 365 Gün Eğlence<br> 
                            <span>SINIRLI SAYIDA</span>
                        </div>
               
                    </div>
                    <div class="btn">
                        <a href="/yillik-kart-odeme">SEÇ</a>
                    </div>
                </div>

            </div> 
        </div>
        <!-- /Packages2023 -->








    </div>
        


  </div>
</template>
<style >
    body { overflow: auto!important;}
</style>
<script>
import Footer from './Footer'
export default {
  name: 'paketDataNew',
  components: { Footer },
  data() {
    return {
      packets: [],
      extremePackets: [],
    }
  },
  created() {
    if (localStorage.paket !== undefined) {
      localStorage.removeItem('paket')
    }
    this.packets = JSON.parse(localStorage.getItem('tickets'))
    this.packets = this.packets.reduce((r, a) => { r[a.ProductName] = [...(r[a.ProductName] || []), a]; return r; }, {})
    
    this.extremePackets = JSON.parse(localStorage.getItem('extremeTickets'))
  },
  methods: {

    mobileAccordion(event){
        const item = document.querySelectorAll(".newProds2023 .item")
        for (let i = 0; i < item.length; i++) {
            item[i].classList.remove("open");
        }
        console.log(item.length)
        // item.classList.remove("open");

        const currItem = event.target.parentElement.parentElement;        
        currItem.classList.add("open");

    },
    calculateDiscount(total) {
            let finalPrice =  Number(total) - Math.round((Number(total) * 0.1) )
            return finalPrice
    },
    calculateExtremeTotal(campaignID) {
        var item = this.extremePackets.find(x=> x.CampaignId == campaignID)
        let finalPrice =0
        for (let i = 0; i < item.CampaignDetailList.length; i++) {
            finalPrice += item.CampaignDetailList[i].DiscountedPrice
        }
            //let finalPrice = Math.round( Number(total) - (Number(total) * 0.1) )
            return finalPrice
    },
    calculateExtremeOldTotal(campaignID) {
        var item = this.extremePackets.find(x=> x.CampaignId == campaignID)
        let finalPrice =0
        for (let i = 0; i < item.CampaignDetailList.length; i++) {
            finalPrice += item.CampaignDetailList[i].ProductPrice
        }
            //let finalPrice = Math.round( Number(total) - (Number(total) * 0.1) )
            return finalPrice
    },
    paketSec(e) {
      window.localStorage.setItem('paket', JSON.stringify(e))
      window.localStorage.setItem('secilipaket', JSON.stringify(e[0]))
      
      // this.$root.ticketType = e.CategoryId 
     window.localStorage.setItem('ticketType', parseInt(e.CategoryId));
     this.$root.ticketType = parseInt(window.localStorage.getItem('ticketType'));
     // commit test

      
      if(['Party Flight', 'Group Flight', 'Event Flight'].includes(e[0].ProductName)) {
        this.$router.push('/form')
      } else {
        this.$router.push('/gelenler')
      }
    },
    kampanyaSec(e) {
      //this.$root.ticketType = 10 // extreme kampanyalar..
        window.localStorage.setItem('ticketType', parseInt(10))
        this.$root.ticketType = parseInt(window.localStorage.getItem('ticketType'));


        let alltickets = JSON.parse(localStorage.getItem('tickets'))
        for (let i = 0; i < e.CampaignDetailList.length; i++) {
            
            let  ticketPr = alltickets.find(x=> x.ProductID ===e.CampaignDetailList[i].ProductId)
            if(ticketPr.CategoryId === 1){
                this.$root.dakika = ticketPr.Duration
                this.$root.name = ticketPr.Name
                
                window.localStorage.setItem('ucret', this.calculateExtremeOldTotal(e.CampaignId))
                this.$root.ucret = parseFloat(window.localStorage.getItem('ucret'));
                //this.$root.ucret = this.calculateExtremeOldTotal(e.CampaignId)

                this.$root.itemid = ticketPr.ProductID
                window.localStorage.setItem('secilipaket', JSON.stringify(ticketPr))
                var newPackets= []
                newPackets.push(ticketPr)
                window.localStorage.setItem('paket', JSON.stringify(newPackets))
                
                break
            }
        }
      
      window.localStorage.setItem('seciliKampanya', JSON.stringify(e))
      this.$router.push('/gelenler')
    },
  },
  props: {},
}
</script>
