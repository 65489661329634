<template>
  <div>
    <div class="load" style="background:black;" v-if="true">
      <div class="centered-container">
        <div class="loader-logo-row">
          <img :src="redirectionObj.logo" alt="">
        </div>
        <div class="loader-row-one">
          <img class="left-wing" src="/images/left-wing.png" alt="" />
          <div class="loadImgs winged-loader">
            <img class="fly-person-loader" src="/images/flyperson.png" alt="" />
            <img src="/images/loading.png" class="loadImg" alt="" />
          </div>
          <img class="right-wing" src="/images/right-wing.png" alt="" />
        </div>
        <div class="loader-row-two">
          <center class="loader-info-text">{{redirectionObj.brancheName}} >>> {{redirectionObj.packageName}}</center>
          <center class="redirecting-text">{{redirectionObj.redirectText}}</center>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import $ from 'jquery'


export default {
  name: 'BiletAl',
  data() {
    return {
      loader: false,
      redirectionObj:{
        brancheName: 'Demirören Flyzone',
        packageName: 'Paket seçimi',
        redirectText: 'adımına yönlendiriliyorsunuz…',
        logo: '/images/mallofistanbulLogo.png'
      },
      goToForm: false,
      formPackets: ['eventflight', 'groupflight', 'partyflight','nightflight','kidflight'],
      selectedBranch: {},
      incomingParam: null,
      parts: []
    }
  },
  created() {
    (async ()=> {
    window.localStorage.clear()
      await this.readParams()
      await this.setLoaderInfo()
      
      await this.sleep(2000)
      if(this.parts[0] != 'kampanya') {
        await this.parseParams()
      }
    })()
  },
  computed: {
    ...mapState(['branches', 'packages']),
  },
  methods: {
    async setLoaderInfo() {
      if(this.parts[0] == 'kampanya' && this.parts[1] == '10_Bilet_Yerine_12_Bilet') {
        this.$root.bulkTicket = { tickets: 12, CampaignId: 40 };
        window.localStorage.setItem('bulkTicket', JSON.stringify(this.$root.bulkTicket))
        this.redirectionObj.logo = '/images/topluPaket.png'
        this.redirectionObj.brancheName = '10 Bilet Yerine'
        this.redirectionObj.packageName = '12 Bilet Al'
        this.redirectionObj.redirectText = 'Şube seçim adımına yönlendiriliyorsunuz..'
        await this.sleep(2000)
        this.$router.push("/nerede");
      } else if(this.parts[0] == 'kampanya' && this.parts[1] == '20_Bilet_Yerine_25_Bilet') {
        this.$root.bulkTicket = { tickets: 25, CampaignId: 41 };
        window.localStorage.setItem('bulkTicket', JSON.stringify(this.$root.bulkTicket))
        this.redirectionObj.logo = '/images/topluPaket.png'
        this.redirectionObj.brancheName = '20 Bilet Yerine'
        this.redirectionObj.packageName = '25 Bilet Al'
        this.redirectionObj.redirectText = 'Şube seçim adımına yönlendiriliyorsunuz..'
        await this.sleep(2000)
        this.$router.push("/nerede");
      } else if(this.parts.length === 2 && this.branches[this.parts[0]] && this.packages[this.parts[1]]) {
        this.redirectionObj.brancheName = this.branches[this.parts[0]].name
        this.redirectionObj.logo = this.branches[this.parts[0]].logo
        this.redirectionObj.packageName = this.packages[this.parts[1]].name
        this.redirectionObj.redirectText = 'bilet seçimi adımına yönlendiriliyorsunuz..'
        this.selectedBranch = this.branches[this.parts[0]]
      } else if(this.parts.length === 1 && this.branches[this.parts[0]]) {
        this.redirectionObj.brancheName = this.branches[this.parts[0]].name
        this.redirectionObj.logo = this.branches[this.parts[0]].logo
        this.redirectionObj.packageName = ' Paket Seçimi'
        this.redirectionObj.redirectText = 'adımına yönlendiriliyorsunuz..'
        this.selectedBranch = this.branches[this.parts[0]]
      } else {
        this.$router.push('/')
      }
    },

    dataMustBeShown(){
      var thisComponent = this;

      const data = {territoryId: this.selectedBranch.id}
      
      $.ajax({
        url: `${process.env.VUE_APP_ENV_SERVER}/Services/FlyzoneCrm/B2CServices/GetContentB2C`,
        method:"post", 
        data: data,
        contentType: 'application/x-www-form-urlencoded', 
        dataType: 'json' ,
        success:
          function(response){
             (async ()=> {
            await thisComponent.showMustGoOn(response)
              })()
        }});


    },
    async showMustGoOn(response) {

          const branch = this.selectedBranch;


          localStorage.setItem('tickets',JSON.stringify(response.ticketProducts))
          localStorage.setItem('products',JSON.stringify(response.gitfProducts))

          let extremeCampaigns = [];
          if(response.campaigns != null){
              extremeCampaigns = response.campaigns.filter(x => x.CampaignTypeId == 2)
              localStorage.setItem('extremeTickets', JSON.stringify(extremeCampaigns))

          }
          localStorage.setItem('content', JSON.stringify(response))
          if(this.$root.bulkTicket != null) {
            const selectedCampaign = response.campaigns.find(x => x.CampaignId == this.$root.bulkTicket.CampaignId)
            localStorage.setItem('campaign', JSON.stringify(selectedCampaign))
          }
          window.localStorage.setItem('konum', branch.name)
          window.localStorage.setItem('adres', branch.adres)
          window.localStorage.setItem('subeTelefon', branch.telefon)
          window.localStorage.setItem('konumId', branch.id)
          await this.setPlan(this.parts[0], this.parts[1])
          var selectedPackage = localStorage.getItem('paket')
          if( this.goToForm == true) {
            this.$router.push('/form')
            return
          }
          if(this.parts.length == 1 || selectedPackage === null) {
              this.$router.push('/paketler')   
          } else if( this.parts.length == 2) {
            this.$router.push('/gelenler')
          }

    },

    
    async parseParams() {
      // await this.loadContent()
      await this.dataMustBeShown()
      
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async setPlan(location, selectedPackage) {
      window.localStorage.setItem('konum', this.branches[location].name)
      window.localStorage.setItem('konumId', this.branches[location].id)
      if(selectedPackage) {
        let rawPackets = JSON.parse(localStorage.getItem('tickets'))
        let packets = rawPackets.reduce((r, a) => { r[a.ProductName] = [...(r[a.ProductName] || []), a]; return r; }, {})
        for (const packet in packets) {
          const ProductName = packets[packet][0].ProductName.replace(/\s/g, '')
          if (selectedPackage.toLowerCase() === ProductName.toLowerCase()) {
            window.localStorage.setItem('paket', JSON.stringify(packets[packet]))
            if(this.formPackets.includes(ProductName.toLowerCase())){
              this.goToForm = true
            }
            return
          }
        }
      } return
    },
    async readParams() {
      this.incomingParam = this.$route.params.plan
      this.parts = this.incomingParam.split('-')
    }
  },
}
</script>

<style>
.fly-person-loader {
  max-height: 150% !important;
  width: 100% !important;
  position: absolute;
  margin-left: -8% !important;
  margin-top: 8% !important;
  z-index: 5 !important;
}
.winged-loader {
  margin: unset !important;
}

.centered-container {
  margin: 0 auto;
  text-align: center;
}
.left-wing {
  max-width: 100%;
  height: auto;
}
.right-wing {
  max-width: 100%;
  height: auto;
}
.loader-row-one {
  text-align: center;
  display: flex;
  align-items: center;
}

.loader-logo-row img {
  background: white;
  border-radius: 5px;
  padding: 5px 0 ;
  margin: 5% 0;
  width: 45%;
}
.loader-row-two {
  margin-top: 5%;
}
.loader-info-text {
  color: white;
  font-style: oblique;
  font-size: 25px;
  font-weight: 700;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.redirecting-text {
  color: white;
  font-style: oblique;
  font-size: 20px;
  font-weight: 300;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
</style>
