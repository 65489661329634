<template>
<div style="display:table"  v-if="$root.ticketType !== 10">
    <div class="sepet" :class="{'open': this.sepetOpen}">
        <div class="mobil-sepet-ok" @click="sepetKapat">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->
                <title>2BF005A4-555A-425F-913B-AD88C418B29E</title>
                <desc>Created with sketchtool.</desc>
                <g id="Güzel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="icn-right" fill="#ffffff" fill-rule="nonzero">
                        <g id="right-arrow-(4)-copy" transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) translate(6.000000, 0.000000)">
                            <path d="M1.352657,23.7517241 C1.19806763,23.9172414 1.00483092,24 0.792270531,24 C0.579710145,24 0.38647343,23.9172414 0.231884058,23.7517241 C-0.077294686,23.4206897 -0.077294686,22.8827586 0.231884058,22.5517241 L10.0869565,12 L0.231884058,1.44827586 C-0.077294686,1.11724138 -0.077294686,0.579310345 0.231884058,0.248275862 C0.541062802,-0.0827586207 1.04347826,-0.0827586207 1.352657,0.248275862 L11.7681159,11.4 C12.0772947,11.7310345 12.0772947,12.2689655 11.7681159,12.6 L1.352657,23.7517241 L1.352657,23.7517241 Z" id="Path" transform="translate(6.000000, 12.000000) rotate(-180.000000) translate(-6.000000, -12.000000) "></path>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
        <div class="sepetBaslik">
            <div class="sepetIcerik">
            <h1 v-text="this.konum"></h1>
            <h5 v-text="this.adres"></h5>
            <h5 v-text="this.telefon"></h5>
            </div>
            <div class="sepetTarih">
                <section v-if="!campaign && $root.ticketType === 1 && $root.tarih !== undefined && $root.tarih !== null"> 
                    <div v-text="$root.tarih"></div>
                    <span v-text="$root.saat"></span>
                    <span> - </span>
                    <span v-text="$root.bitisSaat"></span>
                </section>
                <section v-else-if="campaign">
                    <span>{{campaign.Name}}</span>
                </section>
                <section v-else-if="extreme">
                    <span>{{extreme.Name}}</span>
                </section>
                <div v-if="$root.bulkTicket || $root.ticketType === 5" class="sepetIcerik">
                    <ul>
                        <li>Biletler 1 sene içinde kullanılabilir</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="sepetIcerik">
            <ul v-if="$route.path !== '/sepetim'">
                <li v-if="$root.personCount>0">
                    <span class="xSayi">{{$root.personCount}} x</span>
                    <span class="carpan" v-if="$root.ticketType === 1">{{$root.dakika}}'dk </span> 
                    <span class="carpan" v-if="$root.ticketType === 5">{{$root.kullanimSayisi}}'Kul.</span> 
                    <span class="yPaket" v-text="$root.name"></span>
                    <span class="gelenTur strikethrough">{{$root.yetiskinToplamTutar}}₺</span>
                    <span class="onFiyat">{{calculateDiscount($root.yetiskinToplamTutar)}} ₺</span> <br>
                    <span class="xSayi">(Yetişkin)</span>
                    <span v-if="!$root.bulkTicket" class="offerAdult">%10 İndirim</span>
                </li>
                <li v-if="$root.childCount>0">
                    <span class="xSayi">{{$root.childCount}} x</span>
                    <span class="carpan" v-if="$root.ticketType === 1">{{$root.dakika}}'dk </span> 
                    <span class="carpan" v-if="$root.ticketType === 5">{{$root.kullanimSayisi}}'Kul.</span> 
                    <span class="yPaket" v-text="$root.name"></span>
                    <span class="gelenTur strikethrough">{{$root.cocukToplamTutar}}₺</span>
                    <span class="onFiyat">{{calculateDiscount($root.cocukToplamTutar)}} ₺</span><br>
                    <span class="xSayi">(Çocuk) </span>
                    <span v-if="!$root.bulkTicket" class="offerKid">%10 İndirim</span>
                </li>
            </ul>
            <div v-if="$route.path !== '/sepetim'">
            <ul  v-for="(item, index) in $root.urunler" :key="index">
                <li>
                    <span class="xSayi" v-text="item.Quantity"></span> x
                    <span class="carpan">{{item.ProductName + " ("+item.BodySize+")"}}</span>
                    <span class="gelenTur strikethrough">{{(item.Price*item.Quantity)}}₺</span>
                    <span class="onFiyat">{{calculateDiscount(item.Price*item.Quantity)}} ₺ </span>
                    <span> - %10 İndirim</span>
                </li>
            </ul>
            </div>
        </div>
        <!-- <div class="sepetTutar">
            <ul>
                <li><p>Toplam <span v-text="$root.sepetAraToplam+ ' ₺'"></span></p></li>
                <li><p>Vergi %18 <span v-text="$root.kdvHesapla+ ' ₺'"></span></p></li>
            </ul>
        </div> -->
        <div class="toplamTutar">
            <span>Toplam Ücret: <strong v-if="$root.sepetToplam > 0" class="strikethrough" v-text="$root.sepetToplam+' ₺ '"></strong>
            </span>
            <span v-text="calculateDiscount($root.sepetToplam)+' ₺'"></span>
        </div>
    </div>
    <div class="sepetKisiListe" v-if="$root.tabName === 'sepetim'">
        <div class="sepetBaslik">
            <h1>UÇUŞTA KİMLER OLACAK</h1>
            
        </div>
        
        <div class="kisilerRow"  v-for="(item, index) in person" :key="index">
            <div class="kisi">
                <span><strong>{{item.ad}} {{item.soyad}}</strong> <div class="tel">{{item.telefon}}</div></span>
            </div>
        </div>
      

    </div>
    <div class="mobilSepetSabit" @click="sepetAc">
        <div class="sepet-m-icn">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
                <path fill="#FFF" fill-rule="nonzero" d="M16.459 5.457a.156.156 0 0 0-.156-.144H13.49V4.53A4.536 4.536 0 0 0 8.96 0a4.536 4.536 0 0 0-4.531 4.531v.782H1.615a.156.156 0 0 0-.156.144l-.974 13.42a1 1 0 0 0 .997 1.073h14.954a1 1 0 0 0 .997-1.072L16.46 5.457zM5.678 4.53A3.285 3.285 0 0 1 8.959 1.25a3.285 3.285 0 0 1 3.281 3.281v.782H5.678V4.53zm-.625 5.157c-.862 0-1.563-.701-1.563-1.563 0-.624.313-1.185.938-1.432v1.432a.626.626 0 0 0 1.25 0V6.694c.625.246.937.813.937 1.431 0 .862-.7 1.563-1.562 1.563zm7.812 0c-.861 0-1.562-.701-1.562-1.563 0-.624.312-1.185.937-1.432v1.432a.626.626 0 0 0 1.25 0V6.694c.625.246.938.813.938 1.431 0 .862-.701 1.563-1.563 1.563z"/>
            </svg>
        </div>
        <div class="mobil-toplam-tutar">
            <span>Toplam Ücret: 
                <strong class="strikethrough" style="color: #ffffff85 !important;" v-text="$root.sepetToplam+'  '"></strong>
                <strong v-text="calculateDiscount($root.sepetToplam)+' ₺'"></strong>
            </span>
        </div>
        <div class="sepet-ok">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->
                <title>2BF005A4-555A-425F-913B-AD88C418B29E</title>
                <desc>Created with sketchtool.</desc>
                <g id="Güzel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="icn-right" fill="#ffffff" fill-rule="nonzero">
                        <g id="right-arrow-(4)-copy" transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) translate(6.000000, 0.000000)">
                            <path d="M1.352657,23.7517241 C1.19806763,23.9172414 1.00483092,24 0.792270531,24 C0.579710145,24 0.38647343,23.9172414 0.231884058,23.7517241 C-0.077294686,23.4206897 -0.077294686,22.8827586 0.231884058,22.5517241 L10.0869565,12 L0.231884058,1.44827586 C-0.077294686,1.11724138 -0.077294686,0.579310345 0.231884058,0.248275862 C0.541062802,-0.0827586207 1.04347826,-0.0827586207 1.352657,0.248275862 L11.7681159,11.4 C12.0772947,11.7310345 12.0772947,12.2689655 11.7681159,12.6 L1.352657,23.7517241 L1.352657,23.7517241 Z" id="Path" transform="translate(6.000000, 12.000000) rotate(-180.000000) translate(-6.000000, -12.000000) "></path>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    </div>
</div>

<!-- KAMPANYA EXTREME -->


<div style="display:table"  v-else-if="$root.ticketType === 10">
    <div class="sepet" :class="{'open': this.sepetOpen}">
        <div class="mobil-sepet-ok" @click="sepetKapat">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->
                <title>2BF005A4-555A-425F-913B-AD88C418B29E</title>
                <desc>Created with sketchtool.</desc>
                <g id="Güzel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="icn-right" fill="#ffffff" fill-rule="nonzero">
                        <g id="right-arrow-(4)-copy" transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) translate(6.000000, 0.000000)">
                            <path d="M1.352657,23.7517241 C1.19806763,23.9172414 1.00483092,24 0.792270531,24 C0.579710145,24 0.38647343,23.9172414 0.231884058,23.7517241 C-0.077294686,23.4206897 -0.077294686,22.8827586 0.231884058,22.5517241 L10.0869565,12 L0.231884058,1.44827586 C-0.077294686,1.11724138 -0.077294686,0.579310345 0.231884058,0.248275862 C0.541062802,-0.0827586207 1.04347826,-0.0827586207 1.352657,0.248275862 L11.7681159,11.4 C12.0772947,11.7310345 12.0772947,12.2689655 11.7681159,12.6 L1.352657,23.7517241 L1.352657,23.7517241 Z" id="Path" transform="translate(6.000000, 12.000000) rotate(-180.000000) translate(-6.000000, -12.000000) "></path>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
        <div class="sepetBaslik">
            <div class="sepetIcerik">
            <h1 v-text="this.konum"></h1>
            <h5 v-text="this.adres"></h5>
            <h5 v-text="this.telefon"></h5>
            </div>
            <div class="sepetTarih">
                <section v-if="$root.tarih !== undefined && $root.tarih !== null"> 
                    <div v-text="$root.tarih"></div>
                    <span v-text="$root.saat"></span>
                    <span> - </span>
                    <span v-text="$root.bitisSaat"></span>
                </section>
                <section>
                    <span v-text="this.extremeName"></span>
                </section>
            </div>
        </div>
        <div class="sepetIcerik">
            <ul v-if="$route.path !== '/sepetim'">
                <li v-if="$root.personCount>0" >
                    <span class="xSayi">{{$root.personCount}} x</span>&nbsp;
                    <span v-text="this.extremeName"></span> &nbsp; (Yetişkin)
                    
                    <span class="gelenTur strikethrough">{{$root.yetiskinToplamTutar}}₺</span><br>
                    <span class="onFiyat">{{calculateDiscountExtreme($root.personCount)}} ₺</span> 
                    <ul v-for="item in CampaignDetailList" :key="item.CampaignDetailId">
                        <li class="carpan" >
                            &nbsp;&nbsp;- {{ item.ProductName }} {{ item.ProductNameExt }}
                        </li> 
                    </ul>
                </li>
                <li v-if="$root.childCount>0">
                    <span class="xSayi">{{$root.childCount}} x</span>&nbsp;
                    <span v-text="this.extremeName"></span> &nbsp; (Çocuk)
                    <span class="gelenTur strikethrough">{{$root.cocukToplamTutar}}₺</span>
                    <span class="onFiyat">{{calculateDiscountExtreme($root.childCount)}} ₺</span><br>
                    <ul v-for="item in CampaignDetailList" :key="item.CampaignDetailId">
                        <li class="carpan" >
                            &nbsp;&nbsp;- {{ item.ProductName }} {{ item.ProductNameExt }}
                        </li> 
                    </ul>
                </li>
            </ul>
            <div v-if="$route.path !== '/sepetim'">
            <ul  v-for="(item, index) in $root.urunler" :key="index">
                <li>
                    <span class="xSayi" v-text="item.Quantity"></span> x
                    <span class="carpan">{{item.ProductName + " ("+item.BodySize+")"}}</span>
                    <span class="gelenTur strikethrough">{{(item.Price*item.Quantity)}}₺</span>
                    <span class="onFiyat">{{calculateDiscount(item.Price*item.Quantity)}} ₺ </span>
                </li>
            </ul>
            </div>
        </div>
        <!-- <div class="sepetTutar">
            <ul>
                <li><p>Toplam <span v-text="$root.sepetAraToplam+ ' ₺'"></span></p></li>
                <li><p>Vergi %18 <span v-text="$root.kdvHesapla+ ' ₺'"></span></p></li>
            </ul>
        </div> -->
        <div class="toplamTutar">
            <span>Toplam Ücret: <strong v-if="$root.sepetToplam > 0" class="strikethrough" v-text="$root.sepetToplam+' ₺ '"></strong>
            </span>
            <span v-text="calculateCartTotalExtreme() +' ₺'"></span>
        </div>
    </div>
    <div class="sepetKisiListe" v-if="$root.tabName === 'sepetim'">
        <div class="sepetBaslik">
            <h1>UÇUŞTA KİMLER OLACAK</h1>
            
        </div>
        
        <div class="kisilerRow"  v-for="(item, index) in person" :key="index">
            <div class="kisi">
                <span><strong>{{item.ad}} {{item.soyad}}</strong> <div class="tel">{{item.telefon}}</div></span>
            </div>
        </div>
      

    </div>
    <div class="mobilSepetSabit" @click="sepetAc">
        <div class="sepet-m-icn">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
                <path fill="#FFF" fill-rule="nonzero" d="M16.459 5.457a.156.156 0 0 0-.156-.144H13.49V4.53A4.536 4.536 0 0 0 8.96 0a4.536 4.536 0 0 0-4.531 4.531v.782H1.615a.156.156 0 0 0-.156.144l-.974 13.42a1 1 0 0 0 .997 1.073h14.954a1 1 0 0 0 .997-1.072L16.46 5.457zM5.678 4.53A3.285 3.285 0 0 1 8.959 1.25a3.285 3.285 0 0 1 3.281 3.281v.782H5.678V4.53zm-.625 5.157c-.862 0-1.563-.701-1.563-1.563 0-.624.313-1.185.938-1.432v1.432a.626.626 0 0 0 1.25 0V6.694c.625.246.937.813.937 1.431 0 .862-.7 1.563-1.562 1.563zm7.812 0c-.861 0-1.562-.701-1.562-1.563 0-.624.312-1.185.937-1.432v1.432a.626.626 0 0 0 1.25 0V6.694c.625.246.938.813.938 1.431 0 .862-.701 1.563-1.563 1.563z"/>
            </svg>
        </div>
        <div class="mobil-toplam-tutar">
            <span>Toplam Ücret: 
                <strong class="strikethrough" style="color: #ffffff85 !important;" v-text="$root.sepetToplam+'  '"></strong>
                <strong v-text="calculateCartTotalExtreme() +' ₺'"></strong>
            </span>
        </div>
        <div class="sepet-ok">
            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->
                <title>2BF005A4-555A-425F-913B-AD88C418B29E</title>
                <desc>Created with sketchtool.</desc>
                <g id="Güzel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="icn-right" fill="#ffffff" fill-rule="nonzero">
                        <g id="right-arrow-(4)-copy" transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) translate(6.000000, 0.000000)">
                            <path d="M1.352657,23.7517241 C1.19806763,23.9172414 1.00483092,24 0.792270531,24 C0.579710145,24 0.38647343,23.9172414 0.231884058,23.7517241 C-0.077294686,23.4206897 -0.077294686,22.8827586 0.231884058,22.5517241 L10.0869565,12 L0.231884058,1.44827586 C-0.077294686,1.11724138 -0.077294686,0.579310345 0.231884058,0.248275862 C0.541062802,-0.0827586207 1.04347826,-0.0827586207 1.352657,0.248275862 L11.7681159,11.4 C12.0772947,11.7310345 12.0772947,12.2689655 11.7681159,12.6 L1.352657,23.7517241 L1.352657,23.7517241 Z" id="Path" transform="translate(6.000000, 12.000000) rotate(-180.000000) translate(-6.000000, -12.000000) "></path>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    </div>
</div>

</template>

<script>

export default {
    name:"Sepet",
    data(){
        return{
            konum:null,
            adres: null,
            telefon: null,
            tarih:null,
            saat:null,
            paket:null,
            price:[],
            person:[],
            sepetOpen:false,
            CampaignDetailList:[],
            extremeName:null
            
        }
    },
    created(){
        this.$root.personCount=  isNaN(localStorage.yetiskinSayisi)||localStorage.cocukSayisi ===undefined?0:localStorage.yetiskinSayisi
        this.$root.childCount= isNaN(localStorage.cocukSayisi)||localStorage.cocukSayisi ===undefined?0:localStorage.cocukSayisi
        this.$root.tarih = localStorage.tarih
        this.$root.saat = localStorage.saat
        this.$root.bitisSaat = localStorage.bitisSaat
        this.person=JSON.parse(localStorage.kisiler||null)
        if(this.$root.ticketType === 10){
            let camp = JSON.parse(localStorage.seciliKampanya);
            this.CampaignDetailList=camp.CampaignDetailList
            this.extremeName = camp.Name;
        }
    },
 
    mounted() {
        this.konum = localStorage.konum
        this.adres = localStorage.adres
        this.telefon = localStorage.subeTelefon
        this.$root.corapSepetToplam=0
        if(this.$root.ticketType !== 10){
            this.paket =JSON.parse( localStorage.secilipaket||null)
            this.$root.yetiskinToplamTutar = parseInt(localStorage.yetiskinSayisi||0)*this.paket.Price
            this.$root.cocukToplamTutar =    parseInt(localStorage.cocukSayisi||0)   *this.paket.Price
            this.$root.dakika = this.paket.Duration
            this.$root.name=this.paket.ProductName
            this.$root.ucret = this.paket.Price
        }
        else{

            this.$root.yetiskinToplamTutar = parseInt(localStorage.yetiskinSayisi||0)*this.$root.ucret
            this.$root.cocukToplamTutar = parseInt(localStorage.cocukSayisi||0)*this.$root.ucret
        }
        this.$root.urunler=JSON.parse(localStorage.RestrictionProducts||null)
        var seciliurunler=JSON.parse(localStorage.RestrictionProducts||'[]')
        for(var i=0;i<seciliurunler.length;i++){
            this.$root.corapSepetToplam +=seciliurunler[i].Quantity*seciliurunler[i].Price 
        }
        console.log(this.$root.yetiskinToplamTutar, this.$root.cocukToplamTutar, this.$root.corapSepetToplam)
        this.$root.sepetAraToplam = parseFloat(this.$root.yetiskinToplamTutar) + parseFloat(this.$root.cocukToplamTutar) + parseFloat(this.$root.corapSepetToplam)
        this.$root.kdvHesapla = parseFloat(parseFloat(this.$root.sepetAraToplam) * parseFloat(this.$root.kdv)).toFixed(2)
        this.$root.sepetToplam = parseFloat(this.$root.sepetAraToplam).toFixed(2)
    },
    methods:{
        sepetAc(){
            this.sepetOpen = true
        },
        sepetKapat(){
            this.sepetOpen = false
        },
        calculateDiscount(total) {
            let finalPrice =  Number(total) - Math.round((Number(total) * 0.1) )
            if(this.$root.bulkTicket != null) {
                const campaign = JSON.parse(window.localStorage.getItem('campaign'))
                finalPrice = Number(campaign.CampaignDetailList[0].DiscountedPrice)
            }
            return finalPrice
        },
        calculateDiscountExtreme(quant) {
            let finalPrice=0
            if(this.$root.ticketType === 10)
            {
                //finalPrice=total;
                const campaign = JSON.parse(window.localStorage.getItem('seciliKampanya'))
                for (let i = 0; i < campaign.CampaignDetailList.length; i++) {
                    finalPrice += campaign.CampaignDetailList[i].DiscountedPrice
                }
            }
            
            return finalPrice*quant
        },
        calculateCartTotalExtreme() {
            let finalPrice=0
            let uruntoplam=0
            if(this.$root.ticketType === 10)
            {
                //finalPrice=total;
                const campaign = JSON.parse(window.localStorage.getItem('seciliKampanya'))
                for (let i = 0; i < campaign.CampaignDetailList.length; i++) {
                    finalPrice += campaign.CampaignDetailList[i].DiscountedPrice
                }
            }
            
            var seciliurunler=JSON.parse(localStorage.RestrictionProducts||'[]')
            for(var i=0;i<seciliurunler.length;i++){
                uruntoplam +=this.calculateDiscount(seciliurunler[i].Quantity*seciliurunler[i].Price)
            }
            finalPrice = finalPrice  *(parseInt(localStorage.yetiskinSayisi||0)+parseInt(localStorage.cocukSayisi||0))
            
            return finalPrice+uruntoplam
        }
    },
    computed: {
        campaign() {
            return JSON.parse(window.localStorage.getItem('campaign')) || null
        },
        extreme() {
            return JSON.parse(window.localStorage.getItem('seciliKampanya')) || null
        },

    }
}
</script>

<style lang="css" scoped>
.gelenTur {margin-left: 1rem;}
.offerAdult {margin-left: 3rem;}
.offerKid {margin-left: 4rem;}
</style>