<template>
<div>
    <div class="footerRow">
        <div class="footer-logo">
            <svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Güzel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="2-Bilet-Satın-Al" transform="translate(-40.000000, -897.000000)" fill-rule="nonzero">
                        <g id="Group-4" transform="translate(40.000000, 897.000000)">
                            <g id="icn-tr">
                                <circle id="Oval" fill="#D80027" cx="12.5" cy="12.5" r="12.5"></circle>
                                <g id="Group" transform="translate(7.000000, 8.105469)" fill="#F0F0F0">
                                    <polygon id="Path" points="8.52138672 2.10869141 9.54702148 3.52202148 11.2080078 2.98344727 10.1807617 4.39555664 11.20625 5.80883789 9.54584961 5.26821289 8.51865234 6.68032227 8.51972656 4.93417969 6.85927734 4.39355469 8.5203125 3.85498047"></polygon>
                                    <path d="M5.72236328,7.92714844 C3.77138672,7.92714844 2.18974609,6.34550781 2.18974609,4.39453125 C2.18974609,2.44355469 3.77138672,0.861914062 5.72236328,0.861914062 C6.33066406,0.861914062 6.90302734,1.01577148 7.40273438,1.28652344 C6.61879883,0.519824219 5.54667969,0.0466796875 4.36367187,0.0466796875 C1.96240234,0.0466796875 0.0158691406,1.99326172 0.0158691406,4.39448242 C0.0158691406,6.79570312 1.96245117,8.74228516 4.36367187,8.74228516 C5.54677734,8.74228516 6.61884766,8.2690918 7.40273438,7.50244141 C6.90302734,7.77329102 6.33066406,7.92714844 5.72236328,7.92714844 Z" id="Path"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
            <span>Türkiye</span>
        </div>
        <div class="bankakart">
            <img src="images/icn-visa.svg" alt="visa">
            <img src="images/icn-mastercard.svg" alt="icn-mastercard">
            <img src="images/icn-troy.svg" alt="troy">
            <img src="images/icn-bkm.svg" alt="express">
            <img src="images/icn-iyzico.png" alt="iyzico" height="16" style="height:16px;opacity:0.6">
        </div>
        <div class="crmmedya">
            <a href="https://www.twinleon.com/tr" target="_blank">
            <img class="rakam-logo" src="images/logo-twinleon.png" alt="" style="height:22px">
            </a>
        </div>
    </div>
    <div class="mobilFooter">
        <div class="bankakart">
            <img src="images/icn-visa.svg" alt="visa">
            <img src="images/icn-mastercard.svg" alt="icn-mastercard">
            <img src="images/icn-troy.svg" alt="troy">
            <img src="images/icn-bkm.svg" alt="express">
            <img src="images/icn-iyzico.png" alt="iyzico" height="16" style="height:16px;opacity:0.6">

        </div>
        <div class="author">
            <div class="footer-logo">
                <svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Güzel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="2-Bilet-Satın-Al" transform="translate(-40.000000, -897.000000)" fill-rule="nonzero">
                            <g id="Group-4" transform="translate(40.000000, 897.000000)">
                                <g id="icn-tr">
                                    <circle id="Oval" fill="#D80027" cx="12.5" cy="12.5" r="12.5"></circle>
                                    <g id="Group" transform="translate(7.000000, 8.105469)" fill="#F0F0F0">
                                        <polygon id="Path" points="8.52138672 2.10869141 9.54702148 3.52202148 11.2080078 2.98344727 10.1807617 4.39555664 11.20625 5.80883789 9.54584961 5.26821289 8.51865234 6.68032227 8.51972656 4.93417969 6.85927734 4.39355469 8.5203125 3.85498047"></polygon>
                                        <path d="M5.72236328,7.92714844 C3.77138672,7.92714844 2.18974609,6.34550781 2.18974609,4.39453125 C2.18974609,2.44355469 3.77138672,0.861914062 5.72236328,0.861914062 C6.33066406,0.861914062 6.90302734,1.01577148 7.40273438,1.28652344 C6.61879883,0.519824219 5.54667969,0.0466796875 4.36367187,0.0466796875 C1.96240234,0.0466796875 0.0158691406,1.99326172 0.0158691406,4.39448242 C0.0158691406,6.79570312 1.96245117,8.74228516 4.36367187,8.74228516 C5.54677734,8.74228516 6.61884766,8.2690918 7.40273438,7.50244141 C6.90302734,7.77329102 6.33066406,7.92714844 5.72236328,7.92714844 Z" id="Path"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                <span>Türkiye</span>
            </div>
            <div class="crmmedya">
            <a href="https://www.twinleon.com/tr" target="_blank">
                <img class="rakam-logo" src="images/logo-twinleon.png" alt="" style="height:22px">
            </a>
            </div>
        </div>
    </div>
</div>
    
</template>

<script>
export default {
    name:"footers",
   data(){
        return{
             
        }
    }    
}
</script>