<template>
  <div :key="keyo">
    <Sepet></Sepet>
    <div class="load" v-if="isLoading">
      <div class="loadImgs">
        <img src="images/loading-logo.png" alt="" />
        <img src="images/loading.png" class="loadImg" alt="" />
      </div>
    </div>
    <div class="zamanBg">
      <div style="margin-top:1rem;"></div>
      <p class="not">
        <img src="images/calendar-date.png" />
        <span
          >Lütfen Uçuş Bilet’iniz için önce TARİHİ seçip sonra UÇUŞ SAATİNİ
          seçerek bir sonraki aşamaya geçin.</span
        >
      </p>
      <p class="not" v-if="this.uyari">
        <img src="images/clockFull.png" />
        <span v-text="this.mesaj"></span>
      </p>
      <div class="takvim">
        <datepicker
          v-model="tarih"
          type="date"
          mode="single"
          :format="customFormatter"
          :value="anlikDeger"
          :language="tr"
          @selected="loadAvailableSlots"
          :disabledDates="computedDisabledDates"
          ref="datepicker"
        />
        <div
          class="takvimOk takvimLeft"
          :style="yesterdayAvailable()"
          @click="dayPrev(tarih)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="#000"
              fill-rule="evenodd"
              d="M.322 12.915l7.038 7.039a1.1 1.1 0 0 0 1.555-1.556l-5.16-5.16H22.55a1.1 1.1 0 0 0 0-2.2H3.755l5.16-5.16A1.1 1.1 0 1 0 7.36 4.321L.322 11.36a1.1 1.1 0 0 0 0 1.555z"
            />
          </svg>
        </div>
        <div class="takvimOk takvimRight" @click="dayNext(tarih)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              fill="#000"
              fill-rule="evenodd"
              d="M23.329 11.36L16.29 4.322a1.1 1.1 0 0 0-1.556 1.555l5.161 5.161H1.1a1.1 1.1 0 0 0 0 2.2h18.796l-5.16 5.16a1.1 1.1 0 1 0 1.555 1.556l7.038-7.039a1.1 1.1 0 0 0 0-1.555z"
            />
          </svg>
        </div>
        <h1 v-if="saat.length == 0" class="not">
          Seçilen Güne Uygun Saat Bulunamadı...
        </h1>
      </div>
      <template v-if="this.dateFull">
        <div class="saatDilimleri">
          <ul>
            <template v-for="(item, index) in saat" :item="item">
              <li
                v-if="availableSlot(item.starttime)"
                @click="saatSec(item.elementID, index)"
                :key="index"
              >
                <div class="char" :class="{ full: item.active }">
                  <span
                    :class="{ full: item.active }"
                    :style="{ height: item.fullnessValue + '%' }"
                  ></span>
                </div>
                <div class="saat">
                  <span>{{ item.starttime }}</span>
                </div>
                <div class="info" v-if="item.fullnessValue < 80">
                  Çok fazla yoğunluk yok
                </div>
                <div class="info" v-if="item.fullnessValue > 79">
                  Yoğunluk çok fazla
                </div>
              </li>
            </template>
          </ul>
        </div>
      </template>

      <template v-if="this.dateFull">
        <div>
          <div class="saatSlider">
            <swiper :options="swiperOption" ref="mySwiper">
              <template v-for="(item, index) in saat" :item="index">
                <swiper-slide
                  v-if="availableSlot(item.starttime)"
                  :key="item.elementID"
                  :class="[
                    { 'swiper-slide-active': item.active },
                    { active: item.active },
                  ]"
                >
                  <div
                    class="ucusSaati"
                    @click="saatSec(item.elementID, index)"
                  >
                    <span class="ucusBaslik">UÇUŞ SAATİ</span>
                    <span class="ucusSaat">{{ item.starttime }}</span>
                  </div>
                </swiper-slide>
              </template>
            </swiper>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </div>
        </div>
      </template>
      <div class="butonlar butonlarRelocate butonlarRelocateForNeZaman">
        <router-link to="/gelenler"><span>GERİ</span></router-link>
        <router-link v-if="this.$root.controlled == true" to="/kimler"
          ><span>İLERİ</span></router-link
        >
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from './Footer'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import { tr } from 'vuejs-datepicker/dist/locale'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import axios from 'axios'
import Sepet from './SepetMobil'
export default {
  name: 'NeZaman',
  components: {
    Datepicker,
    swiper,
    swiperSlide,
    Footer,
    Sepet,
  },
  data() {
    return {
      hourDetails: null,
      keyo: 1,
      saat: [],
      gunler: [],
      seciliSaat: null,
      linkAktif: true,
      anlikSaat: null,
      tr: tr,
      branchClosingTime: 20,
      isLoading: false,
      tarih: new Date(),
      anlikDeger: '',
      nextDay: '',
      dateFull: true,
      mesaj: '',
      uyari: false,
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 40,
        slidesPerGroup: 1,
        loop: false,
        //loopFillGroupWithBlank: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          768: {
            direction: 'vertical',
            spaceBetween: 10,
          },
        },
      },
    }
  },
  created() {
    this.$root.menuActive1 = false
    this.$root.menuLink1 = true
    this.$root.menuActive2 = false
    this.$root.menuLink2 = true
    this.$root.menuActive3 = false
    this.$root.menuLink3 = true
    this.$root.menuActive4 = true
    this.$root.menuLink4 = false
    this.$root.menuActive5 = false
    this.$root.menuLink5 = false
    this.$root.menuActive6 = false
    this.$root.menuLink6 = false
    this.setHourDetails()

    if (localStorage.paket === undefined) {
      this.$router.push('/paketler')
    }
    if (localStorage.saat !== undefined) {
      localStorage.removeItem('saat')
    }

    this.loadAvailableSlots(new Date())
  },
  methods: {
    setHourDetails() {
      const seciliPaket = JSON.parse(window.localStorage.getItem('secilipaket'))
      this.hourDetails = seciliPaket.ticketProductHours[0].ticketProductHourDetails
    },
    saatSec(i, index) {
      this.saat.find((item) => {
        item.active = false
        if (item.elementID == i) {
          this.$root.controlled = true
          item.active = true
          this.$root.saat = item.starttime
          localStorage.saat = item.starttime
        }
        this.$root.saatIndex = index
        this.swiper.activeIndex = this.$root.saatIndex
      })
    },
    customFormatter(date) {
      return moment(date).format('DD.MM.YYYY')
    },
    loadAvailableSlots(val) {
      this.isLoading = true
      this.$snotify.info('Uçuşa Hazırlanıyor...', '', {
        timeout: 2000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
      })
      window.localStorage.tarih = moment(val).format('DD/MM/YYYY')
      this.$root.tarih = moment(val).format('DD/MM/YYYY')
      this.saat = []
      var ticket = JSON.parse(localStorage.paket)
      this.tarih = this.pickSuitableDate(ticket[0].ProductID, val)
      var veri = {
        paramDay: moment(this.tarih).format('YYYY-MM-DD'),
        TerritoryId: localStorage.konumId,
        productID: ticket[0].ProductID,
      }

      axios
        .post(
          `${process.env.VUE_APP_ENV_SERVER}/Services/FlyzoneCrm/B2CServices/GetFullnessDealer`,
          JSON.stringify(veri),
          {
            headers: {
              'Content-Type': 'application/json',
              'cache-control': 'no-cache',
              dataType: 'json',
            },
          }
        )
        .then((response) => {
          var veriler = response.data

          for (var i = 0; i < veriler.length; i++) {
            var cr = veriler[i]
            let pDuration = JSON.parse(
              window.localStorage.getItem('secilipaket')
            ).Duration
            //this.branchClosingTime//close @ 20:00

            let closeTime = parseInt(
              veriler[veriler.length - 1].endtime.substring(0, 2)
            ) //20//close @ 20:00 // veriler[veriler.length-1].endtime
            this.branchClosingTime = closeTime
            let dt = new Date(`December 25, 2017 ${closeTime}:00:00`) //just any date is ok, but branchClosingTime is IMPORTANT
            dt.setMinutes(dt.getMinutes() - pDuration)
            let closingTime = dt.getHours() * 100 + dt.getMinutes()
            let packageStarting = parseInt(cr.starttime.replace(':', ''))

            if (packageStarting <= closingTime) {
              this.saat.push({
                starttime: cr.starttime,
                endtime: cr.endtime,
                elementID: cr.elementID,
                fullnessValue: cr.fullnessValue,
                active: false,
              })
            }
          }

          if (this.saat.length == 0) {
            this.dateFull = false
            var packetId = JSON.parse(localStorage.paket)[0].ProductID
            var packetName = JSON.parse(localStorage.paket)[0].ProductName
            var packetGunler = JSON.parse(localStorage.paket)[0].ticketProductHours[0].ticketProductHourDetails
            var gunAdi
            //this.gunler = " ";
            for (var g = 0; g < packetGunler.length; g++) {
              if (packetGunler[g].Day == 1) {
                gunAdi = 'Pazartesi'
                this.gunler.push(' ' + gunAdi)
              }
              if (packetGunler[g].Day == 2) {
                gunAdi = 'Salı'
                this.gunler.push(' ' + gunAdi)
              }
              if (packetGunler[g].Day == 3) {
                gunAdi = 'Çarşamba'
                this.gunler.push(' ' + gunAdi)
              }
              if (packetGunler[g].Day == 4) {
                gunAdi = 'Perşembe'
                this.gunler.push(' ' + gunAdi)
              }
              if (packetGunler[g].Day == 5) {
                gunAdi = 'Cuma'
                this.gunler.push(' ' + gunAdi)
              }
              if (packetGunler[g].Day == 6) {
                gunAdi = 'Cumartesi'
                this.gunler.push(' ' + gunAdi)
              }
              if (packetGunler[g].Day == 0) {
                gunAdi = 'Pazar'
                this.gunler.push(' ' + gunAdi)
              }
            }
            if (packetId == 4) {
              this.mesaj =
                'Seçmiş olduğunuz tarihe ait uçuş saati bulunmamaktadır. ' +
                packetName +
                ' paketine ait müsait günler:' +
                this.gunler
            }
            if (packetId == 3) {
              this.mesaj =
                'KID FLIGHT uçuş saatimiz 10:00 ile 11:00 saatleri arasındadır. Bugüne ait KID FLIGHT uçuş saati geçmiştir. Lütfen ileri tarihlerden uçuş saati seçiniz.'
            }
            this.isLoading = false
          } else {
            this.dateFull = true
          }
          this.isLoading = false
          this.keyo++
        })
    },
    dayNext(currDate) {
      currDate.setDate(currDate.getDate() + 1)
      this.loadAvailableSlots(currDate)
    },
    dayPrev(currDate) {
      console.log(`${currDate}`)
      currDate.setDate(currDate.getDate() - 1)
      console.log(`${currDate}`)
      this.loadAvailableSlots(currDate)
    },
    availableSlot(hour) {
      let selectedDate = window.localStorage.getItem('tarih')
      selectedDate = selectedDate.split('/') // DD/MM/YYYY
      let today = new Date()
      if (today.getMonth() + 1 < selectedDate[1]) return true //January is 0
      if (today.getDate() < selectedDate[0]) return true
      if (today.getHours() < hour.split(':')[0]) return true
      return false
    },
    pickSuitableDate(packageId, customDate) {
      let today = customDate ? customDate : new Date()
      if (
        (today.getHours() >= this.branchClosingTime &&
          this.hoursDifferenceBtwnDays(today, new Date()) <= 23.8)
      ) {
        today.setDate(today.getDate() + 1)
      }
      if (packageId == 4) {
        this.uyari = true
        this.mesaj = 'Night flight paketi cuma ve cumartesi günlerine özeldir.'
        this.$snotify.warning(
          'Night Flight Cuma ve Cumartesi günlerine özeldir, gelecek cumaya yönlendiriliyorsunuz...',
          '',
          {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            position: 'centerCenter',
            bodyMaxLength: 200,
          }
        )
        return this.getNextDayOfWeek(today, 6, 5)
      } else return today
    },
    getNextDayOfWeek(date, dayOfWeek1, dayOfWeek2) {
      // 0 is Sunday, 1 is Monday
      let resultDate1 = new Date(date.getTime())
      resultDate1.setDate(
        date.getDate() + ((7 + dayOfWeek1 - date.getDay()) % 7)
      )

      let resultDate2 = new Date(date.getTime())
      resultDate2.setDate(
        date.getDate() + ((7 + dayOfWeek2 - date.getDay()) % 7)
      )
      return resultDate1 < resultDate2 ? resultDate1 : resultDate2
    },
    isToday(someDate) {
      const today = new Date()
      return (
        someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
      )
    },
    hoursDifferenceBtwnDays(day1, day2) {
      return Math.abs(day1 - day2) / 36e5
    },
    yesterdayAvailable() {
      let hoursDifference = (this.tarih - new Date()) / 36e5
      console.log(hoursDifference)
      if ( hoursDifference <= 23.8 )
        return 'display: none;'
      else return ''
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    },
    computedDisabledDates() {
      let availableDays = []
      const AllDays = [0, 1, 2, 3, 4, 5, 6]
      let disabledDates = {
        to: new Date(Date.now() - 8640000),
        days: [],
      }
      this.hourDetails.forEach(e => { availableDays.push(e.Day) })
      disabledDates.days = AllDays.filter(d => !availableDays.includes(d))
      return disabledDates
    }
  },
  mounted() {
    localStorage.tarih = moment(this.tarih).format('DD/MM/YYYY')
    this.$root.tarih = localStorage.tarih
    this.$root.controlled = false
  },
  beforeDestroy() {
    this.$root.dakika = JSON.parse(localStorage.secilipaket)['Duration']
    this.$root.seciliDakika = this.$root.dakika
    this.$root.seciliSaat = new Date(
      'January 01, 2020 ' + this.$root.saat + ':00'
    )
    this.$root.seciliSaat.setMinutes(
      this.$root.seciliSaat.getMinutes() + this.$root.seciliDakika
    )
    this.$root.bitisSaat = moment(this.$root.seciliSaat).format('HH:mm')
    localStorage.bitisSaat = this.$root.bitisSaat
    this.$root.bitisSaat = localStorage.bitisSaat
    this.$root.tabName = 'coraplar'
  },
}
</script>
