<template>
  <div id="vueRoot">
    <form
      ref="form"
      action="https://entegrasyon.asseco-see.com.tr/fim/est3Dgate"
      method="POST"
    >
      <input type="hidden" name="pan" v-model="cardno" />
      <input type="hidden" name="cv2" v-model="ccv2" />
      <input
        type="hidden"
        name="Ecom_Payment_Card_ExpDate_Month"
        v-model="cmonth"
      />
      <input
        type="hidden"
        name="Ecom_Payment_Card_ExpDate_Year"
        v-model="cyear"
      />
      <input type="hidden" name="clientid" v-model="clientid" />
      <input type="hidden" name="okUrl" v-model="okUrl" />
      <input type="hidden" name="amount" v-model="amount" />
      <input type="hidden" name="oid" v-model="oid" />
      <input type="hidden" name="failUrl" v-model="failurl" />
      <input type="hidden" name="lang" v-model="lang" />
      <input type="hidden" name="rnd" v-model="rnd" />
      <input type="hidden" name="hash" v-model="hash" />
      <input type="hidden" name="currency" v-model="currency" />
      <input type="hidden" name="storetype" v-model="storetype" />
      <a href="#" v-on:click="submit">SUBMIT</a>
    </form>
  </div>
</template>

<script>
export default {
  name: "FormPost3D",
  el: "#vueRoot",
  data() {
    return {
      cardno: localStorage.cardNo,
      ccv2: localStorage.cardCvv,
      cmonth: localStorage.cardAy,
      cyear: localStorage.cardYil,
      amount: 10,
      clientid: "100100000",
      okUrl: "http://localhost:2887/WebForm3dSucces.aspx",
      oid: "dlkasjdl3223231",
      failUrl: "http://localhost:2887/WebForm3dSucces.aspx",
      lang: "tr",
      rnd: "20200202105623",
      hash: this.toUTF8Array(
        "100100000" +
          "dlkasjdl3223231" +
          10 +
          "http://localhost:2887/WebForm3dSucces.aspx" +
          "http://localhost:2887/WebForm3dSucces.aspx" +
          "20200202105623" +
          "123456"
      ),
      currency: "949",
      storetype: "3d",
    };
  },

  methods: {
    submit: function () {
      this.$refs.form.submit();
    },
    toUTF8Array(str) {
      var utf8 = [];
      for (var i = 0; i < str.length; i++) {
        var charcode = str.charCodeAt(i);
        if (charcode < 0x80) utf8.push(charcode);
        else if (charcode < 0x800) {
          utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
        } else if (charcode < 0xd800 || charcode >= 0xe000) {
          utf8.push(
            0xe0 | (charcode >> 12),
            0x80 | ((charcode >> 6) & 0x3f),
            0x80 | (charcode & 0x3f)
          );
        }
        // surrogate pair
        else {
          i++;
          // UTF-16 encodes 0x10000-0x10FFFF by
          // subtracting 0x10000 and splitting the
          // 20 bits of 0x0-0xFFFFF into two halves
          charcode =
            0x10000 +
            (((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff));
          utf8.push(
            0xf0 | (charcode >> 18),
            0x80 | ((charcode >> 12) & 0x3f),
            0x80 | ((charcode >> 6) & 0x3f),
            0x80 | (charcode & 0x3f)
          );
        }
      }
      return utf8;
    },
  },
};
</script>
