<template>
  <div>

    <template v-for="(item, index) in packets">
      <div class="colUclu colUcluPaket" :key="index">
        <a
          @click="paketSec(item)"
          class="colCheck pointer"
          :style="{'background-image': 'url(https://crm.flyzoneturkey.com/upload/'+item[0].ProductImage+ ')',}"
        >
          <div class="ortala">
            <h2 class="wow fadeInUp" data-wow-delay="0.3s">
              {{ item[0].ProductName }}
            </h2>
            <p class="wow fadeInUp" data-wow-delay="0.4s">
              {{ item[0].ProductDsc }}
            </p>
            <img src="images/icn-right.svg" alt="" />
          </div>
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import Footer from './Footer'
export default {
  name: 'paketData',
  components: { Footer },
  data() {
    return {
      packets: [],
    }
  },
  created() {
    if (localStorage.paket !== undefined) {
      localStorage.removeItem('paket')
    }
    this.packets = JSON.parse(localStorage.getItem('tickets'))
    this.packets = this.packets.reduce((r, a) => { r[a.ProductName] = [...(r[a.ProductName] || []), a]; return r; }, {})
    
  },
  methods: {

    paketSec(e) {
      window.localStorage.setItem('paket', JSON.stringify(e))
      window.localStorage.setItem('secilipaket', JSON.stringify(e[0]))
      if(['Party Flight', 'Group Flight', 'Event Flight'].includes(e[0].ProductName)) {
        this.$router.push('/form')
      } else {
        this.$router.push('/gelenler')
      }
    },
  },
  props: {},
}
</script>
