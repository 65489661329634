<template>
  <div>
    <div class="zamanBg">
      <div class="container">
        <SonucMesaj></SonucMesaj>
      </div>
      <div class="biletDetay biletDetaySonuc active">
        <div class="mobilBiletDetayBaslik">
          <h2>BİLET DETAY</h2>
        </div>
        <div class="biletDetayBaslik" v-if="this.$root.ticketType !== 10">
          <div class="biletDetayPaket" v-text="secilipaket.ProductName"></div>
          <div class="biletDetayKonum" v-text="konum"></div>
          <div class="biletDetayTarih" v-text="tarih"></div>
          <div v-if="this.$root.ticketType === 1" class="biletDetaySaat" v-text="start + ' - ' + end"></div>
          <div v-if="this.$root.ticketType === 5" class="biletDetaySaat" v-text="start"></div>
        </div>
        <div class="biletDetayBaslik" v-if="this.$root.ticketType === 10">
          <div class="biletDetayPaket" v-text="this.extremeName"></div>
          <div class="biletDetayKonum" v-text="konum"></div>
          <div class="biletDetayTarih" v-text="tarih"></div>
          <div class="biletDetaySaat" v-text="start + ' - ' + end"></div>
        </div>
        <div class="biletTabloContent"  v-if="this.$root.ticketType !== 10">
          <div class="biletDetayTabloBaslik">
            <div class="biletDetayCol">Paket</div>
            <div class="biletDetayCol">Kişi</div>
            <div class="biletDetayCol">Ücret</div>
          </div>
          <div class="biletDetayTabloRow">
            <div>
              <div v-if="this.$root.ticketType === 1"
                class="biletDetayCol"
                v-text="
                  secilipaket.Duration +
                  ' dk ' +
                  secilipaket.ProductName +
                  '(' +
                  toplamKisiSayisi +
                  ')'
                "
              ></div>
              <div v-if="this.$root.ticketType === 5"
                class="biletDetayCol"
                v-text="
                  secilipaket.useCount +
                  ' Kul. ' +
                  secilipaket.ProductName +
                  '(' +
                  toplamKisiSayisi +
                  ')'
                "
              ></div>
              <div class="biletDetayCol" v-text="toplamKisiSayisi"></div>
              <div
                class="biletDetayCol"
                v-text="
                  Number(secilipaket.Price) * Number(toplamKisiSayisi) + ' ₺'
                "
              ></div>
            </div>
          </div>
          <div
            class="biletDetayTabloRow"
            v-for="(item, i) in restrictionProducts"
            :key="i"
          >
            <div
              class="biletDetayCol"
              v-text="item.BodySize + ' ' + item.ProductName"
            ></div>
            <div class="biletDetayCol" v-text="item.Quantity"></div>
            <div
              class="biletDetayCol"
              v-text="item.Price * item.Quantity + ' ₺'"
            ></div>
          </div>
        </div>
        <div class="biletTabloContent"  v-if="this.$root.ticketType === 10">
          <div class="biletDetayTabloBaslik"> 
            <div class="biletDetayCol">Paket</div>
            <div class="biletDetayCol">Kişi</div>
            <div class="biletDetayCol">Ücret</div>
          </div>
          <div class="biletDetayTabloRow">
            <div>
              <div
                class="biletDetayCol"
                
              >
              {{ this.extremeName }} <br>
              <ul v-for="item in CampaignDetailList" :key="item.CampaignDetailId">
                        <li class="carpan" >
                            &nbsp;&nbsp;- {{ item.ProductName }} {{ item.ProductNameExt }}
                        </li> 
                    </ul>
              </div>
              <div class="biletDetayCol" v-text="toplamKisiSayisi"></div>
              <div
                class="biletDetayCol"
                v-text="
                  calculateDiscountExtreme(toplamKisiSayisi)+ ' ₺'
                "
              ></div>
            </div>
          </div>
          <div
            class="biletDetayTabloRow"
            v-for="(item, i) in restrictionProducts"
            :key="i"
          >
            <div
              class="biletDetayCol"
              v-text="item.BodySize + ' ' + item.ProductName"
            ></div>
            <div class="biletDetayCol" v-text="item.Quantity"></div>
            <div
              class="biletDetayCol"
              v-text="calculateDiscount(item.Price * item.Quantity) + ' ₺'"
            ></div>
          </div>
        </div>

        <div class="biletSepetUcret"  v-if="this.$root.ticketType !== 10">
          <div class="biletToplamUcret">
            Toplam Ücret:
            <span style="color:#5b5b5b;font-size: 12px;">&nbsp; (%10 İndirim)&nbsp;&nbsp;</span>
            <strong
              class="strikethrough"
              style="color: #5b5b5b !important"
              v-text="Number(toplamUcert).toFixed(2) + '  '"
            ></strong>
            <strong>
              <span v-text="calculateDiscount(toplamUcert).toFixed(2)"> </span>
            </strong>
            ₺
          </div>
        </div>
        <div class="biletSepetUcret"  v-if="this.$root.ticketType === 10">
          <div class="biletToplamUcret">
            Toplam Ücret:
            <span style="color:#5b5b5b;font-size: 12px;">&nbsp;&nbsp;</span>
            <strong
              class="strikethrough"
              style="color: #5b5b5b !important"
              v-text="$root.sepetToplam + ' ₺'"
            ></strong>
            <strong>
              <span v-text="calculateCartTotalExtreme() + ' ₺'"> </span>
            </strong>
            ₺
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import SonucMesaj from "./SonucMesaj";
import Footer from "./Footer";
export default {
  name: "OdemeSonuc",
  data() {
    return {
      product: [],
      cocukSayisi: 0,
      yetiskinSayisi: 0,
      secilipaket: null,
      restrictionProducts: [],
      konum: null,
      tarih: null,
      start: null,
      end: null,
      CampaignDetailList:[],
      extremeName:null
    };
  },
  computed: {
    toplamKisiSayisi() {
      return Number(this.cocukSayisi) + Number(this.yetiskinSayisi);
    },
    toplamUcert() {
      const toplamBiletUcreti = Number(this.secilipaket.Price) * Number(this.toplamKisiSayisi)
      let toplamCorapUcreti = 0
      if(this.restrictionProducts !== null && this.restrictionProducts !== undefined)
        toplamCorapUcreti=this.restrictionProducts.map(o => o.Quantity*o.Price).reduce((a, c) => { return a + c }, 0)
      return Number(toplamBiletUcreti) + Number(toplamCorapUcreti || 0)
    },
  },
  created() {

    if(this.$root.ticketType === 10){
        this.setExtremeResultValues()
    }else
    {
      this.setResultValues()
    }

    (this.$root.menuActive1 = false),
      (this.$root.menuLink1 = true),
      (this.$root.menuActive2 = false),
      (this.$root.menuLink2 = true),
      (this.$root.menuActive3 = false),
      (this.$root.menuLink3 = true),
      (this.$root.menuActive4 = false),
      (this.$root.menuLink4 = true),
      (this.$root.menuActive5 = false),
      (this.$root.menuLink5 = true),
      (this.$root.menuActive6 = false),
      (this.$root.menuLink6 = true);

      
  },
  mounted() {
        this.$root.sepetAraToplam = parseFloat(this.$root.yetiskinToplamTutar) + parseFloat(this.$root.cocukToplamTutar) + parseFloat(this.$root.corapSepetToplam)
        this.$root.kdvHesapla = parseFloat(parseFloat(this.$root.sepetAraToplam) * parseFloat(this.$root.kdv)).toFixed(2)
        this.$root.sepetToplam = parseFloat(this.$root.sepetAraToplam).toFixed(2)
        },
  methods: {
    setResultValues() {
      this.secilipaket = JSON.parse(window.localStorage.getItem("secilipaket"));
      this.konum = window.localStorage.getItem("konum");
      this.tarih = window.localStorage.getItem("tarih");
      this.start = window.localStorage.getItem("saat");
      this.end = window.localStorage.getItem("bitisSaat");
      this.restrictionProducts = JSON.parse( window.localStorage.getItem("RestrictionProducts"));
      this.yetiskinSayisi = window.localStorage.getItem("yetiskinSayisi");
      this.cocukSayisi = window.localStorage.getItem("cocukSayisi");
    },
    setExtremeResultValues() {
      let camp = JSON.parse(localStorage.seciliKampanya);
        this.CampaignDetailList=camp.CampaignDetailList
        this.extremeName = camp.Name;
      this.konum = window.localStorage.getItem("konum");
      this.tarih = window.localStorage.getItem("tarih");
      this.start = window.localStorage.getItem("saat");
      this.end = window.localStorage.getItem("bitisSaat");
      this.restrictionProducts = JSON.parse( window.localStorage.getItem("RestrictionProducts"));
      this.yetiskinSayisi = window.localStorage.getItem("yetiskinSayisi");
      this.cocukSayisi = window.localStorage.getItem("cocukSayisi");
    },
    calculateDiscount(total) {
        let finalPrice =  Number(total) - Math.round((Number(total) * 0.1) )
        if(this.$root.bulkTicket != null) {
            const campaign = JSON.parse(window.localStorage.getItem('campaign'))
            finalPrice = Number(campaign.CampaignDetailList[0].DiscountedPrice)
        }
        return finalPrice
    },
        calculateDiscountExtreme(quant) {
            let finalPrice=0
            if(this.$root.ticketType === 10)
            {
                //finalPrice=total;
                const campaign = JSON.parse(window.localStorage.getItem('seciliKampanya'))
                for (let i = 0; i < campaign.CampaignDetailList.length; i++) {
                    finalPrice += campaign.CampaignDetailList[i].DiscountedPrice
                }
            }
            
            return finalPrice*quant
        },
        calculateCartTotalExtreme() {
            let finalPrice=0
            let uruntoplam=0
            if(this.$root.ticketType === 10)
            {
                //finalPrice=total;
                const campaign = JSON.parse(window.localStorage.getItem('seciliKampanya'))
                for (let i = 0; i < campaign.CampaignDetailList.length; i++) {
                    finalPrice += campaign.CampaignDetailList[i].DiscountedPrice
                }
            }
            
            var seciliurunler=JSON.parse(localStorage.RestrictionProducts||'[]')
            for(var i=0;i<seciliurunler.length;i++){
                uruntoplam +=this.calculateDiscount(seciliurunler[i].Quantity*seciliurunler[i].Price)
            }
            finalPrice = finalPrice  *(parseInt(localStorage.yetiskinSayisi||0)+parseInt(localStorage.cocukSayisi||0))
            
            return finalPrice+uruntoplam
        }
  },
  components: {
    SonucMesaj,
    Footer,
  },
};
</script>
