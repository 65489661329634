<template>
  <div>
    <div class="load" v-if="isLoading">
      <div class="loadImgs">
        <img src="images/loading-logo.png" alt="" />
        <img src="images/loading.png" class="loadImg" alt="" />
      </div>
    </div>



    <div class="yearCartCheckPage">
        <div class="yearCardcontainer">

            <div class="yearCartBox yearCartBoxThanyou"> 

              <i><img src="/images/completedCheck.png" alt=""></i>

              <p>
                Ödemeniz başarılı bir şekilde gerçekleştirilmiştir. <br>
                Kartınızı şubemize geldiğinizde kimliğinizi ibraz ederek alabilirsiniz. <br>
                Keyifli Uçuşlar...
              </p>

            </div>

        </div>  

    </div>



    <Footer />
  </div>
</template>

<script>


import Footer from "./Footer";
import { error } from 'jquery';
export default {
  name: "yillik-kart-odeme-tesekkur",
  components: { Footer },

  data() {

    
    return {
      isLoading: true,
      adres: localStorage.adres,
      sube: localStorage.konum,

      
    };
  },
  methods: {
    checkBulkTickets() {},
    setSelectedPackage(bulkTicket) {},

  },
  computed: {
    bulkTicket() {
      // return this.$root.bulkTicket;
    },
  },
  created() {
    this.$root.sepetBos = false;
    this.isLoading = false;
    this.$root.menuActive1 = false;
    this.$root.menuLink1 = true;
    this.$root.menuActive2 = true;
    this.$root.menuLink2 = false;
    this.$root.menuActive3 = false;
    this.$root.menuLink3 = false;
    this.$root.menuActive4 = false;
    this.$root.menuLink4 = false;
    this.$root.menuActive5 = false;
    this.$root.menuLink5 = false;
    this.$root.menuActive6 = false;
    this.$root.menuLink6 = false;
  },
  mounted() {
    document.body.style.backgroundColor = "black";
    // this.checkBulkTickets()
  },
  beforeDestroy() {
    document.body.style.backgroundColor = "";
  },
};
</script>
<style scoped>


</style>
