<template>
  <div class="zamanBg">
    <div class="info-holder">
      <div class="info-side">
        <h1 class="page-header">BİLET DEĞİŞİKLİĞİ VE İPTALİ</h1>
        <div class="info-card">
          <h1 class="page-header">{{ biletBilgileri.TerritoryName }}</h1>
          <p class="light-text">{{ biletBilgileri.Address }}</p>
          <h1 class="page-header" style="margin-top: 1rem">
            {{ biletBilgileri.TicketDate }}
          </h1>
          <div class="biletTabloContent">
            <div class="sepetimTabloBaslik">
              <div class="sepetimCol">Paket</div>
              <div class="sepetimCol">Kişi</div>
              <div class="sepetimCol">Ücret</div>
            </div>
            <div
              class="sepetimContent"
              style="
                padding: 0;
                border-bottom: 1px solid #313b46;
                margin-bottom: 15px;
              "
            >
              <div
                class="sepetimTabloRow"
                style="border-bottom: 0"
                v-for="(item, i) in biletBilgileri.OrderDetails"
                :key="i"
              >
                <div class="sepetimCol">
                  <div>
                    <span class="xSayi">{{ item.Quantity }}</span> x
                    <span class="yPaket" style="top: 0">{{
                      item.ProductName
                    }}</span>
                  </div>
                </div>
                <div class="sepetimCol">
                  <span>{{ item.Quantity }}</span>
                </div>
                <div class="sepetimCol">
                  <span class="onFiyat">{{ item.Amount }}</span>
                </div>
              </div>
              <br />
            </div>
          </div>
          <div>
            <button v-if="biletBilgileri.updateEnable" class="blue-button" @click="getFullness()">
              Tarih Değişikliği
            </button>
            <button
              class="blue-button disabled"
              @click="openPopup('deleteConfirmation')"
            >
              Bilet İptali
            </button>
          </div>
        </div>
      </div>
      <div class="instruction-side">
        <h1 class="page-header"></h1>
        <h1 class="page-header">Koşullar ve Şartlar</h1>
        <h1 class="page-header" style="font-size: 16px">
          1 -) Bilet Değişikliği
        </h1>
        <p class="light-text">
          Biletlerin seans başlangıç saati , satın alımından itibaren seans
          başlangıç saatinin 1 saat öncesine kadar değiştirilebilecektir.
        </p>
        <br />
        <p class="light-text">
          Bilet , satın alınan başlangıç gün ve saatin maks. 1 ay ileriye
          değiştirilebilecektir.
        </p>
        <br />
        <p class="light-text">Bilet tarihi ve saati, işlem yapıla.</p>
        <br />
        <h1 class="page-header" style="font-size: 16px">
          2-) Bilet İptal / İade
        </h1>
        <p class="light-text">
          Biletler, satın alımından itibaren seans başlangıç saatinin 1 saat
          öncesine kadar İptal veya iade edilebilir.
        </p>
      </div>
    </div>
    <div id="overlay" v-if="showPopup">
      <div class="pop-up-window">
        <span class="close" @click="closePopup()" v-if="!loader">x</span>
        <section style="width: 80%" v-if="timeUpdater">
          <p class="light-head">Tarih Seç</p>
          <div class="date-time-selector">
            <datepicker
              class="date-picker-input"
              v-model="selectedDate"
              type="date"
              mode="single"
              :format="customFormatter"
              :value="anlikDeger"
              :language="tr"
              @selected="getFullness()"
              :disabledDates="filteredDisabledDates"
              ref="datepicker"
            />
          </div>
          <p class="light-head">Seans Başlangıcı Seç</p>
          <section class="time-intervals-section">
            <select
              class="time-intervals"
              :class="{ error: invalidTimeFlag }"
              v-model="selectedTime"
              @change="invalidTimeFlag = false"
            >
              <option value="null" disabled selected>Saat seçiniz..</option>
              <option
                :value="item"
                v-for="(item, i) in filteredTimeInterval"
                :key="i"
              >
                {{ `${item.starttime}` }}
              </option>
            </select>
          </section>
          <div class="horizontal-row">
            <p class="light-head">Eski Bilet Tarihi</p>
            <p class="light-head">Yeni Bilet Tarihi</p>
          </div>
          <div class="horizontal-row">
            <p class="light-head-white">
              {{ biletBilgileri.TicketDate.split(" ")[0] }}<br />
              {{ biletBilgileri.TicketDate.split(" ")[1] }}
            </p>
            <img
              src="/images/double-arrow-right.svg"
              style="width: 1.4rem; margin-top: -10px"
              alt=""
            />
            <p class="light-head-white">
              {{ moment(selectedDate).format("DD.MM.YYYY") }} <br />
              {{ selectedTime ? selectedTime.starttime : "-" }}
            </p>
          </div>
          <button
            class="blue-button"
            @click="changeTicket()"
            style="width: 100%"
          >
            Değiştir
          </button>
        </section>
        <section v-if="loader">
          <img src="/images/loading.png" class="loadImg" alt="error-cross" />
          <h1 class="page-header">Bilgiler yükleniyor...</h1>
        </section>
        <section v-if="errorResult">
          <center>
            <img src="/images/error-cross.svg" alt="error-cross" />
            <h1 class="page-header">{{ errMsg }}</h1>
          </center>
        </section>
        <section v-if="successResult" class="result-section">
          <img
            src="/images/icn-odendi.png"
            alt="error-cross"
            style="margin: 20px auto"
          />
          <h1 class="page-header">Biletiniz güncellendi...</h1>
          <button
            class="blue-button"
            @click="afterSuccess()"
            style="width: 100%"
          >
            Geri
          </button>
        </section>
        <section v-if="deleteConfirmation">
          <h1 class="page-header" style="font-size: 18px; text-align: center">
            Bilet siparişiniz iptal edilecek ve para iadeniz kredi kartınıza
            yapılacaktır , onaylıyor musunuz ?
          </h1>
          <div>
            <button
              class="blue-button confirm-btn"
              @click="delteTicket()"
              style="width: 100%"
            >
              Onaylıyorum
            </button>
            <button
              class="blue-button"
              @click="closePopup()"
              style="width: 100%"
            >
              Vazgeç
            </button>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import axios from "axios";
import { tr } from "vuejs-datepicker/dist/locale";
export default {
  name: "BiletBilgileri",
  components: { Datepicker },
  data() {
    return {
      moment: moment,
      biletBilgileri: {
        TerritoryId: null,
        ProductId: null,
        TerritoryName: "",
        Address: null,
        TicketDate: "",
        TotalAmount: null,
        updateEnable: false,
        OrderDetails: [],
      },
      loader: false,
      timeUpdater: false,
      showPopup: false,
      successResult: false,
      errorResult: false,
      selectedDate: new Date(),
      anlikDeger: "",
      selectedTime: null,
      tr: tr,
      timeIntervalsOpts: [],
      invalidTimeFlag: false,
      deleteConfirmation: false,
      errMsg: "Hata oluştu...",
    };
  },
  beforeMount() {
    this.extractParams();
  },
  mounted() {
    this.selectCloserSuitableDay(this.biletBilgileri.ProductId);
  },
  methods: {
    isToday(someDate) {
      const today = new Date();
      return (
        someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
      );
    },
    delteTicket() {
      var thisComponent = this;
      thisComponent.openPopup("loader");
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        let urlencoded = new URLSearchParams();
        urlencoded.append("OrderID", thisComponent.biletBilgileri.OrderID); //5078141804
        urlencoded.append(
          "CustomerGSM",
          thisComponent.biletBilgileri.CustomerGSM
        ); //118790
        let requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow",
        };
        fetch(
          `${process.env.VUE_APP_ENV_SERVER}/Services/FlyzoneCrm/B2CServices/B2CCancelOrder`,
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            result = JSON.parse(result);
            if (result.succes == true) {
              thisComponent.$router.push("/bilet-sorgulama");
            } else {
              thisComponent.errMsg = result.message;
              thisComponent.openPopup("errorResult");
            }
          })
          .catch((error) => {
            console.log("error connection", error);
            thisComponent.errMsg = "Bağlantı hatası...";
            thisComponent.openPopup("errorResult");
          });
    },
    openPopup(section) {
      this.closePopup();
      this.showPopup = true;
      this.$data[section] = true;
    },
    customFormatter(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    extractParams() {
      const params = this.$route.params;
      if (Object.keys(params).length === 0) {
        this.$router.push({ name: "bilet-sorgulama" });
      } else {
        this.biletBilgileri = params.result;
      }
    },
    closePopup() {
      this.showPopup = false;
      this.loader = false;
      this.timeUpdater = false;
      this.successResult = false;
      this.errorResult = false;
      this.deleteConfirmation = false;
    },
    getFullness() {
      var veri = {
        paramDay: moment(this.selectedDate).format("YYYY-MM-DD"),
        TerritoryId: this.biletBilgileri.TerritoryId,
        productID: this.biletBilgileri.ProductId,
      };
      this.openPopup("loader");
      axios
        .post(
          `${process.env.VUE_APP_ENV_SERVER}/Services/FlyzoneCrm/B2CServices/GetFullnessDealer`,
          JSON.stringify(veri),
          {
            headers: {
              "Content-Type": "application/json",
              "cache-control": "no-cache",
              dataType: "json",
            },
          }
        )
        .then((response) => {
          const veriler = response.data;
          this.timeIntervalsOpts = veriler;
        })
        .catch((e) => console.log(e))
        .finally(() => {
          this.openPopup("timeUpdater");
        });
    },
    changeTicket() {
      this.invalidTimeFlag = false;
      if (this.selectedTime !== null) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("OrderID", this.biletBilgileri.OrderID);
        urlencoded.append("CustomerGSM", this.biletBilgileri.CustomerGSM);
        urlencoded.append(
          "Startdate",
          `${moment(this.selectedDate).format("YYYYY-MM-DD")} ${
            this.selectedTime.starttime
          }:00.000`
        );
        urlencoded.append(
          "Enddate",
          `${moment(this.selectedDate).format("YYYYY-MM-DD")} ${
            this.selectedTime.endtime
          }:00.000`
        );

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow",
        };
        this.openPopup("loader");
        fetch(
          `${process.env.VUE_APP_ENV_SERVER}/Services/FlyzoneCrm/B2CServices/B2CUpdateTicket`,
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            result = JSON.parse(result);
            if (result.succes == true) {
              this.openPopup("successResult");
            } else {
              this.openPopup("errorResult");
            }
          })
          .catch((error) => {
            console.log("error", error);
            this.openPopup("errorResult");
          });
      } else {
        this.invalidTimeFlag = true;
      }
    },
    afterSuccess() {
      this.$router.push("bilet-sorgulama");
    },
    selectCloserSuitableDay(productID) {
      let dayToSelect = new Date(); /*assume today is selected by default*/
      if (dayToSelect.getHours() >= 21) {
        /*if clock after 20:00 set tomorrow*/
        dayToSelect.setDate(dayToSelect.getDate() + 1);
      }
      if (productID == 4) {
        /*if night flight set friday or sat*/
        if (dayToSelect.getDay() != 5 && dayToSelect.getDay() != 6) {
          dayToSelect.setDate(
            dayToSelect.getDate() + ((1 + 4 - dayToSelect.getDay()) % 4 || 4)
          );
        }
        this.$snotify.warning(
          "Night Flight Cuma ve Cumartesi günlerine özeldir, gelecek cumaya yönlendiriliyorsunuz...",
          "",
          {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            position: "centerCenter",
            bodyMaxLength: 200,
          }
        );
      }
      this.selectedDate = dayToSelect;
    },
  },
  computed: {
    filteredTimeInterval() {
      if (this.isToday(this.selectedDate)) {
        // if today
        const currTime = Number(new Date().getHours() * 100) + Number(new Date().getUTCMinutes());
        return this.timeIntervalsOpts.filter((el) => {
          const upComingTime = Number(el.starttime.replace(":", ""));
          if (currTime < upComingTime) {
            return el;
          } //compare ex: 12:00 < 15:30
        });
      } else {
        // if not today
        return this.timeIntervalsOpts
      }
    },
    filteredDisabledDates() {
      const disabledDates = {
        to: new Date(Date.now() - 8640000),
        days: [],
      };
      if (this.biletBilgileri.ProductId == 4) {
        disabledDates.days = [0, 1, 2, 3, 4];
        return disabledDates;
      } else {
        disabledDates.days = [0];
        return disabledDates;
      }
    },
  },
};
</script>

<style scoped lang="css">
.confirm-btn {
  background-image: linear-gradient(
    154deg,
    #70e4fd -25%,
    #dc005d 69%
  ) !important;
}
.result-section {
  display: flex;
  flex-flow: column;
  width: 85%;
}
#overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
}
.horizontal-row {
  display: flex;
  justify-content: space-between;
}
.horizontal-row .light-head {
  font-size: 12px;
  margin-top: 1.5rem;
}
.light-head-white {
  color: white !important;
  font-size: 12px !important;
}
.time-intervals {
  font-weight: 500;
  width: 100%;
  background-color: transparent;
  border: 1px solid #5b5b5b63;
  padding: 0.6rem;
  border-radius: 7px;
  transform: skew(-17deg);
  text-align: center;
  color: white;
  font-family: Montserrat;
  font-style: italic;
  font-size: 14px;
  appearance: none;
}
.time-intervals option {
  color: grey;
  font-size: 17px;
  font-family: arial;
  text-align: center;
}
>>> .time-intervals::-ms-expand {
  display: none;
}
.light-head,
.light-head-white {
  color: #5a636f;
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
  font-style: italic;
}
>>> .vdp-datepicker input {
  font-size: 14px;
  background-color: transparent;
  color: white;
  text-align: center;
  font-family: Montserrat;
  font-style: italic;
  border: 1px #5b5b5b63 solid;
  padding: 0.6rem 0;
  border-radius: 7px;
  transform: skew(-17deg);
  font-weight: 500;
  position: relative;
  margin-bottom: 20px;
  width: calc(100% + 2.05rem);
}
.date-time-selector {
  position: relative;
}
.time-intervals-section {
  position: relative;
}
.date-time-selector::after,
.time-intervals-section::after {
  content: " ";
  background-image: url("/images/blue-dropdown-arrow.svg");
  width: 1rem;
  height: 1rem;
  background-size: contain;
  position: absolute;
  right: 20px;
  top: 11px;
}

.pop-up-window {
  width: 17rem;
  height: auto;
  min-height: 18.5rem;
  position: absolute;
  background-color: #121924;
  margin: 10rem calc(50% - 10.5rem);
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
  padding: 1rem 2rem;
  z-index: 3;
}
.pop-up-window .close {
  color: white;
  font-size: 25px;
  position: absolute;
  top: 5px;
  right: 16px;
  cursor: pointer;
}
.pop-up-window .loadImg {
  left: 15%;
  top: 15%;
  max-width: 70%;
  opacity: 0.3;
}
.blue-button {
  width: 9rem;
  background-image: linear-gradient(154deg, #70e4fd -25%, #0066dc 69%);
  color: #fff;
  margin-top: 15px;
  height: 35px;
  transform: skew(-20deg);
  border-radius: 10px;
  border: solid 1px #313b46;
  font-size: 13px;
  font-weight: 600;
  font-family: Montserrat;
  cursor: pointer;
  margin-right: 1rem;
}
.sepetimTabloBaslik {
  padding: 0;
  margin: 0px 0 5px;
  border-bottom: 1px solid #313b46;
}
.page-header {
  font-size: 20px;
  font-weight: 700;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin-bottom: 1.5rem;
  margin-top: 10px;
}
.info-holder {
  display: flex;
  flex-direction: row;
  padding: 4rem 5rem;
  gap: 2rem;
}
.info-side {
  width: 100%;
}
.instruction-side {
  width: 100%;
  padding-top: 1rem;
}
.info-card {
  border-radius: 6px;
  background-color: #121924;
  padding: 1.5rem;
  overflow: auto;
}
.light-text {
  color: #5a636f;
  font-size: 14px;
}
.sepetimCol span:nth-child(2) {
  line-height: 19px;
}
.error {
  border: red 1px solid;
}

@media screen and (max-width: 1024px) {
}
@media only screen and (max-width: 769px) {
  .zamanBg {
    min-height: calc(82vh) !important;
  }
  .info-holder {
    display: flex;
    flex-direction: column;
    padding: 4rem 2rem;
  }
}
@media only screen and (max-width: 480px) {
  .sepetimCol {
    width: 37%;
  }
  .sepetimCol:nth-child(3) {
    display: block;
  }
  .info-holder {
    padding: 2rem 1rem;
  }
}
</style>
