<template>
  <div class="gelenlerForm kimlerForm">
    <h1>UÇUŞTA KİMLER OLACAK</h1>
    <div class="kimlerAciklama">
      <img src="images/loading-logo.png" alt="" />
      <span
        >Uçuşa geçmek için en az bir kişinin Ad Soyad ve Telefon bilgisini
        giriniz. Bu bilgileri doldurmak gişe işlemlerinde süreci
        hızlandıracaktır.
      </span>
    </div>
    <div class="kisiEkleRow">
      <div class="kisiForm">
        <input
          type="text"
          v-model="ad"
          class="textForm"
          placeholder="Ad"
          :class="{ error: this.errorAd }"
          @focus="errorSilAd()"
        />
        <input
          type="text"
          v-model="soyad"
          class="textForm"
          placeholder="Soyad"
          :class="{ error: this.errorSoyad }"
          @focus="errorSilSoyad()"
        />
        <input
          type="text"
          v-model="telefon"
          v-mask="'+90 (###) ### ## ##'"
          class="textForm"
          :class="{ error: this.errorTelefon }"
          @focus="errorSilTelefon()"
          v-on:keyup.enter="addPerson(ad, soyad, telefon)"
          placeholder="Telefon"
        />
      </div>
      <div class="rakamIslem">
        <div class="artir" @click="addPerson(ad, soyad, telefon)">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Güzel"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="icn-plus" fill="#000000" fill-rule="nonzero">
                <g id="plus">
                  <path
                    d="M23.1000027,13 L0.899997253,13 C0.403197026,13 0,12.5520019 0,12 C0,11.4479981 0.403197026,11 0.899997253,11 L23.1000027,11 C23.596803,11 24,11.4479981 24,12 C24,12.5520019 23.596803,13 23.1000027,13 Z"
                    id="Path"
                  ></path>
                  <path
                    d="M12,24 C11.4479981,24 11,23.596803 11,23.1000027 L11,0.899997253 C11,0.403197026 11.4479981,0 12,0 C12.5520019,0 13,0.403197026 13,0.899997253 L13,23.1000027 C13,23.596803 12.5520019,24 12,24 Z"
                    id="Path"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>

    <div class="listCol">
      <div class="listBorder">
        <div class="formRow" v-for="(item, index) in person" :key="index">
          <div class="baslikSayi">
            <span
              ><strong>{{ item.ad }} {{ item.soyad }}</strong>
              <div class="tel">{{ item.telefon }}</div></span
            >
          </div>
          <div class="rakamIslem">
            <div class="azalt" @click="deletePerson(index)">
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->
                <title>B07E5E16-63AB-425A-94EF-122520204E2E</title>
                <desc>Created with sketchtool.</desc>
                <g
                  id="Güzel"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g id="icn-minus" fill="#000000" fill-rule="nonzero">
                    <path
                      d="M23.0820404,12.7985948 L0.899297424,12.7985948 C0.402883504,12.7985948 0,12.3957113 0,11.8992974 C0,11.4028835 0.402883504,11 0.899297424,11 L23.0820404,11 C23.5784543,11 23.9813378,11.4028835 23.9813378,11.8992974 C23.9813378,12.3957113 23.5784543,12.7985948 23.0820404,12.7985948 Z"
                      id="Path"
                    ></path>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'
export default {
  name: 'KimlerForm',
  data() {
    return {
      person: [],
      ad: '',
      soyad: '',
      telefon: '',
      errorAd: false,
      errorSoyad: false,
      errorTelefon: false,
      totalPerson: 0,
    }
  },
  mounted() {
    if (this.person.length == 0) {
      this.$root.controlled = false
    }
  },
  created() {
    this.person = JSON.parse(localStorage.kisiler)
    if (this.person.length > 0) {
      this.$root.controlled = true
    }
  },
  methods: {
    addPerson: function(ad, soyad, telefon) {
      let currentContact = JSON.parse(window.localStorage.getItem('kisiler'))
      if(currentContact.length == 1) {
        this.$snotify.info('Maksimum miktarda kişi eklediniz, devam edebilirsiniz.', '', {
          timeout: 3000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
        })
        return
      }
      if (this.ad.length < 2) {
        this.errorAd = true
      }
      if (this.soyad.length < 2) {
        this.errorSoyad = true
      }
      if (this.telefon.length < 19) {
        this.errorTelefon = true
      }
      if ( this.ad.length >= 2 && this.soyad.length >= 2 && this.telefon.length == 19) {
        this.person.push({
          ad: ad,
          soyad: soyad,
          telefon: telefon.replace(/[^0-9]/g, '').substring(2),
        })
        localStorage.kisiler = JSON.stringify(this.person)

        this.ad = ''
        this.soyad = ''
        this.telefon = ''
        this.person = JSON.parse(localStorage.kisiler)
        if (this.person.length > 0) {
          this.$root.controlled = true
        }
        localStorage.totalPerson--
      }
    },
    deletePerson: function(index) {
      this.person.splice(index, 1)
      localStorage.kisiler = JSON.stringify(this.person)
      this.person = JSON.parse(localStorage.kisiler)
      console.log('silindi', index)
      localStorage.totalPerson++
      if (this.person.length == 0) {
        this.$root.controlled = false
      }
    },
    errorSilAd: function() {
      this.errorAd = false
    },
    errorSilSoyad: function() {
      this.errorSoyad = false
    },
    errorSilTelefon: function() {
      this.errorTelefon = false
    },
  },
  components: {
    TheMask,
  },
}
</script>
