<template>
  <div>
      <h1>{{$route.params.plan}}</h1>
    <Video></Video>
    <BiletmiEtkinlikmi></BiletmiEtkinlikmi>
  </div>
</template>

<script>
import Video from './Video'
import BiletmiEtkinlikmi from './BiletmiEtkinlikmi'
//import HomeHeader from './HomeHeader'
import axios from 'axios'
export default {
  name: 'Home',
  components: { Video, BiletmiEtkinlikmi },
  data() {
    return {
      visible: false,
      tickets: [],
    }
  },
  created() {
    window.localStorage.clear()
    this.menuInit()
  },
  mounted() {
    //this.loadContent()
  },
  methods: {
    menuInit() {
      this.$root.menuActive1 = false
      this.$root.menuLink1 = false
      this.$root.menuActive2 = false
      this.$root.menuLink2 = false
      this.$root.menuActive3 = false
      this.$root.menuLink3 = false
      this.$root.menuActive4 = false
      this.$root.menuLink4 = false
      this.$root.menuActive5 = false
      this.$root.menuLink5 = false
      this.$root.menuActive6 = false
      this.$root.menuLink16 = false
    },
    loadContent() {
      axios({
        url: `${process.env.VUE_APP_ENV_SERVER}/Services/FlyzoneCrm/B2CServices/GetContentB2C`,
        method: 'post',
        headers: {},
      }).then((response2) => {
        this.tickets = response2.data.ticketProducts
        window.localStorage.setItem( 'tickets', JSON.stringify(response2.data.ticketProducts))
        window.localStorage.setItem( 'products', JSON.stringify(response2.data.gitfProducts))
        window.localStorage.setItem('content', JSON.stringify(response2.data))
      })
    },
  },
}
</script>
