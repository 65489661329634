<template>
    <div>
        <div class="bgSiyah">
            <div class="container">
                <div class="biletListe">
                    <h1>BİLETLERİM</h1>
                    <div class="listCol">
                        <div class="listBorder">
                        <template v-for="(item, index) in biletler" >
                            <div class="biletRow" @click="biletDetay(index)" :class="{'active': item.aktifMi === true, 'secili': item.seciliMi===true}" :key="'bilet'+index">
                                <div class="biletOrtala">
                                    <span class="gosterge"></span>
                                    <span class="flight">{{item.paket}}</span>
                                    <span class="icn-right">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                            <g id="Güzel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="icn-right" fill="#000000" fill-rule="nonzero">
                                                    <g id="right-arrow-(4)-copy" transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) translate(6.000000, 0.000000)">
                                                        <path d="M1.352657,23.7517241 C1.19806763,23.9172414 1.00483092,24 0.792270531,24 C0.579710145,24 0.38647343,23.9172414 0.231884058,23.7517241 C-0.077294686,23.4206897 -0.077294686,22.8827586 0.231884058,22.5517241 L10.0869565,12 L0.231884058,1.44827586 C-0.077294686,1.11724138 -0.077294686,0.579310345 0.231884058,0.248275862 C0.541062802,-0.0827586207 1.04347826,-0.0827586207 1.352657,0.248275862 L11.7681159,11.4 C12.0772947,11.7310345 12.0772947,12.2689655 11.7681159,12.6 L1.352657,23.7517241 L1.352657,23.7517241 Z" id="Path" transform="translate(6.000000, 12.000000) rotate(-180.000000) translate(-6.000000, -12.000000) "></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                    <span class="biletSaat">{{item.saat}}</span>
                                    <span class="biletTarih">{{item.tarih}}</span>
                                </div>
                            </div>
                           </template> 
                        </div>
                    </div>
                </div>
                <div class="biletlerimSag">
                    <h2>UÇUŞA GEÇMEK İÇİN HEMEN BİLET AL</h2>
                    <a href="/nerede" class="odemeBtn"><span>BİLET AL</span></a>
                </div>
            </div>
        </div>
        <div class="biletDetay" :class="{'active': detayAcilsinMi===true}">
            <div class="mobilBiletDetayBaslik">
                <img src="images/icn-left.png" alt="" @click="detayKapat">
                <h2>BİLET DETAY</h2>
            </div>
            <div class="biletDetayBaslik">
                <div class="biletDetayPaket" v-text="detayPaket"></div>
                <div class="biletDetayKonum" v-text="detayKonum"></div>
                <div class="biletDetayTarih">{{detayTarih}}</div>
                <div class="biletDetaySaat">{{detaySaat}}</div>
            </div>
            <div class="biletTabloContent">
                <div class="biletDetayTabloBaslik">
                    <div class="biletDetayCol">Paket</div>
                    <div class="biletDetayCol">Kişi</div>
                    <div class="biletDetayCol">Ücret</div>
                </div>
                <div class="biletDetayTabloRow" v-for="item in biletDetaylar[0]" :key="item">
                    <div class="biletDetayCol" v-text="item.urun"></div>
                    <div class="biletDetayCol" v-text="item.kisi"></div>
                    <div class="biletDetayCol" v-text="item.birimFiyat+ ' ₺'"></div>
                </div>
                
            </div>
            <div class="kapat" @click="detayKapat">Kapat</div>
            <div class="biletSepetUcret">
                <div>Toplam: <span v-text="araToplam"></span> ₺</div>
                <div>Vergi %18: <span v-text="vergi"></span> ₺</div>
                <div class="biletToplamUcret">Toplam Ücret: <strong><span v-text="total"> </span> ₺</strong></div>
            </div>
        </div>
        <Footer></Footer>
    </div>   
</template>

<script>
import Footer from './Footer'
import '../biletler'
export default {
    name:"Biletlerim",
    data(){
        return{
            biletler:[],
            detayAcilsinMi:false,
            biletDetaylar:[],
            detayPaket:"",
            detayKonum:"",
            detayTarih:null,
            detaySaat:null,
            araToplam:null,
            vergi:null,
            total:null,
        }
    },
     created(){
      this.biletler = biletler.biletler
    },
    methods: {
        biletDetay(e){
            this.detayAcilsinMi=true
            this.biletler.find(item => {
                item.seciliMi=false
             }),
            this.biletler[e].seciliMi= true
            this.detayPaket = this.biletler[e].paket
            this.detayKonum = this.biletler[e].konum
            this.detayTarih = this.biletler[e].tarih
            this.detaySaat = this.biletler[e].saat
            this.biletDetaylar.splice(0,1)
            this.biletDetaylar.push(this.biletler[e].sepet)
            this.araToplam=0
            this.vergi=0
            this.biletDetaylar[0].forEach((value, index) => {
                this.araToplam += parseFloat(value.birimFiyat)
                this.vergi=parseFloat(parseFloat(this.araToplam) - (parseFloat(this.araToplam)*0.98)).toFixed(2)
                this.total= parseFloat(this.araToplam) + parseFloat(this.vergi)
            });
        },
        detayKapat(){
            this.detayAcilsinMi=false
            this.biletler.find(item => {
                item.seciliMi=false
             })
        }
    },
    components:{
        Footer
    }
}
</script>
