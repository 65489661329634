<template>
  <div class="butons">
    <div
      class="colButon"
      style="background-image: url('/images/bilet-satin-al.jpg')"
    >
      <router-link to="/nerede">
        <div class="ortala">
          <h2>BİLET SATIN AL</h2>
          <img src="images/icn-right.svg" alt="" />
        </div>
      </router-link>
    </div>
    <div
      class="colButon bulk-section yearly-card-section" style="filter:grayscale(0)"
    >
    <a href="/yillik-kart-odeme">
    <img src="/images/kartPanel3.png" style="margin:auto; width:80%;">
  </a>
 <!--
    <div style="margin: 0rem -2rem 0px 0;">
      <h2 class="unhovered"> DAHA ÇOK UÇURAN PAKETLER </h2>
      <div class="packages">
      <a @click="buyBulkTicket(12,40)" href="javascript:;">
          <h3 class="bulk-pack">
            10 Bilete 12 Bilet
            <span class="notes">* 1 kişi için geçerlidir</span>
          </h3>
          
      </a>
      <a @click="buyBulkTicket(25,41)" href="javascript:;">
        <h3 class="bulk-pack">
          20 Bilete 25 Bilet
          <span class="notes">* 1 kişi için geçerlidir</span>
        </h3>
        
      </a>
      </div>
    -->


    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "biletmiEtkinlikmi",
  methods: {
    buyBulkTicket(tickets, CampaignId) {
      this.$root.bulkTicket = { tickets, CampaignId };
      window.localStorage.setItem('bulkTicket', JSON.stringify(this.$root.bulkTicket))
      this.$router.push("/nerede");
    },
  },
  mounted() {
    this.$root.tabName = 'gelenler',
    this.$root.bulkTicket = JSON.parse(window.localStorage.getItem('bulkTicket'))|| null
  }
};
</script>

<style lang="css" scoped>
.bulk-section {
  flex-direction: column;
  justify-content: center;
  background-image: url('/images/etkinlik-planla.jpg');
}
.colButon h2::before {
  height: 50%;
}
.relative {
  position: relative;
}
.bulk-section .packages {
  display: flex;
  max-width: 24rem;
  margin: 2rem 0 0 0;
}
.bulk-pack {
  text-align: left;
  max-width: 8rem;
  transform: skew(-8deg);
  border: 2px #1d87e5 solid;
  border-radius: 5px;
  height: 5rem;
  align-self: center;
  padding: 1rem;
}
.bulk-pack::after {
  content: " ";
  background-image: url('/images/icn-ok-right.svg');
  width: 2rem;
  height: 2rem;
  display: block;
  height: 1.8rem;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 0.5rem;
}
.colButon h2.unhovered {
  max-width: 23rem;
  text-align: left;
  transform: skew(-8deg);
  margin-left: 1rem;
}
.colButon h2.unhovered::before {
  all: unset;
}
@media only screen and (max-width: 370px) {
  .bulk-section .packages {
    margin: 2rem 0 0px 15px;
  }
  .bulk-pack {
    max-width: 6rem;
  }
}

span.notes {
    font-size: 12px;
    font-weight: normal;
    margin-top: 5px;
}


</style>
