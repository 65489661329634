<template>
  <div class="gelenlerForm">
    <div class="corapAciklama">
      <img src="images/icn-corap.png" alt="" />
      <span
        >Güvenlik nedeniyle altı kaydırmaz silikonlu çorap giyilmesi
        zorunludur.<br />
        Daha önceden çorap aldıysanız yanınızda getirmeyi unutmayın!<br />
      </span>
    </div>
    <div
      class="formRow corapVar"
      @click="corapVar()"
      :class="{ active: this.corapFlag }"
    >
      <div class="baslikSayi">
        <span>Uçuşa katılacak herkesin çorabı var</span>
        <div class="personSayi">
          <span></span>
        </div>
      </div>
    </div>
    <div class="corapAciklama alt" style="margin-top:20px">
      <span
        ><strong
          >Lütfen ayakkabı numaranıza uygun çorap seçimi yapınız.
          <!-- <i v-text="$root.toplamSayi"></i> -->
        </strong></span
      >
    </div>
    <div class="formRow" v-for="(item, index) in socks" :key="index">
      <div class="baslikSayi">
        <span>{{ item.BodySize + ' - ' + item.ProductName }}</span>
        <div class="personSayi">
          <span v-text="item.Quantity"></span>
        </div>
      </div>
      <div class="rakamIslem">
        <div class="azalt" @click="corapMinus(index)">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Güzel"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="icn-minus" fill="#000000" fill-rule="nonzero">
                <path
                  d="M23.0820404,12.7985948 L0.899297424,12.7985948 C0.402883504,12.7985948 0,12.3957113 0,11.8992974 C0,11.4028835 0.402883504,11 0.899297424,11 L23.0820404,11 C23.5784543,11 23.9813378,11.4028835 23.9813378,11.8992974 C23.9813378,12.3957113 23.5784543,12.7985948 23.0820404,12.7985948 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </svg>
        </div>
        <div class="artir" @click="corapPlus(index)">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Güzel"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="icn-plus" fill="#000000" fill-rule="nonzero">
                <g id="plus">
                  <path
                    d="M23.1000027,13 L0.899997253,13 C0.403197026,13 0,12.5520019 0,12 C0,11.4479981 0.403197026,11 0.899997253,11 L23.1000027,11 C23.596803,11 24,11.4479981 24,12 C24,12.5520019 23.596803,13 23.1000027,13 Z"
                    id="Path"
                  ></path>
                  <path
                    d="M12,24 C11.4479981,24 11,23.596803 11,23.1000027 L11,0.899997253 C11,0.403197026 11.4479981,0 12,0 C12.5520019,0 13,0.403197026 13,0.899997253 L13,23.1000027 C13,23.596803 12.5520019,24 12,24 Z"
                    id="Path"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CorapForm',
  data() {
    return {
      socks: [],
      isActive: false,
      corapToplamSayi: 0,
      seciliurunler: [],
      corapFlag: false,
    }
  },
  created() {
    if(window.localStorage.corapFlag != undefined) {
      this.corapFlag = window.localStorage.corapFlag
    } else {
      window.localStorage.setItem('corapFlag', false)
    }
    /*if(window.localStorage.RestrictionProducts) {
      this.seciliurunler = window.localStorage.RestrictionProducts
    }*/
    
    var itemid = this.$root.itemid;

    this.$root.toplamSayi =
    parseInt(this.$root.personCount) + parseInt(this.$root.childCount)
    this.corapToplamSayi = 0
    this.$root.silikonluCorapSayi = 0
    var ticket = JSON.parse(localStorage.tickets)
    var findticket = ticket.find((x) => x.ProductID === itemid)
    var products = JSON.parse(localStorage.products)

    this.$root.urunler=JSON.parse(localStorage.RestrictionProducts||null)
    var seciliurunler=JSON.parse(localStorage.RestrictionProducts||'[]')
    for(var i=0;i<seciliurunler.length;i++){
        this.$root.corapSepetToplam +=seciliurunler[i].Quantity*seciliurunler[i].Price 
    }

    var itemproducts = []
    for (var i = 0; i < findticket.RestrictionProducts.length; i++) {
      var findproduct = products.find(
        (x) => x.ProductID === findticket.RestrictionProducts[i]
      )
      if (findproduct !== undefined) {
        findproduct.Quantity = 0
        itemproducts.push(findproduct)
      }
    }
    this.socks = itemproducts
    this.seciliurunler = this.socks.filter(function(x) {
      if (x.Quantity > 0) {
        return x
      }
    })
    localStorage.RestrictionProducts = JSON.stringify(this.seciliurunler)
    localStorage.totalPerson =
      parseInt(localStorage.yetiskinSayisi) + parseInt(localStorage.cocukSayisi)

      this.corapHesapla();
  },
  watch: {
    corapFlag(val) {
      window.localStorage.corapFlag=  val
      this.$root.corapFlag = val
    }
  },
  methods: {
    corapPlus(i) {
      if (this.$root.toplamSayi === 0) {
        return false
      } else {
        this.socks[i].Quantity++
        this.corapToplamSayi++
        this.$root.toplamSayi--
        this.$root.silikonluCorapSayi++
      }
      this.$root.corapUcret = this.socks[i].Price

      this.corapHesapla()
      this.$root.urunler = JSON.parse(localStorage.RestrictionProducts)
    },
    corapMinus(i) {
      if (this.socks[i].Quantity === 0) {
        return false
      } else {
        this.socks[i].Quantity--
        this.corapToplamSayi--
        this.$root.toplamSayi++
        this.$root.silikonluCorapSayi--
      }
      this.$root.corapUcret = this.socks[i].Price
      this.corapHesapla()
      this.$root.urunler = JSON.parse(localStorage.RestrictionProducts)
    },
    corapHesapla() {
      this.seciliurunler = this.socks.filter(function(x) {
        if (x.Quantity > 0) {
          return x
        }
      })
      localStorage.RestrictionProducts = JSON.stringify(this.seciliurunler)
      this.$root.corapSepetToplam = 0
      for (var i = 0; i < this.seciliurunler.length; i++) {
        this.$root.corapSepetToplam +=
          this.seciliurunler[i].Quantity * this.seciliurunler[i].Price
      }

      this.$root.sepetAraToplam = parseFloat(
        parseFloat(this.$root.yetiskinToplamTutar) +
          parseFloat(this.$root.cocukToplamTutar) +
          parseFloat(this.$root.corapSepetToplam)
      ).toFixed(2)
      // this.$root.kdvHesapla = parseFloat(parseFloat(this.$root.sepetAraToplam) * parseFloat(this.$root.kdv)).toFixed(2)
      this.$root.sepetToplam = parseFloat(this.$root.sepetAraToplam).toFixed(2)
    },
    corapVar() {
      this.corapFlag = !this.corapFlag
    },
  },
  components: {},
}
</script>
