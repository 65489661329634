<template>
<div style="display:none;">
    <!--<div id="CitizenContainer" class="citizen-container" :class="{'show': !isCitizen}">-->
    <div id="CitizenContainer" class="citizen-container" style="display:none;"></div>
        <div class="textInfo">
            <div class="">
                <p class="text">
                    TL fiyatlardan sadece T.C. vatandaşlığı bulunan misafirler faydalanabilir!<br>
                    This Turkish Lira’s prices are for only Turkish Citizens guest!
                </p>
                    
        <div class="select-container" v-if="!ticketInfo">

            <div class="wrap">
            <div class="select-box">
                <div class="select-box__current" tabindex="1">
                    <div class="select-box__value">
                    <input class="select-box__input" type="radio" id="f0" value="-1" name="citizenStatus"  checked="checked"/>
                    <p class="select-box__input-text">Seçiniz / Choose</p>
                    </div>

                    <div class="select-box__value">
                    <input class="select-box__input" type="radio" id="citizen" value="1" name="citizenStatus" />
                    <p class="select-box__input-text">T.C. Vatandaşıyım <br> Turkish Citizen</p>
                    </div>
                    <div class="select-box__value">
                    <input class="select-box__input" type="radio" id="notcitizen" value="0" name="citizenStatus" />
                    <p class="select-box__input-text">T.C. Vatandaşı değilim <br> Not Turkish Citizen</p>
                    </div>
                    
                    <img class="select-box__icon" src="images/icn-down.png" alt="Arrow Icon" aria-hidden="true"/>
                </div>
                <ul class="select-box__list">
                    <li><label class="select-box__option" for="citizen" aria-hidden="aria-hidden" >T.C. Vatandaşıyım <br> Turkish Citizen</label></li>
                    <li><label class="select-box__option" for="notcitizen" aria-hidden="aria-hidden" >T.C. Vatandaşı değilim <br> Not Turkish Citizen</label></li>                
                </ul>
            </div>
            </div>
            <button type="button" class="submit"  @click="citizenCheck()">
                <span></span>
            </button>

            
        </div>

        <div id="ticketInfoContainer" class="ticketInfoContainer" v-if="ticketInfo">
            <img src="images/counter.png" aria-hidden="true" class="ico"/>
            <div class="text">
                <strong>We welcome you to our branch.</strong><br>
                <p>
                    You can go to our branch of your choice and get your ticket from the box office.<br>Enjoy!
                </p>
            </div>
            <div class="goback">
                <a href="https://www.flyzoneturkey.com/">Flyzone Website</a>
            </div>
        </div>

           
                
            </div>
        </div>
    </div>
</div>    
</template>


<script>
export default {
    name:"CitizenDate",
   data(){
        
        //this.citizenCheckPoint();
        return{
            isCitizen: 0,
            ticketInfo:false,

        }
    },
    props:{
        
    },
    computed: {
    },
    methods:{
        
        citizenCheckPoint() {
            var check = parseInt(localStorage.citizen);
            if (check === 1) {
                this.isCitizen = 1;
            } else {
                this.isCitizen = 0;
                this.ticketInfo = true;
            }
        },
        citizenCheck() {
            /*
            var CitizenContainer = document.getElementById('CitizenContainer');
            var status = parseInt(document.querySelector('input[name="citizenStatus"]:checked').value);




            if(status === 1) {
                console.log("citizen");
                localStorage.setItem('citizen', 1);
                this.citizenCheckPoint();
            }
            if(status === 0) {
                console.log("not citizen")
                localStorage.setItem('citizen', 0);
                this.ticketInfo = true;

            }

            */
        }
        
    },
    computed: {
       
       
    }
    
}
</script>