<template>
  <div>
    <div class="load" v-if="isLoading">
      <div class="loadImgs">
        <img src="images/loading-logo.png" alt="" />
        <img src="images/loading.png" class="loadImg" alt="" />
      </div>
    </div>
    <PaketDataNew />
    
    <!--
    <PaketDataOld />
    -->
    <Footer />
  </div>
</template>

<script>
import moment from "moment";
import PaketDataNew from "./PaketDataNew";
import PaketDataOld from "./PaketData";
import Footer from "./Footer";
export default {
  name: "Paketler",
  components: { PaketDataNew, Footer, PaketDataOld },
  data() {
    return {
      isLoading: true,
      adres: localStorage.adres,
      sube: localStorage.konum,
    };
  },
  methods: {
    checkBulkTickets() {
      if (this.bulkTicket != null) {
        this.setSelectedPackage(this.bulkTicket);
        this.$router.push("/kimler");
      }
    },
    setSelectedPackage(bulkTicket) {
      const baseFlight = JSON.parse(window.localStorage.getItem("tickets")).filter((x) => x.ProductName == "Base Flight")[0];
      window.localStorage.setItem("paket",JSON.stringify([baseFlight]));
      window.localStorage.setItem("secilipaket", JSON.stringify(baseFlight));
      window.localStorage.setItem("yetiskinSayisi", bulkTicket.tickets);
      window.localStorage.setItem("cocukSayisi", 0);
      window.localStorage.setItem("saat", "10:00");
      window.localStorage.setItem("bitisSaat", "20:00");
      window.localStorage.setItem("RestrictionProducts", "[]");
      window.localStorage.setItem("tarih", moment(new Date()).format("DD/MM/YYYY"));
    },
  },
  computed: {
    bulkTicket() {
      return this.$root.bulkTicket;
    },
  },
  created() {
    this.$root.sepetBos = false;
    this.isLoading = false;
    this.$root.menuActive1 = false;
    this.$root.menuLink1 = true;
    this.$root.menuActive2 = true;
    this.$root.menuLink2 = false;
    this.$root.menuActive3 = false;
    this.$root.menuLink3 = false;
    this.$root.menuActive4 = false;
    this.$root.menuLink4 = false;
    this.$root.menuActive5 = false;
    this.$root.menuLink5 = false;
    this.$root.menuActive6 = false;
    this.$root.menuLink6 = false;
  },
  mounted() {
    document.body.style.backgroundColor = "black";
    this.checkBulkTickets()
  },
  beforeDestroy() {
    document.body.style.backgroundColor = "";
  },
};
</script>
<style scoped></style>
