<template>
  <div>
    <div class="zamanBg">
      <div class="container">
        <KimlerForm></KimlerForm>
        <div class="sepetSidebar">
          <Sepet></Sepet>
          <div class="butonlar butonlarRelocate butonlarRelocateForKimler">
            <a @click="tabDegistir('coraplar')" ><span>GERİ</span></a>
            <a @click="goToPay()" :class="{ 'aTagDisabled': $root.controlled != true }" ><span>İLERİ</span></a>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from './Footer'
import KimlerForm from './KimlerForm'
import Sepet from './Sepet'
export default {
  name: 'Kimler',
  data() {
    return {}
  },
  created() {
    if(!window.localStorage.getItem('kisiler')) {
      window.localStorage.setItem('kisiler', '[]')
    }
    this.$root.menuActive1 = false
    this.$root.menuLink1 = true
    this.$root.menuActive2 = false
    this.$root.menuLink2 = true
    this.$root.menuActive3 = false
    this.$root.menuLink3 = true
    this.$root.menuActive4 = false
    this.$root.menuLink4 = true
    this.$root.menuActive5 = true
    this.$root.menuLink5 = false
    this.$root.menuActive6 = false
    this.$root.menuLink6 = false
    if (
      localStorage.yetiskinSayisi === undefined ||
      localStorage.cocukSayisi === undefined
    ) {
      this.$router.push('/gelenler')
    } else if (
      localStorage.yetiskinSayisi < 1 &&
      localStorage.cocukSayisi < 1
    ) {
      this.$router.push('/gelenler')
    }
  },
  components: {
    KimlerForm,
    Sepet,
    Footer,
  },
  methods: {
    goToPay() {
      let currentContact = JSON.parse(localStorage.kisiler)
      if(currentContact.length == 0) {
        this.$snotify.warning('En az 1 kişi eklemelisinz.', '', {
          timeout: 3000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
        })
      } else {
        this.$router.push('/odeme')
      }
    },
    tabDegistir(val) {
      const bulkTicket = this.$root.bulkTicket;
      if(bulkTicket == null && this.$root.ticketType === 1) {
        this.$root.tabDegistir('coraplar')
        this.$router.push('/ne-zaman')
      } 
      else if(bulkTicket == null && this.$root.ticketType === 5) {
        this.$root.tabDegistir('gelenler')
        this.$router.push('/gelenler')
      }
      else if(this.$root.ticketType === 10) {
        this.$router.push('/ne-zaman')
      }else {
        this.$router.push('/')
      }
    }
  }
}
</script>
