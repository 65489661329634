<template>
  <div class="zamanBg">
    <div class="query-container">
      <h1 class="page-header">BİLET DEĞİŞİKLİĞİ VE İPTALİ</h1>
      <div class="form-container">
        <div class="form-group baslikSayi" :class="{ error: phoneFlag }">
          <input
            type="text"
            v-model="phone"
            name="CepTelefonu"
            v-mask="'+90 (###) ### ## ##'"
            required="required"
            @blur="checkPhoneFlag()"
          />
          <label for="input" class="control-label">Cep Telefonu</label>
          <p class="input-info">Biletinize kayıtlı cep telefon numaranız.</p>
        </div>
        <div class="form-group baslikSayi" :class="{ error: emailCodeFlag }">
          <input
            type="text"
            v-mask="'######'"
            v-model="emailCode"
            name="MailKodu"
            required="required"
            @blur="checkEmailCodeFlag()"
          />
          <label for="input" class="control-label">Sipariş No</label>
          <p class="input-info">
            Biletinize kayıtlı mail adresine gönderilen 6 haneli kodu giriniz.
          </p>
        </div>
        <button class="blue-button" @click="startQuery()">BİLET SORGULA</button>
      </div>
    </div>
    <div id="overlay" v-if="showPopup">
      <div class="pop-up-window">
        <span class="close" @click="closePopup()" v-if="noData">x</span>
        <section v-if="noData">
          <img
            src="/images/error-cross.svg"
            alt="error-cross"
            style="width: 100%"
          />
          <h1 class="page-header">Bilet bilgileri hatalı!</h1>
        </section>
        <section v-if="loader">
          <img src="/images/loading.png" class="loadImg" alt="error-cross" />
          <h1 class="page-header">Bilet bilgileri yükleniyor...</h1>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BiletSorgulama",
  data() {
    return {
      phone: "",
      emailCode: "",
      phoneFlag: false,
      emailCodeFlag: false,
      showPopup: false,
      loader: false,
      noData: false,
      errMsg: ""
    };
  },
  methods: {
    closePopup() {
      this.loader = false;
      this.noData = false;
      this.showPopup = false;
    },
    hasInvalidInput() {
      if (this.phone.length != 19) {
        this.phoneFlag = true;
      }
      if (this.emailCode.length != 6) {
        this.emailCodeFlag = true;
      }
      return this.phoneFlag || this.emailCodeFlag;
    },
    checkEmailCodeFlag() {
      if (this.emailCode.length != 6 && this.emailCode != "") {
        this.emailCodeFlag = true;
      } else {
        this.emailCodeFlag = false;
      }
    },
    checkPhoneFlag() {
      if (this.phone.length != 19 && this.phone != "") {
        this.phoneFlag = true;
      } else {
        this.phoneFlag = false;
      }
    },
    startQuery() {
      if (!this.hasInvalidInput()) {
        const formatedPhone = this.phone
          .replace("+90", "")
          .replace("(", "")
          .replace(")", "")
          .replace(/\s/g, "");
        this.getOrder(formatedPhone, this.emailCode);
      }
    },
    getOrder(phone, emailCode) {
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      let urlencoded = new URLSearchParams();
      urlencoded.append("OrderID", emailCode); //5078141804
      urlencoded.append("CustomerGSM", phone); //118790
      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };
      this.showPopup = true;
      this.loader = true;
      fetch(
        `${process.env.VUE_APP_ENV_SERVER}/Services/FlyzoneCrm/B2CServices/B2CGetOrder`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          this.loader = false;
          result = JSON.parse(result);
          result.OrderID = emailCode
          if (result.TerritoryId !== null) {
            this.$router.push({ name: "bilet-bilgileri", params: { result } });
          } else {
            this.noData = true;
          }
        })
        .catch((error) => {
          console.log("error connection", error);
          this.loader = false;
          this.noData = true;
        });
    },
  },
  computed: {
    phoneCount() {
      return this.phone.length;
    },
  },
};
</script>

<style scoped>
#overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
}
.loadImg {
  left: 15%;
  top: 15%;
  max-width: 70%;
  opacity: 0.3;
}
.pop-up-window {
  width: 18rem;
  height: 18rem;
  position: absolute;
  background-color: #121924;
  margin: 10rem calc(50% - 9rem);
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  flex-direction: column;
}
.pop-up-window h1 {
  font-size: 16px;
  margin-top: 2rem;
}
.pop-up-window .close {
  color: white;
  font-size: 25px;
  position: absolute;
  top: 5px;
  right: 16px;
  cursor: pointer;
}
.query-container {
  padding: 4rem 8rem;
  max-width: 75rem;
}
.error {
  border: solid 1px red;
}
.page-header {
  font-size: 20px;
  font-weight: 700;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin-bottom: 1.5rem;
}
.form-group .control-label {
  top: 0;
  transform: skew(20deg);
}
.form-container {
  display: flex;
  align-items: center;
  gap: 15px;
}
.baslikSayi {
  width: 100%;
}
.form-group input,
.form-group textarea {
  padding: 7px 0;
  font-size: 14px;
}
.form-group input:focus ~ .control-label,
.form-group input:valid ~ .control-label {
  display: none;
}
.blue-button {
  width: 100%;
  background-image: linear-gradient(154deg, #70e4fd -25%, #0066dc 69%);
  color: #fff;
  margin-top: -4px;
  height: 45px;
  transform: skew(-20deg);
  border-radius: 10px;
  border: solid 1px #313b46;
  font-size: 15px;
  font-weight: bold;
  font-family: Montserrat;
  cursor: pointer;
}
.input-info {
  font-size: 10px;
  transform: skew(30deg);
  font-weight: lighter;
  color: gray;
  line-height: 14px;
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}
.input-info::before {
  content: " ";
  background-image: url("/images/info-dot.svg");
  height: 17px;
  width: 17px;
  position: absolute;
  left: -21px;
  background-size: cover;
  transform: skew(-16deg);
  top: -2px;
}
@media screen and (max-width: 1024px) {
  .query-container {
    padding: 4rem 4rem;
  }
}
@media only screen and (max-width: 769px) {
  .query-container {
    padding: 3rem 2rem;
  }
  .zamanBg {
    min-height: calc(82vh) !important;
  }
}
@media only screen and (max-width: 480px) {
  .input-info {
    margin-left: 7px;
  }
  .form-container {
    flex-direction: column;
  }
  .blue-button {
    margin-top: 1.3rem;
  }
}
</style>
