<template>
<div>
    <div class="bgSiyah">
        <div class="container">
            <div class="gelenlerForm mobilBaslik">
                <h1>YENİ FATURA EKLE</h1>
                <div class="faturaRow">
                    <div class="row mobilKayitliFatura">
                    <div class="form-group">
                    <div class="select-box">
                        <div class="select-box__current" tabindex="2">
                            
                            <div class="select-box__value">
                            <input class="select-box__input" @change="faturaSec('Bilge Yılmaz')" type="radio" id="kayitli1" value="Bilge Yılmaz" name="KayitliFatura"/>
                            <p class="select-box__input-text">Bilge Yılmaz</p>
                            </div>
                            <div class="select-box__value">
                            <input class="select-box__input" @change="faturaSec('CRM Medya A.Ş')" type="radio" id="kayitli2" value="CRM Medya A.Ş" name="KayitliFatura"/>
                            <p class="select-box__input-text">CRM Medya A.Ş</p>
                            </div>
                            <div class="select-box__value">
                            <input class="select-box__input" @change="faturaSec('Kayıtlı Faturalarım')" type="radio" id="kayitli0" value="0" name="KayitliFatura"  checked="checked"/>
                            <p class="select-box__input-text">Kayıtlı Faturalarım</p>
                            </div>
                            
                            <img class="select-box__icon" src="images/icn-down.png" alt="Arrow Icon" aria-hidden="true"/>
                        </div>
                        <ul class="select-box__list">
                            <li><label class="select-box__option" for="kayitli0" aria-hidden="aria-hidden">Kayıtlı Faturalarım</label></li>
                            <li><label class="select-box__option" for="kayitli1" aria-hidden="aria-hidden">Bilge Yılmaz</label></li>
                            <li><label class="select-box__option" for="kayitli2" aria-hidden="aria-hidden">CRM Medya A.Ş</label></li>
                        </ul>
                    </div>
                    </div>
                    <div class="form-group yarimText">
                        <input type="text" name="AdSoyad" v-model="adSoyad" required="required" autocomplete="off" @change="isimKayit(adSoyad)"/>
                        <label for="input" class="control-label">Ad Soyad</label><i class="bar"></i>
                    </div>
                    </div>


                    <div class="row">
                    <div class="form-group yarimText">
                    <div class="select-box">
                        <div class="select-box__current" tabindex="1">
                            
                            <div class="select-box__value">
                            <input class="select-box__input" @change="faturaSec('Kurumsal Fatura')" type="radio" id="f1" value="Kurumsal Fatura" name="Fatura"/>
                            <p class="select-box__input-text">Kurumsal Fatura</p>
                            </div>
                            <div class="select-box__value">
                            <input class="select-box__input" @change="faturaSec('Bireysel Fatura')" type="radio" id="f2" value="Bireysel Fatura" name="Fatura"/>
                            <p class="select-box__input-text">Bireysel Fatura</p>
                            </div>
                            <div class="select-box__value">
                            <input class="select-box__input" @change="faturaSec('Fatura Seçiniz')" type="radio" id="f0" value="0" name="Fatura"  checked="checked"/>
                            <p class="select-box__input-text">Fatura Seçiniz</p>
                            </div>
                            
                            <img class="select-box__icon" src="images/icn-down.png" alt="Arrow Icon" aria-hidden="true"/>
                        </div>
                        <ul class="select-box__list">
                            <li><label class="select-box__option" for="f0" aria-hidden="aria-hidden">Fatura Seçiniz</label></li>
                            <li><label class="select-box__option" for="f1" aria-hidden="aria-hidden">Kurumsal Fatura</label></li>
                            <li><label class="select-box__option" for="f2" aria-hidden="aria-hidden">Bireysel Fatura</label></li>
                        </ul>
                    </div>
                    </div>
                    <div class="form-group yarimText">
                        <input type="text" name="AdSoyad" v-model="adSoyad" required="required" autocomplete="off" @change="isimKayit(adSoyad)"/>
                        <label for="input" class="control-label">Ad Soyad</label><i class="bar"></i>
                    </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <input type="text" name="TcNo" required="required" v-mask="'###########'" v-model="tcNo" @change="tcNoKayit(tcNo)" autocomplete="off"/>
                            <label for="input" class="control-label">TC Numarası</label><i class="bar"></i>
                        </div>
                        <div class="form-radio tcRadio">
                            <div class="radio">
                                <label>
                                <input type="checkbox" name="radio" @change="turkDegil('TC Vatandaşı Değilim')" /><i class="helper"></i>TC Vatandaşı Değilim
                                </label>
                            </div>
                            
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group yarimText">
                            <input type="text" name="Telefon" v-mask="'+90 (###) ### ## ##'" required="required" autocomplete="off" v-model="telefon" @change="telefonKayit(telefon)"/>
                            <label for="input" class="control-label">Telefon</label><i class="bar"></i>
                        </div>
                        <div class="form-group yarimText">
                            <input type="text" name="PostaKodu" v-mask="'#####'" required="required" autocomplete="off" v-model="postaKodu" @change="postaKoduKayit(postaKodu)"/>
                            <label for="input" class="control-label">Posta Kodu</label><i class="bar"></i>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <input type="text" name="Adres" required="required" autocomplete="off" v-model="adres" @change="adresKayit(adres)"/>
                            <label for="input" class="control-label">Adres</label><i class="bar"></i>
                        </div>
                    </div>
                    <div class="row">
                        <div class="iller yarimText">
                            <div class="select-box">
                                <div class="select-box__current" tabindex="2">
                                    <div class="select-box__value" v-for="item in iller" :key="item">
                                        <input class="select-box__input" type="radio" :id="item.plaka" @change="ilKayit(item.il)" :value="item.il" name="Il" />
                                        <p class="select-box__input-text">{{item.il}}</p>
                                    </div>
                                    <div class="select-box__value">
                                        <input class="select-box__input" type="radio" id="0" value="0" name="Il" checked="checked"/>
                                        <p class="select-box__input-text">İl Seçiniz</p>
                                    </div>
                                    <img class="select-box__icon" src="images/icn-down.png" alt="Arrow Icon" aria-hidden="true"/>
                                </div>
                                <ul class="select-box__list">
                                    <li><label class="select-box__option" for="0" aria-hidden="aria-hidden">İl Seçiniz</label></li>
                                    <li v-for="i in iller" :key="i">
                                    <label class="select-box__option" @click="ilceleriGetir(i.plaka)" :for="i.plaka" aria-hidden="aria-hidden">{{i.il}}</label>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div class="iller yarimText">
                            <div class="select-box">
                                <div class="select-box__current" tabindex="3">
                                    <div class="select-box__value" v-for="(item, index) in ilceler" :key="index">
                                        <input class="select-box__input" type="radio" :id="'ilce'+index" @change="ilceKayit(item)"  :value="item" name="Ilce" />
                                        <p class="select-box__input-text">{{item}}</p>
                                    </div>
                                    <div class="select-box__value">
                                        <input class="select-box__input" type="radio" id="0" value="0" name="Ilce" checked="checked"/>
                                        <p class="select-box__input-text">İlçe Seçiniz</p>
                                    </div>
                                    <img class="select-box__icon" src="images/icn-down.png" alt="Arrow Icon" aria-hidden="true"/>
                                </div>
                                <ul class="select-box__list">
                                    <li><label class="select-box__option" for="ilce0" aria-hidden="aria-hidden">İlçe Seçiniz</label></li>
                                    <li v-for="(i, index) in ilceler" :key="index">
                                    <label class="select-box__option"  :for="'ilce'+index" aria-hidden="aria-hidden">{{i}}</label>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div class="row mobilButonRow">
                        <button class="girisBtn"><span>EKLE</span></button>
                    </div>
                </div>
        
        
        </div>
        <div class="faturalarListe">
            <div class="faturaListRow faturaYok">Kayıtlı Fatura Yok</div>
            <div class="faturaListRow"><div class="faturaIsim">Bilge Yılmaz</div><div class="faturaTipi">Bireysel Fatura</div></div>
            <div class="faturaListRow"><div class="faturaIsim">CRM Medya A.Ş.</div><div class="faturaTipi">Kurumsal Fatura</div></div>
        </div>
    </div> 
</div>
<Footer></Footer>
</div>   
</template>

<script>
import {TheMask} from 'vue-the-mask'
import Footer from './Footer'
import ililce from '../ililce'
export default {
    name:"FaturaBilgileri",
    data(){
        return{
            iller:[],
            ilceler:[],
            selected:0,
            ilSecildi:false,
            adSoyad:null,
            tcNo:null,
            telefon:null,
            postaKodu:null,
            adres:null
        }
    },
    created(){
      this.iller=ililce;
    }, 
    methods: {
    ilceleriGetir(index){
        this.iller.find(item => {
            if(item.plaka == index){
                this.ilceler = item.ilceleri
                console.log(this.ilceler, "İLÇELER")
            }
        })
    },
    faturaSec(e){
        localStorage.fatura=e
        this.$root.fatura.push({faturaTipi: e});
    },
    isimKayit(adSoyad){
        localStorage.faturaAdSoyad=adSoyad
        this.$root.fatura.push({adSoyad: adSoyad});
    },
    tcNoKayit(tcNo){
        localStorage.tcNo=tcNo
        this.$root.fatura.push({tcNo: tcNo});
    },
    turkDegil(e){
        localStorage.tcNo=e
        this.tcNo = ""
        this.$root.fatura.push({tcDegil: e});
    },
    telefonKayit(telefon){
        localStorage.telefon=telefon
        this.$root.fatura.push({telefon: telefon});
    },
    postaKoduKayit(postaKodu){
        localStorage.postaKodu=postaKodu
        this.$root.fatura.push({postaKodu: postaKodu});
    },
    adresKayit(adres){
        localStorage.adres=adres
        this.$root.fatura.push({adres: adres});
    },
    ilKayit(e){
        localStorage.il=e
        this.$root.fatura.push({il: e});
    },
    ilceKayit(e){
        localStorage.ilce=e
        this.$root.fatura.push({ilce: e});
    },
    },
    components:{
        TheMask,
        Footer
    }

}
</script>
