<template>
  <div id="app">
    <vue-snotify />
    <HomeHeader v-if="$route.meta.showNavigation" />
    <div class="main" id="main">
      <router-view />
    </div>
    <CitizenGate />
  </div>
</template>
<script src="js/main.js"></script>
<script>
import CitizenGate from './components/CitizenGate'
import HomeHeader from './components/HomeHeader'
import NeredeData from './components/NeredeData'
import jQuery from 'jquery'

window.jQuery = jQuery
export default {
  name: 'app',
  components: { HomeHeader, NeredeData, CitizenGate },
  data() {
    return {
      tabName: 'gelenler',
      personCount: 0,
      childCount: 0,
      toplamSayi: 0,
      dakikaUcret: 0,
      dakika: 60,
      
      ucret: parseFloat(window.localStorage.getItem('ucret') || 0), 
      
      yetiskinToplamTutar: 0,
      cocukToplamTutar: 0,
      silikonluCorapSayi: 0,
      corapSepetToplam: 0,
      corapFlag: false,
      silikonluCorapSayi: 0,
      corapUcret: 0,
      sepetAraToplam: 0,
      kdv: 0.18,
      bitisSaat: null,
      urunler: [],
      sepetToplam: 0,
      kdvHesapla: 0,
      fatura: [],
      menuSayac: 0,
      controlled: false,
      tarih: null,
      saat: null,
      sepetBos: false,
      tickets: [],
      dateFull: true,
      menuActive1: false,
      menuLink1: false,
      menuActive2: false,
      menuLink2: false,
      menuActive3: false,
      menuLink3: false,
      menuActive4: false,
      menuLink4: false,
      menuActive5: false,
      menuLink5: false,
      menuActive6: false,
      menuLink6: false,
      saatIndex: 0,
      bulkTicket: JSON.parse(window.localStorage.getItem('bulkTicket'))|| null,
      extremeTickets: [],
      kullanimSayisi:0,
      // ticketType:1, // 1: seanslı bilet , 5: kullanım hakkı , 10:extreme paket
      ticketType: parseInt(window.localStorage.getItem('ticketType')) || null
      
    }
  },
  created() {
  },
  mounted() {
    jQuery('img').each(function() {
      var $img = jQuery(this)
      var imgURL = $img.attr('src')
      if (imgURL.endsWith('.svg')) {
        jQuery.get(imgURL, function(data) {
          // Get the SVG tag, ignore the rest
          var $svg = jQuery(data).find('svg')
          // Replace image with new SVG
          $img.replaceWith($svg)
        })
      }
    })
  },
  methods: {
    tabDegistir(val) {
      if(this.childCount + this.personCount == 0) {
        this.$snotify.warning('En az 1 katılımcı eklemelisinz.', '', {
          timeout: 3000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
        })
      } else {
        this.tabName = val
      }
    },
    showAccountInfo() {
      FB.api('/me', { fields: 'name,email' }, function(response) {
        Log.info('API response', response)
      })
    },
  },
}
</script>
