var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"zamanBg"},[_c('div',{staticClass:"container"},[_c('SonucMesaj')],1),_c('div',{staticClass:"biletDetay biletDetaySonuc active"},[_vm._m(0),(this.$root.ticketType !== 10)?_c('div',{staticClass:"biletDetayBaslik"},[_c('div',{staticClass:"biletDetayPaket",domProps:{"textContent":_vm._s(_vm.secilipaket.ProductName)}}),_c('div',{staticClass:"biletDetayKonum",domProps:{"textContent":_vm._s(_vm.konum)}}),_c('div',{staticClass:"biletDetayTarih",domProps:{"textContent":_vm._s(_vm.tarih)}}),(this.$root.ticketType === 1)?_c('div',{staticClass:"biletDetaySaat",domProps:{"textContent":_vm._s(_vm.start + ' - ' + _vm.end)}}):_vm._e(),(this.$root.ticketType === 5)?_c('div',{staticClass:"biletDetaySaat",domProps:{"textContent":_vm._s(_vm.start)}}):_vm._e()]):_vm._e(),(this.$root.ticketType === 10)?_c('div',{staticClass:"biletDetayBaslik"},[_c('div',{staticClass:"biletDetayPaket",domProps:{"textContent":_vm._s(this.extremeName)}}),_c('div',{staticClass:"biletDetayKonum",domProps:{"textContent":_vm._s(_vm.konum)}}),_c('div',{staticClass:"biletDetayTarih",domProps:{"textContent":_vm._s(_vm.tarih)}}),_c('div',{staticClass:"biletDetaySaat",domProps:{"textContent":_vm._s(_vm.start + ' - ' + _vm.end)}})]):_vm._e(),(this.$root.ticketType !== 10)?_c('div',{staticClass:"biletTabloContent"},[_vm._m(1),_c('div',{staticClass:"biletDetayTabloRow"},[_c('div',[(this.$root.ticketType === 1)?_c('div',{staticClass:"biletDetayCol",domProps:{"textContent":_vm._s(
                _vm.secilipaket.Duration +
                ' dk ' +
                _vm.secilipaket.ProductName +
                '(' +
                _vm.toplamKisiSayisi +
                ')'
              )}}):_vm._e(),(this.$root.ticketType === 5)?_c('div',{staticClass:"biletDetayCol",domProps:{"textContent":_vm._s(
                _vm.secilipaket.useCount +
                ' Kul. ' +
                _vm.secilipaket.ProductName +
                '(' +
                _vm.toplamKisiSayisi +
                ')'
              )}}):_vm._e(),_c('div',{staticClass:"biletDetayCol",domProps:{"textContent":_vm._s(_vm.toplamKisiSayisi)}}),_c('div',{staticClass:"biletDetayCol",domProps:{"textContent":_vm._s(
                Number(_vm.secilipaket.Price) * Number(_vm.toplamKisiSayisi) + ' ₺'
              )}})])]),_vm._l((_vm.restrictionProducts),function(item,i){return _c('div',{key:i,staticClass:"biletDetayTabloRow"},[_c('div',{staticClass:"biletDetayCol",domProps:{"textContent":_vm._s(item.BodySize + ' ' + item.ProductName)}}),_c('div',{staticClass:"biletDetayCol",domProps:{"textContent":_vm._s(item.Quantity)}}),_c('div',{staticClass:"biletDetayCol",domProps:{"textContent":_vm._s(item.Price * item.Quantity + ' ₺')}})])})],2):_vm._e(),(this.$root.ticketType === 10)?_c('div',{staticClass:"biletTabloContent"},[_vm._m(2),_c('div',{staticClass:"biletDetayTabloRow"},[_c('div',[_c('div',{staticClass:"biletDetayCol"},[_vm._v(" "+_vm._s(this.extremeName)+" "),_c('br'),_vm._l((_vm.CampaignDetailList),function(item){return _c('ul',{key:item.CampaignDetailId},[_c('li',{staticClass:"carpan"},[_vm._v("   - "+_vm._s(item.ProductName)+" "+_vm._s(item.ProductNameExt)+" ")])])})],2),_c('div',{staticClass:"biletDetayCol",domProps:{"textContent":_vm._s(_vm.toplamKisiSayisi)}}),_c('div',{staticClass:"biletDetayCol",domProps:{"textContent":_vm._s(
                _vm.calculateDiscountExtreme(_vm.toplamKisiSayisi)+ ' ₺'
              )}})])]),_vm._l((_vm.restrictionProducts),function(item,i){return _c('div',{key:i,staticClass:"biletDetayTabloRow"},[_c('div',{staticClass:"biletDetayCol",domProps:{"textContent":_vm._s(item.BodySize + ' ' + item.ProductName)}}),_c('div',{staticClass:"biletDetayCol",domProps:{"textContent":_vm._s(item.Quantity)}}),_c('div',{staticClass:"biletDetayCol",domProps:{"textContent":_vm._s(_vm.calculateDiscount(item.Price * item.Quantity) + ' ₺')}})])})],2):_vm._e(),(this.$root.ticketType !== 10)?_c('div',{staticClass:"biletSepetUcret"},[_c('div',{staticClass:"biletToplamUcret"},[_vm._v(" Toplam Ücret: "),_c('span',{staticStyle:{"color":"#5b5b5b","font-size":"12px"}},[_vm._v("  (%10 İndirim)  ")]),_c('strong',{staticClass:"strikethrough",staticStyle:{"color":"#5b5b5b !important"},domProps:{"textContent":_vm._s(Number(_vm.toplamUcert).toFixed(2) + '  ')}}),_c('strong',[_c('span',{domProps:{"textContent":_vm._s(_vm.calculateDiscount(_vm.toplamUcert).toFixed(2))}})]),_vm._v(" ₺ ")])]):_vm._e(),(this.$root.ticketType === 10)?_c('div',{staticClass:"biletSepetUcret"},[_c('div',{staticClass:"biletToplamUcret"},[_vm._v(" Toplam Ücret: "),_c('span',{staticStyle:{"color":"#5b5b5b","font-size":"12px"}},[_vm._v("  ")]),_c('strong',{staticClass:"strikethrough",staticStyle:{"color":"#5b5b5b !important"},domProps:{"textContent":_vm._s(_vm.$root.sepetToplam + ' ₺')}}),_c('strong',[_c('span',{domProps:{"textContent":_vm._s(_vm.calculateCartTotalExtreme() + ' ₺')}})]),_vm._v(" ₺ ")])]):_vm._e()])]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mobilBiletDetayBaslik"},[_c('h2',[_vm._v("BİLET DETAY")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"biletDetayTabloBaslik"},[_c('div',{staticClass:"biletDetayCol"},[_vm._v("Paket")]),_c('div',{staticClass:"biletDetayCol"},[_vm._v("Kişi")]),_c('div',{staticClass:"biletDetayCol"},[_vm._v("Ücret")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"biletDetayTabloBaslik"},[_c('div',{staticClass:"biletDetayCol"},[_vm._v("Paket")]),_c('div',{staticClass:"biletDetayCol"},[_vm._v("Kişi")]),_c('div',{staticClass:"biletDetayCol"},[_vm._v("Ücret")])])}]

export { render, staticRenderFns }