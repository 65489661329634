<template>
  <div>
    <div class="zamanBg">
      <div class="container">

        <p class="text" style="color:#ff0; padding:20px 0 0 0; line-height:1.5">
          TL fiyatlardan sadece T.C. vatandaşlığı bulunan misafirler faydalanabilir!<br>
          This Turkish Lira’s prices are for only Turkish Citizens guest!
      </p>


        <GelenlerForm v-if="$root.tabName === 'gelenler'"></GelenlerForm>
        <CorapForm v-if="$root.tabName === 'coraplar'"></CorapForm>
        <div class="sepetSidebar">
          <Sepet></Sepet>
          <div class="butonlar butonlarRelocate butonlarRelocateForGelenler" v-if="$root.tabName === 'gelenler'">
            <router-link to="/paketler"><span>GERİ</span></router-link>
            <a v-if="$root.ticketType === 1 || $root.ticketType === 10"
              :class="{'aTagDisabled': $root.childCount + $root.personCount == 0 }" @click="$root.tabDegistir('coraplar')" ><span>İLERİ</span>
            </a>
            
            <a v-if="$root.ticketType === 5"
              :class="{'aTagDisabled': $root.childCount + $root.personCount == 0 }" @click="KimlereGit()" ><span>İLERİ</span>
            </a>
          </div>
          <div class="butonlar butonlarRelocate butonlarRelocateForCoraplar" v-if="$root.tabName === 'coraplar'">
            <a @click="$root.tabDegistir('gelenler')"><span>GERİ</span></a>
            <a
              :class="{'aTagDisabled': $root.corapSepetToplam == 0 && $root.corapFlag == false }" @click="neZamanGit()"><span>İLERİ</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>


<script>
import HomeHeader from './HomeHeader'
import Footer from './Footer'
import GelenlerForm from './GelenlerForm'
import CorapForm from './CorapForm'
import Sepet from './Sepet'
export default {
  name: 'Gelenler',
  data() {
    return {}
  },
  created() {
    ;(this.$root.menuActive1 = false),
      (this.$root.menuLink1 = true),
      (this.$root.menuActive2 = false),
      (this.$root.menuLink2 = true),
      (this.$root.menuActive3 = true),
      (this.$root.menuLink3 = false),
      (this.$root.menuActive4 = false),
      (this.$root.menuLink4 = false),
      (this.$root.menuActive5 = false)
    ;(this.$root.menuLink5 = false),
      (this.$root.menuActive6 = false),
      (this.$root.menuLink6 = false)
  },
  mounted() {
    //this.$root.tabName = 'gelenler'
  },
  components: {
    HomeHeader,
    GelenlerForm,
    CorapForm,
    Sepet,
    Footer,
  },
  methods: {
    KimlereGit(){
        this.$router.push('/kimler')
    },
    neZamanGit() {
      let corapSayısı = JSON.parse(window.localStorage.getItem('RestrictionProducts')).length
      let corapFlag = this.$root.corapFlag
      if (corapSayısı == 0 && corapFlag == false) {
        this.$snotify.warning('Lütfen çorap seçeneğini belirtin.', '', {
          timeout: 3000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
        })
      } else {
        this.$router.push('/ne-zaman')
      }
    },
  },
}
</script>
