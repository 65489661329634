<template>
  <div class="gelenlerForm">
    <div class="formRow">
      <div class="baslikSayi">
        <span>Yetişkin</span>
        <div class="personSayi"><span v-text="$root.personCount"></span></div>
      </div>
      <div class="rakamIslem">
        <div class="azalt" @click="personMinus">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Güzel"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="icn-minus" fill="#000000" fill-rule="nonzero">
                <path
                  d="M23.0820404,12.7985948 L0.899297424,12.7985948 C0.402883504,12.7985948 0,12.3957113 0,11.8992974 C0,11.4028835 0.402883504,11 0.899297424,11 L23.0820404,11 C23.5784543,11 23.9813378,11.4028835 23.9813378,11.8992974 C23.9813378,12.3957113 23.5784543,12.7985948 23.0820404,12.7985948 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </svg>
        </div>
        <div class="artir" @click="personPlus">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Güzel"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="icn-plus" fill="#000000" fill-rule="nonzero">
                <g id="plus">
                  <path
                    d="M23.1000027,13 L0.899997253,13 C0.403197026,13 0,12.5520019 0,12 C0,11.4479981 0.403197026,11 0.899997253,11 L23.1000027,11 C23.596803,11 24,11.4479981 24,12 C24,12.5520019 23.596803,13 23.1000027,13 Z"
                    id="Path"
                  ></path>
                  <path
                    d="M12,24 C11.4479981,24 11,23.596803 11,23.1000027 L11,0.899997253 C11,0.403197026 11.4479981,0 12,0 C12.5520019,0 13,0.403197026 13,0.899997253 L13,23.1000027 C13,23.596803 12.5520019,24 12,24 Z"
                    id="Path"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <!-- v-if="paket === 'KIDS CLUB'" -->
    <div class="formRow">
      <div class="baslikSayi">
        <span>Çocuk</span>
        <div class="personSayi"><span v-text="$root.childCount"></span></div>
      </div>
      <div class="rakamIslem">
        <div class="azalt" @click="childMinus">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Güzel"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="icn-minus" fill="#000000" fill-rule="nonzero">
                <path
                  d="M23.0820404,12.7985948 L0.899297424,12.7985948 C0.402883504,12.7985948 0,12.3957113 0,11.8992974 C0,11.4028835 0.402883504,11 0.899297424,11 L23.0820404,11 C23.5784543,11 23.9813378,11.4028835 23.9813378,11.8992974 C23.9813378,12.3957113 23.5784543,12.7985948 23.0820404,12.7985948 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </svg>
        </div>
        <div class="artir" @click="childPlus">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Güzel"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="icn-plus" fill="#000000" fill-rule="nonzero">
                <g id="plus">
                  <path
                    d="M23.1000027,13 L0.899997253,13 C0.403197026,13 0,12.5520019 0,12 C0,11.4479981 0.403197026,11 0.899997253,11 L23.1000027,11 C23.596803,11 24,11.4479981 24,12 C24,12.5520019 23.596803,13 23.1000027,13 Z"
                    id="Path"
                  ></path>
                  <path
                    d="M12,24 C11.4479981,24 11,23.596803 11,23.1000027 L11,0.899997253 C11,0.403197026 11.4479981,0 12,0 C12.5520019,0 13,0.403197026 13,0.899997253 L13,23.1000027 C13,23.596803 12.5520019,24 12,24 Z"
                    id="Path"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
    <div class="formRow">
      <div class="dakika">
        <ul>
          <template  v-for="(item, index) in price">
            <li
              :key="index"
              v-bind:class="{ active: item.indexPrice }"
              @click="indexActive(item.id)"
            > 
              <div class="ortala" v-if="item.category == 1">
                <div class="kacDakika">{{ item.sure }} Dakika</div>
                <div class="kacTl">{{ item.fiyat }} ₺</div>
              </div>
              <div class="ortala" v-if="item.category == 5">
                <div class="kacDakika">{{ item.kullanim }} Kullanım</div>
                <div class="kacTl">{{ item.fiyat }} ₺</div>
              </div>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'GelenlerForm',
  data() {
    return {
      key: null,
      price: [],
      yetiskinSayisi: 1,
      cocukSayisi: 0,
      paket: null,
    }
  },
  created() {
    /*if (localStorage.saat === undefined) {
      window.location.href = '/ne-zaman'
    }*/
    let saatler = []
    let paketItem = window.localStorage.getItem('paket');
    if(paketItem !== null && paketItem !== undefined && this.$root.ticketType !== 10){
        let paket = JSON.parse(paketItem)
        let say = 0
        paket.forEach(x => {

          var checked = false
          if (say == 0) {
            checked = true
          } else {
            checked = false
          }
          saatler.push({
            sure: x.Duration,
            kullanim: x.useCount,
            fiyat: x.Price,
            id: x.ProductID,
            indexPrice: checked,
            name: x.ProductName,
            category:x.CategoryId
          })
          say++
        });
        this.price = saatler
        var item = saatler[0]
        this.$root.dakika = item.sure
        this.$root.name = item.name
        this.$root.ucret = item.fiyat
        this.$root.itemid = item.id
        this.$root.kullanimSayisi = item.kullanim
        
        // this.$root.ticketType = item.category
        window.localStorage.setItem('ticketType', parseInt(item.category))
        this.$root.ticketType = parseInt(window.localStorage.getItem('ticketType'));
        // commit test

        var ticketlist = JSON.parse(window.localStorage.getItem('tickets'))
        var getticket = ticketlist.find((x) => x.ProductID == item.id)
        window.localStorage.setItem('secilipaket', JSON.stringify(getticket))
        if (!localStorage.getItem('RestrictionProducts')) {
          localStorage.removeItem('RestrictionProducts')
        }
    }
    else if(this.$root.ticketType === 10)
    {


    }
    this.$root.seciliDakika = this.$root.dakika
    this.$root.seciliSaat = new Date('January 01, 2020 ' + this.$root.saat + ':00')
    this.$root.seciliSaat.setMinutes(this.$root.seciliSaat.getMinutes() + this.$root.seciliDakika)
    this.$root.bitisSaat = moment(this.$root.seciliSaat).format('HH:mm')
    localStorage.bitisSaat = this.$root.bitisSaat
    this.$root.bitisSaat = localStorage.bitisSaat

      if (this.$root.personCount > 0 || this.$root.childCount > 0) 
        this.sepetIslem()
  },
  mounted() {},
  methods: {
    personPlus() {
      this.$root.personCount++
      this.$root.toplamSayi++
      this.sepetIslem()
    },
    personMinus() {
      if (this.$root.personCount == 0) {
      } else {
        this.$root.personCount--
        this.$root.toplamSayi--
        this.sepetIslem()
      }
    },
    childPlus() {
      this.$root.childCount++
      this.$root.toplamSayi++
      this.sepetIslem()
    },
    childMinus() {
      if (this.$root.childCount == 0) {
      } else {
        this.$root.childCount--
        this.$root.toplamSayi--
        this.sepetIslem()
      }
    },
    indexActive(i) {
      this.price.find((item) => {
        if (item.id == i) {
          var ticketlist = JSON.parse(localStorage.tickets)
          var getticket = ticketlist.find((x) => x.ProductID == i)
          localStorage.secilipaket = JSON.stringify(getticket)
          item.indexPrice = true
          this.$root.kullanimSayisi = item.kullanim
          //this.$root.ticketType = item.category
          window.localStorage.setItem('ticketType', parseInt(item.category))


          this.$root.dakika = item.sure
          this.$root.name = item.name
          this.$root.ucret = item.fiyat
          this.$root.itemid = i
          this.$root.seciliSaat = new Date('January 01, 2023 ' + this.$root.saat + ':00')
          this.$root.seciliDakika = this.$root.dakika
          this.$root.seciliSaat.setMinutes(this.$root.seciliSaat.getMinutes() + this.$root.seciliDakika)
          this.$root.bitisSaat = moment(this.$root.seciliSaat).format('HH:mm')
          localStorage.bitisSaat = this.$root.bitisSaat
          this.sepetIslem()
        } else {
          item.indexPrice = false
        }
      })
    },
    sepetIslem() {
      this.$root.yetiskinToplamTutar = parseFloat(parseFloat(this.$root.personCount) * parseFloat(this.$root.ucret)).toFixed(2)
      this.$root.cocukToplamTutar = parseFloat(parseFloat(this.$root.childCount) * parseFloat(this.$root.ucret)).toFixed(2)
      this.$root.sepetAraToplam = parseFloat(parseFloat(this.$root.yetiskinToplamTutar) +parseFloat(this.$root.cocukToplamTutar)).toFixed(2)
      this.$root.kdvHesapla = parseFloat(parseFloat(this.$root.sepetAraToplam) * parseFloat(this.$root.kdv)).toFixed(2)
      this.$root.sepetToplam = parseFloat(this.$root.sepetAraToplam).toFixed(2)
      localStorage.yetiskinSayisi = this.$root.personCount
      localStorage.cocukSayisi = this.$root.childCount
    },
  },
}
</script>
