<template>
  <div>
    <div class="bgSiyah">
      <div class="container">
        <div class="girisYapCol">
          <div class="girisYapButons mobilHizliKayitOl">
            <h1>HIZLI KAYIT OL</h1>
            <div class="loginButons">
              <a href class="googleGiris">
                <svg
                  width="16px"
                  height="16px"
                  viewBox="0 0 16 16"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g id="Güzel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g
                      id="8-Giriş-Yap"
                      transform="translate(-468.000000, -220.000000)"
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                    >
                      <g id="Group-3" transform="translate(468.000000, 218.000000)">
                        <g id="icn-google" transform="translate(0.000000, 2.000000)">
                          <path
                            d="M15.9776931,7.10929101 L8.52143915,7.10929101 L8.52143915,9.59470899 L13.0704762,9.59470899 C12.391873,11.7554709 10.3735873,13.322836 7.98878307,13.322836 C5.04732275,13.322836 2.6629418,10.9382857 2.6629418,7.99695238 C2.6629418,5.05549206 5.04740741,2.67111111 7.98878307,2.67111111 C9.47001058,2.67111111 10.8097354,3.27593651 11.7751111,4.25189418 L13.5792169,2.29092063 C12.1383704,0.879068783 10.1654603,0.00821164021 7.98878307,0.00821164021 C3.57671958,0.00821164021 0,3.58488889 0,7.99695238 C0,12.4090159 3.57671958,15.9858201 7.9888254,15.9858201 C11.853672,15.9858201 15.237418,13.2411005 15.9776931,9.59475132 L15.9776931,7.10933333 L15.9776931,7.10929101 L15.9776931,7.10929101 Z"
                            id="Path"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <span>Google</span>
              </a>
              <a href class="facebookGiris" @click="facebookLogin">
                <svg
                  width="9px"
                  height="16px"
                  viewBox="0 0 9 16"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g id="Güzel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g
                      id="8-Giriş-Yap"
                      transform="translate(-760.000000, -220.000000)"
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                    >
                      <g id="Group-2" transform="translate(760.000000, 218.000000)">
                        <g id="icn-facebook" transform="translate(0.000000, 2.000000)">
                          <path
                            d="M8.26210309,0.00329896907 L6.20602062,0 C3.89608247,0 2.40329897,1.53154639 2.40329897,3.90202062 L2.40329897,5.7011134 L0.336,5.7011134 C0.157360825,5.7011134 0.0127010309,5.84593814 0.0127010309,6.02457732 L0.0127010309,8.63125773 C0.0127010309,8.80989691 0.157525773,8.9545567 0.336,8.9545567 L2.40329897,8.9545567 L2.40329897,15.5320412 C2.40329897,15.7106804 2.54795876,15.8553402 2.72659794,15.8553402 L5.42383505,15.8553402 C5.60247423,15.8553402 5.74713402,15.7105155 5.74713402,15.5320412 L5.74713402,8.9545567 L8.16428866,8.9545567 C8.34292784,8.9545567 8.48758763,8.80989691 8.48758763,8.63125773 L8.48857732,6.02457732 C8.48857732,5.93880412 8.45443299,5.85665979 8.39389691,5.79595876 C8.33336082,5.73525773 8.2508866,5.7011134 8.1651134,5.7011134 L5.74713402,5.7011134 L5.74713402,4.176 C5.74713402,3.44296907 5.92181443,3.07084536 6.87670103,3.07084536 L8.2617732,3.07035052 C8.44024742,3.07035052 8.58490722,2.92552577 8.58490722,2.74705155 L8.58490722,0.326597938 C8.58490722,0.14828866 8.44041237,0.00362886598 8.26210309,0.00329896907 Z"
                            id="Path"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <span>Facebook</span>
              </a>
              
            </div>
          </div>

          <div class="kayitFormu">
            <h1>KAYIT OL</h1>
            <span v-if="hasError" style="display:block; color:red; margin-bottom:10px;">Bu alanlar boş bırakılamaz.</span>
            <div class="row ikili">
              <div class="form-group">
                <input
                  type="text"
                  v-model="name"
                  name="Adin"
                  required="required"
                  autocomplete="off"
                  @focus="deleteErrorName"
                  :class="{'inputError': hasError}"
                />
                <label for="input" class="control-label">Adın</label>
                <i class="bar" :class="{'error':errorName}"></i>
              </div>
            </div>
            <div class="row ikili">
              <div class="form-group">
                <input
                  type="text"
                  v-model="surname"
                  name="Soyadin"
                  required="required"
                  autocomplete="off"
                  :class="{'inputError': hasError}"
                />
                <label for="input" class="control-label">Soyadın</label>
                <i class="bar" :class="{'error':errorName}"></i>
              </div>
            </div>
            <div class="row ikili">
              <div class="form-group">
                <input
                  type="password"
                  v-model="password"
                  name="Sifre"
                  required="required"
                  autocomplete="off"
                  :class="{'inputError': hasError}"
                />
                <label for="input" class="control-label">Şifre</label>
                <i class="bar" :class="{'error':errorName}"></i>
              </div>
            </div>
            <div class="row ikili">
              <div class="form-group">
                <input
                  type="password"
                  v-model="passwordVerify"
                  name="SifreTekrar"
                  required="required"
                  autocomplete="off"
                  :class="{'inputError': hasError}"
                />
                <label for="input" class="control-label">Şifre Tekrar</label>
                <i class="bar" :class="{'error':errorName}"></i>
              </div>
            </div>
            <div class="row ikili">
              <div class="form-group">
                <input
                  type="text"
                  v-model="phone"
                  name="Telefon No"
                  required="required"
                  autocomplete="off"
                  v-mask="'+90 (5##) ### ## ##'"
                  :class="{'inputError': hasError}"
                />
                <label for="input" class="control-label">Telefon Numarası</label>
                <i class="bar" :class="{'error':errorName}"></i>
              </div>
            </div>
            <div class="row ikili">
              <div class="select-box">
                <div class="select-box__current" tabindex="1">
                  <div class="select-box__value">
                    <input
                      class="select-box__input"
                      type="radio"
                      id="cinsiyet"
                      value="Cinsiyet"
                      name="Cinsiyet"
                      checked="checked"
                    />
                    <p class="select-box__input-text">Cinsiyet</p>
                  </div>
                  <div class="select-box__value">
                    <input
                      class="select-box__input"
                      v-model="female"
                      type="radio"
                      id="kadin"
                      value="Kadın"
                      name="Cinsiyet"
                      @click="changeGender('female')"
                    />
                    <p class="select-box__input-text">Kadın</p>
                  </div>
                  <div class="select-box__value">
                    <input
                      class="select-box__input"
                      v-model="man"
                      type="radio"
                      id="erkek"
                      value="Erkek"
                      name="Cinsiyet"
                      @click="changeGender('male')"
                    />
                    <p class="select-box__input-text">Erkek</p>
                  </div>

                  <img
                    class="select-box__icon"
                    src="images/icn-down.png"
                    alt="Arrow Icon"
                    aria-hidden="true"
                  />
                </div>
                <ul class="select-box__list">
                  <li>
                    <label
                      class="select-box__option"
                      for="cinsiyet"
                      aria-hidden="aria-hidden"
                    >Cinsiyet</label>
                  </li>
                  <li>
                    <label class="select-box__option" for="kadin" aria-hidden="aria-hidden">Kadın</label>
                  </li>
                  <li>
                    <label class="select-box__option" for="erkek" aria-hidden="aria-hidden">Erkek</label>
                  </li>
                </ul>
              </div>
            </div>

            <div class="row tek">
              <div class="form-group">
                <input
                  type="text"
                  name="Email"
                  v-model="email"
                  required="required"
                  autocomplete="off"
                  :class="{'inputError': hasError}"
                />
                <label for="input" class="control-label">Email</label>
                <i class="bar" :class="{'error':errorName}"></i>
              </div>
            </div>
            <div class="row tek">
              <div class="form-radio onayCheck">
                <div class="radio">
                  <label>
                    <input type="checkbox" v-model="verify" name="onay" @click="changeNotification('emailVerify')" />
                    <i class="helper"></i>Haberler ve indirimlerden haberdar olmak istiyorum.
                  </label>
                </div>
              </div>
            </div>
            <div class="row tek">
              <div class="form-radio">
                <div class="radio">
                  <label>
                    <input type="checkbox" v-model="verify2" name="onay" @click="changeNotification('phoneVerify')" />
                    <i class="helper"></i>Telefon numarasından ilgili haber, etkinlikler, indirimler hakkında bilgilendirilmek istiyorum.
                  </label>
                </div>
              </div>
            </div>
            <div class="row mobilButonRow">
              <button class="girisBtn" v-on:click="sendpost">
                <span>KAYIT OL</span>
              </button>
            </div>
          </div>
        </div>
        <div class="biletlerimSag kayitOlCol desktopHizliKayitOl">
          <h2>HIZLI KAYIT OL</h2>
          <div class="loginButons">
            <a href class="googleGiris">
              <svg
                width="16px"
                height="16px"
                viewBox="0 0 16 16"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g id="Güzel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g
                    id="8-Giriş-Yap"
                    transform="translate(-468.000000, -220.000000)"
                    fill="#FFFFFF"
                    fill-rule="nonzero"
                  >
                    <g id="Group-3" transform="translate(468.000000, 218.000000)">
                      <g id="icn-google" transform="translate(0.000000, 2.000000)">
                        <path
                          d="M15.9776931,7.10929101 L8.52143915,7.10929101 L8.52143915,9.59470899 L13.0704762,9.59470899 C12.391873,11.7554709 10.3735873,13.322836 7.98878307,13.322836 C5.04732275,13.322836 2.6629418,10.9382857 2.6629418,7.99695238 C2.6629418,5.05549206 5.04740741,2.67111111 7.98878307,2.67111111 C9.47001058,2.67111111 10.8097354,3.27593651 11.7751111,4.25189418 L13.5792169,2.29092063 C12.1383704,0.879068783 10.1654603,0.00821164021 7.98878307,0.00821164021 C3.57671958,0.00821164021 0,3.58488889 0,7.99695238 C0,12.4090159 3.57671958,15.9858201 7.9888254,15.9858201 C11.853672,15.9858201 15.237418,13.2411005 15.9776931,9.59475132 L15.9776931,7.10933333 L15.9776931,7.10929101 L15.9776931,7.10929101 Z"
                          id="Path"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <span>Google ile kayıt ol</span>
            </a>
            <facebook-login class="facebookGiris"
                appId="213632843124887"
              @login="onLogin"
              @logout="onLogout"
              @get-initial-status="getUserData"
              @sdk-loaded="sdkLoaded">
            <!--<a href class="facebookGiris">
          
              <svg
                width="9px"
                height="16px"
                viewBox="0 0 9 16"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g id="Güzel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g
                    id="8-Giriş-Yap"
                    transform="translate(-760.000000, -220.000000)"
                    fill="#FFFFFF"
                    fill-rule="nonzero"
                  >
                    <g id="Group-2" transform="translate(760.000000, 218.000000)">
                      <g id="icn-facebook" transform="translate(0.000000, 2.000000)">
                        <path
                          d="M8.26210309,0.00329896907 L6.20602062,0 C3.89608247,0 2.40329897,1.53154639 2.40329897,3.90202062 L2.40329897,5.7011134 L0.336,5.7011134 C0.157360825,5.7011134 0.0127010309,5.84593814 0.0127010309,6.02457732 L0.0127010309,8.63125773 C0.0127010309,8.80989691 0.157525773,8.9545567 0.336,8.9545567 L2.40329897,8.9545567 L2.40329897,15.5320412 C2.40329897,15.7106804 2.54795876,15.8553402 2.72659794,15.8553402 L5.42383505,15.8553402 C5.60247423,15.8553402 5.74713402,15.7105155 5.74713402,15.5320412 L5.74713402,8.9545567 L8.16428866,8.9545567 C8.34292784,8.9545567 8.48758763,8.80989691 8.48758763,8.63125773 L8.48857732,6.02457732 C8.48857732,5.93880412 8.45443299,5.85665979 8.39389691,5.79595876 C8.33336082,5.73525773 8.2508866,5.7011134 8.1651134,5.7011134 L5.74713402,5.7011134 L5.74713402,4.176 C5.74713402,3.44296907 5.92181443,3.07084536 6.87670103,3.07084536 L8.2617732,3.07035052 C8.44024742,3.07035052 8.58490722,2.92552577 8.58490722,2.74705155 L8.58490722,0.326597938 C8.58490722,0.14828866 8.44041237,0.00362886598 8.26210309,0.00329896907 Z"
                          id="Path"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <span>Facebook ile kayıt ol</span>
                 
            
            </a>-->
            </facebook-login>
            
            
            
            
          
            <!--<div v-if="isConnected" class="information">
            <h1>My Facebook Information</h1>
            <div class="well">
              <div class="list-item">
                <img :src="picture">
              </div>
              <div class="list-item">
                <i>{{name}}</i>
              </div>
              <div class="list-item">
                <i>{{email}}</i>
              </div>
              <div class="list-item">
                <i>{{personalID}}</i>
              </div>
            </div>
          </div>-->
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import Footer from "./Footer";
import facebookLogin from 'facebook-login-vuejs'
export default {
  name: "KayitOl",
  data() {
    return {
      facebookLoginAction: false,
      name: '',
      surname: '',
      password: '',
      passwordVerify: '',
      phone: '',
      gender: null,
      email:'',
      genders: ['cinsiyet','male','female'],
      emailNotification: false,
      phoneNotification: false,
      hasError: false,
      errorName: false,
      errorSurname: false,
      errorPassword: false,
      errorPasswordVerify: false,
      errorPhone: false,
      errorEmail: false,
      fbSignInParams: {
        scope: 'email,user_likes',
        return_scopes: true
      },
      isConnected: false,
      name: '',
      email: '',
      personalID: '',
      picture: '',
      FB: undefined
    }
  },
  components: {
    Footer,
    TheMask,
    facebookLogin
  },
  methods: {
    sendpost() {
      if (this.password !== this.passwordVerify) {
        alert("Şifreleriniz aynı değil");
        return;
      }
      //var data = "{ Name:'" + this.name + "',Surname:'" + this.surname + "',Password:'" + this.password + "',PasswordVerify:'" + this.passwordverify +"',Phone:'" + this.phone +"'}";

      if(this.name == '' || this.surname == '' || this.phone == '' || this.passwordVerify == '' || this.email == '') {
        this.hasError = true;
        return;
      } else {
          this.hasError = false;
          if(this.name == '' && this.surname == '' && this.phone == '' && this.passwordVerify == '' && this.email == '') {
          this.hasError = true;
          return;
        } else {
          this.hasError = false;

          var data = {
            name: this.name,
            surname: this.surname,
            password: this.password,
            passwordVerify: this.passwordVerify,
            phone: this.phone,
            gender: this.gender,
            emailNotification: this.emailNotification,
            phoneNotification: this.phoneNotification
          }
          console.log("kullanıcı", data)
          
          fetch("https://api.githuab.com/gists", {
            method: "post",
            body: JSON.stringify(data),
            header: {
              "Content-type": "application/json; charset=UTF-8"
            }
          })
          .then(function(response) {
            return response.json();
          })
          .then(function(data) {console.log(data)});
          
        }
      }
        /*if(this.name.length < 2){this.errorName = true}
        if(this.surname.length < 2){this.errorSurname = true}
        if(this.password = ''){this.errorPassword = true}
        if(this.passwordVerify = ''){this.errorPasswordVerify = true}
        if(this.phone = ''){this.errorPhone = true}
        if(this.email = ''){this.errorEmail = true}

        if(this.errorName == false && this.errorSurname == false && this.errorPassword == false && this.errorPasswordVerify == false 
        && this.errorPhone == false && this.errorEmail == false) {
            var data = {
            name: this.name,
            surname: this.surname,
            password: this.password,
            passwordVerify: this.passwordVerify,
            phone: this.phone,
            gender: this.gender,
            emailNotification: this.emailNotification,
            phoneNotification: this.phoneNotification
          }
          console.log("kullanıcı", data)
        
          fetch("https://api.githuab.com/gists", {
            method: "post",
            body: JSON.stringify(data)
          })
            .then(function(response) {
              return response.json();
            })
            .then(function(data) {});
        }*/

        
    },
    facebookLogin() {
      FB.login(function(response) {
        if (response.authResponse) {
          console.log('Welcome!  Fetching your information.... ');
          FB.api('/me', function(response) {
            console.log('Good to see you, ' + response.name + '.');
        });
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      });
    },
    changeGender(gen){
      if(gen === 'male') {
        this.gender = 'Male'
      } else if (gen === 'female'){
        this.gender = 'Female'
      } else {
        this.gender = null;
      }
    },
    changeNotification(verify) {
      if(verify == 'emailVerify') {
        if(this.emailNotification == false){
          this.emailNotification = true;
        } else {
          this.emailNotification = false;
        }
      }
      if(verify == 'phoneVerify') {
        if(this.phoneNotification == false){
          this.phoneNotification = true;
        } else {
          this.phoneNotification = false;
        }
      }
    },
    deleteErrorName(){this.errorName = false},
    deleteErrorSurname(){this.errorSurname = false},
    deleteErrorPhone(){this.errorPhone = false},
    deleteErrorPassword(){this.deleteErrorPassword = false},
    deleteErrorPasswordVerify(){this.deleteErrorPasswordVerify = false},
    deleteErrorEmail(){this.errorEmail = false},
    getUserData() {
    this.FB.api('/me', 'GET', { fields: 'id,name,email,picture' },
        user => {
          this.personalID = user.id;
          this.email = user.email;
          this.name = user.name;
        }
      ).then()
    },
    sdkLoaded(payload) {
      this.isConnected = payload.isConnected
      this.FB = payload.FB
      if (this.isConnected) this.getUserData()
    },
    onLogin() {
      this.isConnected = true
      this.getUserData()
    },
    onLogout() {
      this.isConnected = false;
    }
      

  },
  created(){

    
  }
};
</script>

<style >
  .facebookButton {
    background-image:linear-gradient(289deg,#184a8e 100%,#18728e 29%);
  }
</style>