<template>
    <div>
        <div class="bgSiyah">
            <div class="container">
                <div class="girisYapCol">             
                    <div class="kayitFormu mobilBaslik">
                        <h1>PROFİL BİLGİLERİM</h1>
                        <div class="row ikili">
                            <div class="form-group">
                                <input type="text" name="Adin" required="required" autocomplete="off" value="Bilge"/>
                                <label for="input" class="control-label">Adın</label><i class="bar"></i>
                            </div>
                        </div>
                        <div class="row ikili">
                            <div class="form-group">
                                <input type="text" name="Soyadin" required="required" autocomplete="off" value="Yılmaz"/>
                                <label for="input" class="control-label">Soyadın</label><i class="bar"></i>
                            </div>
                        </div>
                        <div class="row ikili">
                            <div class="form-group">
                                <input type="text" name="Telefon No" required="required" autocomplete="off" v-mask="'+90 (5##) ### ## ##'" value="+90 (531) 870 18 27"/>
                                <label for="input" class="control-label">Telefon Numarası</label><i class="bar"></i>
                            </div>
                        </div>
                        <div class="row ikili">
                            <div class="select-box">
                                <div class="select-box__current" tabindex="1">
                                    <div class="select-box__value">
                                        <input class="select-box__input" type="radio" id="cinsiyet" value="Cinsiyet" name="Cinsiyet" />
                                        <p class="select-box__input-text">Cinsiyet</p>
                                    </div>
                                    <div class="select-box__value">
                                        
                                        <input class="select-box__input" type="radio" id="kadin" value="Kadın" name="Cinsiyet" checked="checked"/>
                                        <p class="select-box__input-text">Kadın</p>
                                    </div>
                                    <div class="select-box__value">
                                        <input class="select-box__input" type="radio" id="erkek" value="Erkek" name="Cinsiyet"/>
                                        <p class="select-box__input-text">Erkek</p>
                                    </div>
                                    
                                    
                                    <img class="select-box__icon" src="images/icn-down.png" alt="Arrow Icon" aria-hidden="true"/>
                                </div>
                                <ul class="select-box__list">
                                    <li><label class="select-box__option" for="cinsiyet" aria-hidden="aria-hidden">Cinsiyet</label></li>
                                    <li><label class="select-box__option" for="kadin" aria-hidden="aria-hidden">Kadın</label></li>
                                    <li><label class="select-box__option" for="erkek" aria-hidden="aria-hidden">Erkek</label></li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div class="row tek">
                            <div class="form-group">
                                <input type="text" name="Email" required="required" autocomplete="off" value="bilgeyilmaz@crmmedya.com"/>
                                <label for="input" class="control-label">Email</label><i class="bar"></i>
                            </div>
                        </div>
                        <div class="row ikili">
                            <div class="form-group">
                                <input type="password" name="Sifre" required="required" autocomplete="off" value="250292"/>
                                <label for="input" class="control-label">Şifre</label><i class="bar"></i>
                            </div>
                        </div>
                        <div class="row ikili">
                            <div class="form-group">
                                <input type="password" name="SifreTekrar" required="required" autocomplete="off"  value="250292"/>
                                <label for="input" class="control-label">Şifre Tekrar</label><i class="bar"></i>
                            </div>
                        </div>
                        
                        
                        
                        <div class="row tek">
                            <div class="form-radio onayCheck">
                                <div class="radio">
                                    <label><input type="checkbox" name="onay" checked/><i class="helper"></i>Haberler ve indirimlerden haberdar olmak istiyorum.</label>
                                </div>
                            </div>
                        </div>
                        <div class="row tek">
                            <div class="form-radio">
                                <div class="radio">
                                    <label><input type="checkbox" name="onay" /><i class="helper"></i>Telefon numarasından ilgili haber, etkinlikler, indirimler hakkında bilgilendirilmek istiyorum.</label>
                                </div>
                            </div>
                        </div>
                        <div class="row mobilButonRow">
                            <button class="girisBtn"><span>GÜNCELLE</span></button>
                        </div>
                    </div>
                </div>
                <div class="biletlerimSag kayitOlCol">
                    <h2>UÇUŞA GEÇMEK İÇİN HEMEN BİLET AL</h2>
                    <a href="/nerede" class="odemeBtn"><span>BİLET AL</span></a>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import {TheMask} from 'vue-the-mask'
import Footer from './Footer'
export default {
    name:'KayitOl',
    components:{
        Footer,
        TheMask
    }
}

</script>