<template>
  <div class="odemeForm">
    <h1>GÜVENLİ ÖDEME YAP</h1>
    <div class="faturaRow">
      <div class="row">
        <div class="form-group">
          <input
            type="text"
            name="AdSoyad"
            v-model="adSoyad"
            required="required"
            autocomplete="off"
            @change="adKayit(adSoyad)"
            @focus="errorSilAdSoyad()"
          />
          <label for="input" class="control-label">Ad Soyad</label>
          <i class="bar" :class="{ error: this.errorAdSoyad }"></i>
        </div>
      </div>
      <div class="row">
        <div class="form-group">
          <input
            type="text"
            name="kartNo"
            v-model="kartNo"
            v-mask="'#### #### #### ####'"
            required="required"
            autocomplete="off"
            @change="kartKayit(kartNo)"
            @focus="errorSilKartNo()"
          />
          <label for="input" class="control-label">Kart Numarası</label>
          <i class="bar" :class="{ error: this.errorKartNo }"></i>
        </div>
      </div>
      <div class="row">
        <div class="aylar">
          <div class="select-box">
            <div class="select-box__current" tabindex="1">
              <div
                class="select-box__value"
                v-for="(item, index) in aylar"
                :item="item"
                :key="index"
              >
                <input
                  class="select-box__input"
                  type="radio"
                  :id="item.ayNo"
                  name="Aylar"
                  v-model="ayNo"
                  @change="ayKayit(item.ayNo)"
                  @focus="errorSilAy()"
                />
                <p class="select-box__input-text">{{ item.ayNo }}</p>
              </div>
              <div class="select-box__value">
                <input
                  class="select-box__input"
                  type="radio"
                  id="ay0"
                  value="0"
                  name="Aylar"
                  checked="checked"
                />
                <p
                  class="select-box__input-text"
                  :class="{ error: this.errorAy }"
                >
                  Ay
                </p>
              </div>
              <img
                class="select-box__icon"
                src="images/icn-down.png"
                alt="Arrow Icon"
                aria-hidden="true"
              />
            </div>
            <ul class="select-box__list">
              <li>
                <label
                  class="select-box__option"
                  for="ay0"
                  aria-hidden="aria-hidden"
                  >Ay</label
                >
              </li>
              <li v-for="(i, index) in aylar" :key="index">
                <label
                  class="select-box__option"
                  :for="i.ayNo"
                  aria-hidden="aria-hidden"
                  >{{ i.ayNo }}</label
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="yillar">
          <div class="select-box">
            <div class="select-box__current" tabindex="2">
              <div
                class="select-box__value"
                v-for="item in yillar"
                :item="item"
                :key="item.id"
              >
                <input
                  class="select-box__input"
                  type="radio"
                  :id="'yil' + item.id"
                  name="Yillar"
                  v-model="yilNo"
                  @change="yilKayit(item.yil)"
                  @focus="errorSilYil()"
                />
                <p class="select-box__input-text">{{ item.yil }}</p>
              </div>
              <div class="select-box__value">
                <input
                  class="select-box__input"
                  type="radio"
                  id="yillar0"
                  value="0"
                  name="Yillar"
                  checked="checked"
                />
                <p
                  class="select-box__input-text"
                  :class="{ error: this.errorYil }"
                >
                  Yıl
                </p>
              </div>
              <img
                class="select-box__icon"
                src="images/icn-down.png"
                alt="Arrow Icon"
                aria-hidden="true"
              />
            </div>
            <ul class="select-box__list">
              <li>
                <label
                  class="select-box__option"
                  for="yillar0"
                  aria-hidden="aria-hidden"
                  >Yıl</label
                >
              </li>
              <li v-for="(i, index) in yillar" :key="index">
                <label
                  class="select-box__option"
                  :for="'yil' + i.id"
                  aria-hidden="aria-hidden"
                  >{{ i.yil }}</label
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="form-group cvc">
          <input
            type="text"
            name="cvv"
            v-model="cvv"
            v-mask="'###'"
            required="required"
            autocomplete="off"
            @change="cvvKayit(cvv)"
            @focus="errorSilCvv()"
          />
          <label for="input" class="control-label">CVV</label>
          <i class="bar" :class="{ error: this.errorCvv }"></i>
        </div>
      </div>
      <div class="row" style="display: none">
        <div class="select-box taksitSelect">
          <div class="select-box__current" tabindex="3">
            <div class="select-box__value">
              <input
                class="select-box__input"
                type="radio"
                id="taksit1"
                value="3 Taksit"
                name="Taksit"
              />
              <p class="select-box__input-text">3 Taksit 3 x 15.00 ₺</p>
            </div>
            <div class="select-box__value">
              <input
                class="select-box__input"
                type="radio"
                id="taksit2"
                value="6 Taksit"
                name="Taksit"
              />
              <p class="select-box__input-text">6 Taksit 3 x 15.00 ₺</p>
            </div>
            <div class="select-box__value">
              <input
                class="select-box__input"
                type="radio"
                id="taksit3"
                value="9 Taksit"
                name="Taksit"
              />
              <p class="select-box__input-text">9 Taksit 3 x 15.00 ₺</p>
            </div>
            <div class="select-box__value">
              <input
                class="select-box__input"
                type="radio"
                id="taksit0"
                value="Taksit İstemiyorum"
                checked="checked"
                name="Taksit"
              />
              <p class="select-box__input-text">Taksit İstemiyorum</p>
            </div>
            <img
              class="select-box__icon"
              src="images/icn-down.png"
              alt="Arrow Icon"
              aria-hidden="true"
            />
          </div>
          <ul class="select-box__list">
            <li>
              <label
                class="select-box__option"
                for="taksit0"
                aria-hidden="aria-hidden"
                >Taksit İstemiyorum</label
              >
            </li>
            <li>
              <label
                class="select-box__option"
                for="taksit1"
                aria-hidden="aria-hidden"
                >3 Taksit 3 x 15.00 ₺</label
              >
            </li>
            <li>
              <label
                class="select-box__option"
                for="taksit2"
                aria-hidden="aria-hidden"
                >6 Taksit 3 x 15.00 ₺</label
              >
            </li>
            <li>
              <label
                class="select-box__option"
                for="taksit3"
                aria-hidden="aria-hidden"
                >9 Taksit 3 x 15.00 ₺</label
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="form-radio sozlesmeCheck">
          <div class="radio">
            <label>
              <input
                type="checkbox"
                name="sozlesme"
                v-model="sozlesme"
                required="required"
                @focus="errorSilSozlesme()"
              />
              <i class="helper" :class="{ error: this.errorSozlesme }"></i>
              Devam edebilmek için,
              <strong
                style="text-decoration: underline; z-index: 10"
                @click="sozlesmeModal = true"
              >
                Park Kuralları, Giriş Kuralları, Gizlilik ve Mesafeli Satış
                Sözleşmesini</strong
              >
              onaylayınız.
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="odemeBtn" @click="siparistamamla">
          <span>ÖDEME YAP</span>
        </div>
      </div>
    </div>
    <div
      id="sozlesmeModal"
      class="modal"
      style="display: block"
      v-if="sozlesmeModal"
    >
      <div class="modal-content">
        <span class="closeModal" @click="sozlesmeModal = false">×</span>
        <div class="content-container" style="box-sizing:border-box;">
          <h2>AVRUPA EĞLENCE A.Ş. ONLINE ÖDEME PLATFORMU</h2>
          <p>
            AVRUPA EĞLENCE A.Ş. Online Ödeme Platformu, AVRUPA EĞLENCE A.Ş.
            projesinden satın alma yapmış/yapacak gerçek veya tüzel kişilerin
            ödeme süreçlerini kolaylaştırmak amacıyla hazırlanmış bir online
            ödeme altyapısıdır.
          </p>
          <p>
            AVRUPA EĞLENCE A.Ş. Online Ödeme Platformu, 256bit güvenlik
            sertifikasına sahiptir. Platform üzerinden yapılacak ödemelerde
            kullanılacak kredi kartı bilgileri web sitesinden bağımsız olarak
            256bit SSL şifreleme protokolü kullanılarak anlaşmalı bankaların
            sistemine aktarılır ve banka tarafından sorgulanır. Sorgulama sonucu
            anında kart sahibine iletilir ve ödeme işlemi tamamlanır. Kullanılan
            kredi kartı bilgileri hiçbir şekilde web sitesi yöneticileri veya 3.
            taraflarca görüntülenemez ve kaydedilemez. Adres çubuğunda
            görüntülenen kilit simgesi, sayfanın SSL güvenlik protokolünce
            şifrelendiğini gösterir.
          </p>
          <h2>İPTAL/İADE SÜREÇLERİ:</h2>
          <p>
            Müşterilerin olası hatalı ödeme işlemleri sonrasında başvurulacak
            iptal/iade prosedürleri, iptal/iade dilekçesi ile başlatılır.
            Dilekçede müşteri adı, işlem tarihi, kartın ait olduğu banka, kredi
            kartı numarası, ödeme tutarı ve sistemde görünen işlem numarası
            bilgilerine yer verilmelidir. Doldurulan dilekçe AVRUPA EĞLENCE A.Ş.
            yetkilisi tarafından onaylanarak işleme koyulur.
          </p>
          <p>
            İşleme alınan iptal/iade talebine konu tutar, ödemenin AVRUPA
            EĞLENCE A.Ş. hesabına geçmesini takip eden 14 işgünü içinde ödemenin
            yapıldığı kredi kartına iade edilir. Açıklanan süreç, debit kartla
            yapılan işlemler için 30 işgününe kadar uzayabilir.
          </p>
          <p>
            Seans başlangıcına en az 24 saat kalan biletler için geçerlidir. Seansın başlamasına 
            24 saatten az kalan biletler iade edilemez.
          </p>
          <h2>YASAL UYARI:</h2>
          <p>
            Kredi kartının çalınması, kaybolması veya izinsiz kullanıldığının
            fark edilmesi durumunda kart sahibi, zaman kaybedilmeksizin
            bankasıyla irtibata geçip ilgili önlemleri almakla mükelleftir.
          </p>
          <p>
            Açıklanan online ödeme, iptal/iade süreçlerinin uygulanmasından
            doğabilecek uyuşmazlıkların çözümünde İstanbul mahkemeleri ve icra
            daireleri yetkilidir.
          </p>
        <button v-if="modalTextControl()" type="button" class="odemeBtn btnParkKurallari" @click="parkKurallari = true;sozlesmeModal = false;siparistamamla()">OKUDUM, ANLADIM</button>
        </div>
        <div v-if="modalTextControl()" class="uyariOnayText" style="text-align:center; padding:20px 10px; color:#c00">
          Lütfen Onay Metnini Dikkatlice Okuyunuz ve <b>OKUDUM, ANLADIM</b> butonuna basınız.
        </div>
      </div>
    </div>
    <div class="odemePopup" :class="{ active: popActive === true }">
      <div class="popBg" @click="popKapat"></div>
      <div class="popContent">
        <div class="popClose" @click="popKapat">X</div>
        <iframe
          class="payFrame"
          :src="payurl"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </div>
   
  </div>
</template>
<script>
import axios from "axios";
import data from "../data";
export default {
  name: "KartOdeme",
  data() {
    return {
      aylar: [],
      datatosend: null,
      datatosendExtreme: null,
      card: null,
      popActive: false,
      sozlesmeModal: false,
      payurl: "",
      cvv: "",
      kartNo: "",
      ayNo: 0,
      yilNo: 0,
      adSoyad: "",
      sozlesme: false,
      errorAdSoyad: false,
      errorKartNo: false,
      errorAy: false,
      errorYil: false,
      errorCvv: false,
      errorSozlesme: false,

      parkKurallari: false,
    };
  },

  created() {


    if(this.$root.ticketType === 5) {
      const today = new Date();
      let todayDate = new Date().toLocaleDateString('tr-TR');
      todayDate = todayDate.replace('.','/').replace('.','/')

      const todayTime = this.addLeadingZeros(today.getHours()) + ':' + this.addLeadingZeros(today.getMinutes());

      localStorage.setItem('tarih', todayDate)
      localStorage.setItem('saat', todayTime)

    }   


    // console.log('bd', this.convertBirthdate(localStorage.dogumTarihi))
    // console.log('this.$root.ticketType 000', this.$root.ticketType)


    this.aylar = data.aylar;
    var ipaddress = "";
    console.log('ipaddress start');
    
    axios.get("https://jsonip.com?callback=?").then(function (response) {
      ipaddress = response.ip;
      console.log('ipaddress', ipaddress)
    });

    var customer = {
      CustomerId: 0,
      CustomerGsm: localStorage.telefon,
      Name: localStorage.faturaAd,
      Surname: localStorage.faturaSoyad,
      Tcno: localStorage.tcNo,
      NotTc: false,
      Email: localStorage.email,
      Gender: parseInt(localStorage.cinsiyet),
      BirthDate: this.convertBirthdate(localStorage.dogumTarihi)

    };
    this.fillCorporate(customer)
    
    var order = {
      OrderID: 0,
      TerritoryId: localStorage.konumId,
      ReqInvoice: window.localStorage.getItem('fatura') == 'Fatura İstemiyorum' ? 0 : 1,
      OrderStatus: 1,
      CustomerId: 0,
      Currency: 1,
      PaymentMethod: 1,
      PaymentType: 1,
      OrderDate: new Date(),
      Organization: 1,
      OrganizationKids: parseInt(localStorage.cocukSayisi),
      OrganizationAdult: parseInt(localStorage.yetiskinSayisi),
      IPAddress: ipaddress,
    };
    var orderDetail = [];
    var cocuksayisi    = parseInt(localStorage.cocukSayisi);
    var yetiskinsayisi = parseInt(localStorage.yetiskinSayisi);
    if (localStorage.secilipaket !== undefined && this.$root.ticketType !== 10) {
      var ticket = JSON.parse(localStorage.secilipaket);
      orderDetail.push({
        OrderDetailID: 0,
        ProductId: ticket.ProductID,
        UnitPrice: ticket.Price,
        Currency: ticket.Currency,
        Discount: 0,
        Quantity: yetiskinsayisi + cocuksayisi,
        AdultPerson: yetiskinsayisi,
        KidPerson: cocuksayisi,
        TotalPrice: (yetiskinsayisi + cocuksayisi) * ticket.Price,
        TotalAmount: (yetiskinsayisi + cocuksayisi) * ticket.Price,
      });
    }
    if (localStorage.RestrictionProducts !== undefined) {
      var restrictionproducts = JSON.parse(localStorage.RestrictionProducts);
      for (var i = 0; i < restrictionproducts.length; i++) {
        orderDetail.push({
          OrderDetailID: 0,
          ProductId: restrictionproducts[i].ProductID,
          UnitPrice: restrictionproducts[i].UnitPrice,
          Currency: restrictionproducts[i].Currency,
          Discount: 0,
          Quantity: restrictionproducts[i].Quantity,
          AdultPerson: restrictionproducts[i].Quantity,
          KidPerson: 0,
          TotalPrice:
            restrictionproducts[i].Quantity * restrictionproducts[i].UnitPrice,
          TotalAmount:
            restrictionproducts[i].Quantity * restrictionproducts[i].UnitPrice,
        });
      }
    }
    var orderTickets = [];
    
    if(this.$root.ticketType !== 10){
      if (localStorage.kisiler !== undefined) {
        var ticketowners = JSON.parse(localStorage.kisiler);
        var tarih = localStorage.tarih;
        var saat = localStorage.saat;
        console.log('tarih 111', localStorage.tarih)
        for (let i = 0; i < ticketowners.length; i++) {
          var cr = ticketowners[i];
          var startdate = new Date(
            tarih.split("/")[2] +
              "-" +
              tarih.split("/")[1] +
              "-" +
              tarih.split("/")[0] +
              "T" +
              saat.split(":")[0] +
              ":" +
              saat.split(":")[1]
          );
          console.log(startdate, "BEFORE - ")
          var enddate = new Date()
          
          if(this.$root.ticketType === 5) {
            enddate.setTime(startdate.getTime() + 60 * 60 * 1000)
          } else {
            enddate.setTime(startdate.getTime() + ticket.Duration * 60 * 1000)
          }
          //enddate.setTime(startdate.getTime() + ticket.Duration * 60 * 1000);
          console.log('enddate', enddate)


          orderTickets.push({
            TicketNumber: "",
            QRCode: "",
            ProductId: ticket.ProductID,
            Quantity: 1,
            StartDate: startdate,
            EndDate: enddate,
            CustomerName: cr.adSoyad,
            CustomerGsm: cr.telefon,
            PersonType: 1,
          });
        }
      } else {
        let tarih = localStorage.tarih;
        let saat = localStorage.saat;
        console.log('tarih 2222', localStorage.tarih)
        let startdate = new Date(
          tarih.split("/")[2] +
            "-" +
            tarih.split("/")[1] +
            "-" +
            tarih.split("/")[0] +
            "T" +
            saat.split(":")[0] +
            ":" +
            saat.split(":")[1]
        );
        console.log(startdate, "BEFORE");
        let enddate = new Date();
        orderTickets.push({
          TicketNumber: "",
          QRCode: "",
          ProductId: ticket.ProductID,
          Quantity: 1,
          StartDate: startdate,
          EndDate: enddate,
          CustomerName: localStorage.faturaAd,
          CustomerGsm: localStorage.telefon,
          PersonType: 1,
        });
      }
    }
    else
    {
      // extremee 
      if (localStorage.kisiler !== undefined) {
        var ticketowners = JSON.parse(localStorage.kisiler);
        for (let i = 0; i < ticketowners.length; i++) {
          var cr = ticketowners[i];
          
          orderTickets.push({
            TicketNumber: "",
            QRCode: "",
            Quantity: 1,
            CustomerName: cr.ad+" " +cr.soyad,
            CustomerGsm: cr.telefon,
            PersonType: 1,
          });
        }
      }

    }
    // console.log('this.$root.ticketType', this.$root.ticketType)
    if(this.$root.ticketType !== 10){
      var campaigns = [];
      if(this.$root.bulkTicket != null) {// if multi session sales campaign
        const selectedCampaign = JSON.parse(window.localStorage.getItem('campaign') )
        campaigns = [{
          CampaignId: selectedCampaign.CampaignId,
          CampaignTypeId: selectedCampaign.CampaignTypeId
        }]
        orderDetail[0].campaignid = selectedCampaign.CampaignId
        orderDetail[0].multiSessionSales = 1
        orderDetail[0].CampaignQuantity = selectedCampaign.CampaignDetailList[0].Quantity
      }
      var paymentTransactions = [];
      this.datatosend = {
        customer: customer,
        order: order,
        orderDetail: orderDetail,
        orderTickets: orderTickets,
        campaigns: campaigns,
        paymentTransactions: paymentTransactions,
        territoriId: parseInt(localStorage.konumId),
      };
    }else{
      
        var tarih = localStorage.tarih;
        var saat = localStorage.saat;
        var startdate = new Date(
            tarih.split("/")[2] +
              "-" +
              tarih.split("/")[1] +
              "-" +
              tarih.split("/")[0] +
              "T" +
              saat.split(":")[0] +
              ":" +
              saat.split(":")[1]
          );
        var extremeCamp = JSON.parse(window.localStorage.getItem('seciliKampanya')) 
        this.datatosendExtreme = {
          customer: customer,
          order: order,
          orderDetail: orderDetail,
          orderTickets: orderTickets,
          territoriId: parseInt(localStorage.konumId),
          campaignId: extremeCamp.CampaignId,
          adultQuant: parseInt(localStorage.cocukSayisi),
          kidQuant:   parseInt(localStorage.yetiskinSayisi),
          ticketDate:startdate
        };
    }
  },
  methods: {
     addLeadingZeros(n) {
      if (n <= 9) {
        return "0" + n;
      }
      return n
    },
    convertBirthdate(bdate){
        if(bdate === undefined) return null;
        return bdate.split(".")[2]+"-"+bdate.split(".")[1]+"-"+bdate.split(".")[0]+"T00:00:00.000Z"
    },
    changeTimezone(date, ianatz) {
      // suppose the date is 12:00 UTC
      var invdate = new Date(
        date.toLocaleString("en-US", {
          timeZone: ianatz,
        })
      );
      // then invdate will be 15:00 in Istanbul
      // and the diff is 3 hours
      var diff = 3;
      // so 12:00 in Istanbul is 15:00 UTC
      return new Date(invdate.getTime() + diff); // needs to substract
    },
    adKayit(adSoyad) {
      localStorage.cardAdSoyad = adSoyad;
    },
    kartKayit(kartno) {
      localStorage.cardNo = kartno;
    },
    ayKayit(ay) {
      this.ayNo = ay;
      localStorage.cardAy = ay;
    },
    yilKayit(yil) {
      this.yilNo = yil;
      localStorage.cardYil = yil;
    },
    cvvKayit(cvv) {
      localStorage.cardCvv = cvv;
    },

    modalTextControl(){
      
      this.validation();
      if(
        this.errorAdSoyad === false &&
        this.errorKartNo === false &&
        this.errorCvv === false &&
        this.errorSozlesme === false &&
        this.errorAy === false &&
        this.errorYil === false        
      ) {
        return true;
      } else {
        return false;
      }
    },

    validation(){
      var thisComponent = this;


      if (this.adSoyad.length < 3) {
        this.errorAdSoyad = true;
      }
      if (this.kartNo.length < 19) {
        this.errorKartNo = true;
      }
      if (this.ayNo === 0) {
        this.errorAy = true;
      } else {
        this.errorSilAy();
      }
      if (this.yilNo === 0) {
        this.errorYil = true;
      } else {
        this.errorSilYil();
      }
      if (this.cvv.length < 3) {
        this.errorCvv = true;
      }
      if (this.sozlesme === false) {
        this.errorSozlesme = true;
      }

      if (
        this.errorAdSoyad === false &&
        this.errorKartNo === false &&
        this.errorCvv === false &&
        this.errorSozlesme === false &&
        this.errorAy === false &&
        this.errorYil === false
      ) {

        if(!this.parkKurallari) {
          this.sozlesmeModal = true;
          return false;
        }
      }

    },


    siparistamamla: function () {

      this.validation();
var thisComponent = this;
     
      if (
        this.errorAdSoyad === false &&
        this.errorKartNo === false &&
        this.errorCvv === false &&
        this.errorSozlesme === false &&
        this.errorAy === false &&
        this.errorYil === false
      ) {

      if(!this.parkKurallari) {
        this.sozlesmeModal = true;
        return false;
      }

    if(this.$root.ticketType !== 10){
        axios({
          url: `${process.env.VUE_APP_ENV_SERVER}/Services/FlyzoneCrm/B2CServices/SaveOrder`,
          method: "post",
          headers: { "Content-Type": "application/json" },
          data: JSON.stringify(this.datatosend),
        }).then((response2) => {
          localStorage.setItem("data", response2.data);
          console.log("HELLO");

          window.addEventListener("message", function (e) {
            if (e.data.event === "warranty-transaction-success") {
              console.log(e.data);
              thisComponent.cardClear();
              if (e.data.data === "1") {
                var sncPacketName = JSON.parse(localStorage.secilipaket)[
                  "ProductName"
                ];
                var sncKonum = localStorage.konum;
                var sncDate = localStorage.tarih;
                var sncStartTime = localStorage.saat;
                var sncEndTime = localStorage.bitisSaat;
                var sncDakika = JSON.parse(localStorage.secilipaket)[
                  "Duration"
                ];
                var sncYetiskin = localStorage.yetiskinSayisi;
                var sncChild = localStorage.cocukSayisi;
                var sncPacketPrice = JSON.parse(localStorage.secilipaket)[
                  "Price"
                ];
                var sncProducts = null;
                if(localStorage.RestrictionProducts !== undefined)
                  sncProducts = JSON.parse(localStorage.RestrictionProducts);
                var sncTickets = [
                  {
                    ticketInfo: [
                      {
                        packet: sncPacketName,
                        konum: sncKonum,
                        tarih: sncDate,
                        start: sncStartTime,
                        end: sncEndTime,
                      },
                    ],
                    packets: [
                      {
                        dakika: sncDakika,
                        paket: sncPacketName,
                        person: "Yetişkin",
                        count: sncYetiskin,
                        total: sncPacketPrice,
                      },
                      {
                        dakika: sncDakika,
                        paket: sncPacketName,
                        person: "Çocuk",
                        count: sncChild,
                        total: sncPacketPrice,
                      },
                    ],
                    product: sncProducts,
                  },
                ];

                localStorage.sncTickets = JSON.stringify(sncTickets);

                thisComponent.popKapat();
                thisComponent.$router.push("/odeme-sonuc");
                console.log("popupklapat", "status");
              }
            } else {
              console.log('ERROR: e.data.event != "warranty-transaction-success"');
              console.log(response2)
              //thisComponent.popKapat();
            }
          });
          localStorage.payProcess = response2.data.payProcess;
          thisComponent.payurl = "PostKart.html";
        });
    }
    else{
axios({
          url: `${process.env.VUE_APP_ENV_SERVER}/Services/FlyzoneCrm/B2CServices/SaveOrderExtreme`,
          method: "post",
          headers: { "Content-Type": "application/json" },
          data: JSON.stringify(this.datatosendExtreme),
}).then((response2) => {
          localStorage.setItem("data", response2.data);
          console.log("HELLO");

          window.addEventListener("message", function (e) {
            if (e.data.event === "warranty-transaction-success") {
              thisComponent.cardClear();
              if (e.data.data === "1") {
                var sncPacketName = JSON.parse(localStorage.seciliKampanya)[
                  "Name"
                ];
                var sncKonum = localStorage.konum;
                var sncDate = localStorage.tarih;
                var sncStartTime = localStorage.saat;
                var sncEndTime = localStorage.bitisSaat;
                var sncDakika = JSON.parse(localStorage.secilipaket)[
                  "Duration"
                ];
                var sncYetiskin = localStorage.yetiskinSayisi;
                var sncChild = localStorage.cocukSayisi;
                var sncPacketPrice = JSON.parse(localStorage.secilipaket)[
                  "Price"
                ];
                var sncProducts = JSON.parse(localStorage.RestrictionProducts);
                var sncTickets = [
                  {
                    ticketInfo: [
                      {
                        packet: sncPacketName,
                        konum: sncKonum,
                        tarih: sncDate,
                        start: sncStartTime,
                        end: sncEndTime,
                      },
                    ],
                    packets: [
                      {
                        dakika: sncDakika,
                        paket: sncPacketName,
                        person: "Yetişkin",
                        count: sncYetiskin,
                        total: sncPacketPrice,
                      },
                      {
                        dakika: sncDakika,
                        paket: sncPacketName,
                        person: "Çocuk",
                        count: sncChild,
                        total: sncPacketPrice,
                      },
                    ],
                    product: sncProducts,
                  },
                ];

                localStorage.sncTickets = JSON.stringify(sncTickets);

                thisComponent.popKapat();
                thisComponent.$router.push("/odeme-sonuc");
                console.log("popupklapat", "status");
              }
            } else {
              console.log('ERROR: e.data.event != "warranty-transaction-success"');
              console.log(response2)
              //thisComponent.popKapat();
            }
          });
          localStorage.payProcess = response2.data.payProcess;
          thisComponent.payurl = "PostKart.html";
        });
    }
        thisComponent.popActive = true;
      }
    },
    cardClear() {
      localStorage.cardAdSoyad = "";
      localStorage.cardNo = "";
      localStorage.cardAy = 0;
      localStorage.cardYil = 0;
      localStorage.cardCvv = "";
    },
    errorSilAdSoyad() {
      this.errorAdSoyad = false;
    },
    errorSilKartNo() {
      this.errorKartNo = false;
    },
    errorSilAy() {
      this.errorAy = false;
    },
    errorSilYil() {
      this.errorYil = false;
    },
    errorSilCvv() {
      this.errorCvv = false;
    },
    errorSilSozlesme() {
      this.errorSozlesme = false;
    },
    popKapat() {
      this.popActive = false;
      this.payurl = "";
    },
    fillCorporate(customer) {
      if(window.localStorage.getItem('fatura') === 'Kurumsal Fatura') {
        customer.VKN= window.localStorage.getItem('vergiNo')
        customer.VergiDairesi= window.localStorage.getItem('vergiDaire')
        customer.Sehir= window.localStorage.getItem('il')
        customer.Ilce= window.localStorage.getItem('ilce')
        customer.CompanyName= window.localStorage.getItem('unvan')
        customer.Address= window.localStorage.getItem('faturaAdres')
      } else if(window.localStorage.getItem('fatura') === 'Bireysel Fatura') {
        customer.Sehir= window.localStorage.getItem('il')
        customer.Ilce= window.localStorage.getItem('ilce')
        customer.Address= window.localStorage.getItem('faturaAdres')
      }
      console.log('fillCorporate customer', customer)
      return customer
    }
  },
  computed: {
    yillar() {
      // return a list of years from current year to 20 years from now
      var arr = [];
      for (var i = 0; i < 25; i++) {
        arr.push({
          id: i,
          yil: `${new Date().getFullYear() + i}`
        });
      }
      return arr;
    }
  }
};
</script>
