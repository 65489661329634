export default {
  where: [
    {
      id: 1,
      name: "MALL OF İSTANBUL",
      image: "images/mallof.jpg",
      url: "/paketler",
      adres: "Ziya Gökalp Mah. Süleyman Demirel Bulvarı No: 7 F/34 Başakşehir/İstanbul ",
      telefon: "+90 (212) 936 0359",
      aktifMi: true,
    },
    {
      id: 4,
      name: "NİSSARA AVM NEVŞEHİR",
      image: "images/nissara.png",
      url: "/paketler",
      adres: "Yeni, Lale Cd. No:29, 50160 Nevşehir Merkez/Nevşehir",
      telefon: "+90 (212) 936 0359",
      aktifMi: true,
    },
    {
      id: 3,
      name: "DEMİRÖREN İSTİKLAL",
      image: "images/demiroren.jpg",
      url: "/paketler",
      adres: "Hüseyinağa, İstiklal Cd. No:52, 34435 Beyoğlu/İstanbul",
      telefon: "+90 (212) 231 4354",
      aktifMi: true,
    },
    {
      id: 5,
      name: "TORİUM AVM ESENYURT",
      image: "images/torium.png",
      url: "/paketler",
      adres: "Turgut Özal Mahallesi E-5 Üzeri, Haramidere Yolu, 34903 Esenyurt",
      telefon: "+90 (212) 936 0359",
      aktifMi: true,
    },
    {
      id: 8,
      name: "LENS İSTANBUL KURTKÖY",
      image: "images/kurtkoy.jpg",
      url: "/paketler",
      adres: "Yeni̇şehi̇r Mah Sümbül Sk Lens İstanbul D Blok No: 4n Pendi̇k/ İstanbul",
      telefon: "+90 (212) 936 0359",
      aktifMi: true,
    },
  ],
  packets: [
    {
      name: "BASE FLIGHT",
      image: "images/paket1.jpg",
      url: "/ne-zaman",
      description:
        "7-13 yaş arasındakilerin ebeveyn veya bir büyüğün eşliğinde; 13 yaşından büyüklerin ise bireysel olarak Katılabileceği 60 dakikalık seans.",
    },
    {
      name: "KIDS CLUB",
      image: "images/paket2.jpg",
      url: "/ne-zaman",
      description:
        "7-13 yaş arasındakilerin ebeveyn veya bir büyüğün eşliğinde; 13 yaşından büyüklerin ise bireysel olarak Katılabileceği 60 dakikalık seans.",
    },
    {
      name: "NIGHT FLIGHT",
      image: "images/paket3.jpg",
      url: "/ne-zaman",
      description:
        "7-13 yaş arasındakilerin ebeveyn veya bir büyüğün eşliğinde; 13 yaşından büyüklerin ise bireysel olarak Katılabileceği 60 veya 120 dakikalık seans.",
    },
  ],
  price: [
    {
      id: 0,
      second: "60",
      tl: "49.00",
      indexPrice: true,
    },
    {
      id: 1,
      second: "90",
      tl: "55.00",
      indexPrice: false,
    },
    {
      id: 2,
      second: "120",
      tl: "70.00",
      indexPrice: false,
    },
  ],
  socks: [
    {
      id: 0,
      no: "31-34 Numara Silikonlu Çorap",
      tl: "7.5",
      counter: 0,
      varMi: false,
    },
    {
      id: 1,
      no: "35-38 Numara Silikonlu Çorap",
      tl: "7.5",
      counter: 0,
      varMi: false,
    },
    {
      id: 2,
      no: "39-42 Numara Silikonlu Çorap",
      tl: "7.5",
      counter: 0,
      varMi: false,
    },
    {
      id: 3,
      no: "43-46 Numara Silikonlu Çorap",
      tl: "7.5",
      counter: 0,
      varMi: false,
    },
    {
      id: 4,
      no: "Silikonlu Çorabım Var",
      tl: "7.5",
      counter: 0,
      varMi: true,
    },
  ],
  aylar: [
    {
      id: 0,
      ayNo: "01",
      ay: "Ocak",
    },
    {
      id: 1,
      ayNo: "02",
      ay: "Şubat",
    },
    {
      id: 2,
      ayNo: "03",
      ay: "Mart",
    },
    {
      id: 3,
      ayNo: "04",
      ay: "Nisan",
    },
    {
      id: 4,
      ayNo: "05",
      ay: "Mayıs",
    },
    {
      id: 5,
      ayNo: "06",
      ay: "Haziran",
    },
    {
      id: 6,
      ayNo: "07",
      ay: "Temmuz",
    },
    {
      id: 7,
      ayNo: "08",
      ay: "Ağustos",
    },
    {
      id: 8,
      ayNo: "09",
      ay: "Eylül",
    },
    {
      id: 9,
      ayNo: "10",
      ay: "Ekim",
    },
    {
      id: 10,
      ayNo: "11",
      ay: "Kasım",
    },
    {
      id: 11,
      ayNo: "12",
      ay: "Aralık",
    },
  ],
  yillar: [
    {
      id: 3,
      yil: "2023",
    },
    {
      id: 4,
      yil: "2024",
    },
    {
      id: 5,
      yil: "2025",
    },
    {
      id: 6,
      yil: "2026",
    },
    {
      id: 7,
      yil: "2027",
    },
    {
      id: 8,
      yil: "2028",
    },
    {
      id: 9,
      yil: "2029",
    },
    {
      id: 10,
      yil: "2030",
    },
    {
      id: 11,
      yil: "2031",
    },
    {
      id: 12,
      yil: "2032",
    },
    {
      id: 13,
      yil: "2033",
    },
    {
      id: 14,
      yil: "2034",
    },
    {
      id: 15,
      yil: "2035",
    },
    {
      id: 16,
      yil: "2036",
    },
    {
      id: 17,
      yil: "2037",
    },
    {
      id: 18,
      yil: "2038",
    },
    {
      id: 19,
      yil: "2039",
    },
    {
      id: 20,
      yil: "2040",
    },
    {
      id: 21,
      yil: "2041",
    },
    {
      id: 22,
      yil: "2042",
    },
    {
      id: 23,
      yil: "2043",
    },
    {
      id: 24,
      yil: "2044",
    },
    {
      id: 25,
      yil: "2045",
    },
  ],
  sorular: [
    {
      id: 0,
      soru: "Mall of Istanbul’a Nasıl Gidilir?",
      cevap:
        "<h1>Metro</h1><h2>Taksim</h2><p>Taksim metrosu ile Yenikapı ya gidiniz. Yenikapı’dan Bağcılar metrosuna bininiz. Kirazlı durağında ininiz ve Başakşehir metrosuna aktarma yapınız. İkitelli Sanayi durağından Mall of İstanbul’a giden ücretsiz müşteri servislerini kullanabilirsiniz.</p> <h2>Anadolu Yakası</h2><p>Marmaray ile Yenikapı’ya gidiniz. Yenikapı’dan Bağcılar metrosuna bininiz. Kirazlı durağında ininiz ve Başakşehir metrosuna aktarma yapınız. İkitelli Sanayi durağından Mall of İstanbul’a giden ücretsiz müşteri servislerini kullanabilirsiniz</p>",
      link: "/mall-of-istanbula-nasil-gidilir",
      gorsel: "images/mallofanasilgidlir.jpg",
    },
    {
      id: 1,
      soru: "Kaydırmaz Çorapların Önemi Nedir?",
      cevap:
        "<h1>Metro</h1><h2>Taksim</h2><p>Taksim metrosu ile Yenikapı ya gidiniz. Yenikapı’dan Bağcılar metrosuna bininiz. Kirazlı durağında ininiz ve Başakşehir metrosuna aktarma yapınız. İkitelli Sanayi durağından Mall of İstanbul’a giden ücretsiz müşteri servislerini kullanabilirsiniz.</p> <h2>Anadolu Yakası</h2><p>Marmaray ile Yenikapı’ya gidiniz. Yenikapı’dan Bağcılar metrosuna bininiz. Kirazlı durağında ininiz ve Başakşehir metrosuna aktarma yapınız. İkitelli Sanayi durağından Mall of İstanbul’a giden ücretsiz müşteri servislerini kullanabilirsiniz</p>",
      link: "/kaydirmaz-coraplarin-onemi-nedir",
      gorsel: "images/flyzone-corap.jpg",
    },
    {
      id: 1,
      soru: "Mall of Istanbul’a Nasıl Gidilir?",
      cevap:
        "<h1>Metro</h1><h2>Taksim</h2><p>Taksim metrosu ile Yenikapı ya gidiniz. Yenikapı’dan Bağcılar metrosuna bininiz. Kirazlı durağında ininiz ve Başakşehir metrosuna aktarma yapınız. İkitelli Sanayi durağından Mall of İstanbul’a giden ücretsiz müşteri servislerini kullanabilirsiniz.</p> <h2>Anadolu Yakası</h2><p>Marmaray ile Yenikapı’ya gidiniz. Yenikapı’dan Bağcılar metrosuna bininiz. Kirazlı durağında ininiz ve Başakşehir metrosuna aktarma yapınız. İkitelli Sanayi durağından Mall of İstanbul’a giden ücretsiz müşteri servislerini kullanabilirsiniz</p>",
      link: "/mall-of-istanbula-nasil-gidilir",
      gorsel: "images/mallofanasilgidlir.jpg",
    },
  ],
};
