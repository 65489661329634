<template>
    <div class="sonucMesaj">
       <div class="sonucBaslik">
           <img src="images/icn-odendi.png" alt="" style="width:25px; float:left; margin-right:10px">
            <h1>ÖDEME YAPILDI</h1>
       </div>
       
       <div class="sonucContent">
           <p>Uçuş bilgileriniz e-posta adresinize gönderilmiştir. <br>İyi eğlenceler.</p>
           <a href="/" @click="cacheSil">ANASAYFA</a>
           <!-- <a href="/biletlerim">BİLETLERİM</a> -->
       </div>
    </div>   
</template>

<script>
import data from "../data"
export default {
    name:"SonucMesaj",
    data(){
        return{
        }
    },
     created(){
      this.aylar = data.aylar
      this.yillar = data.yillar
    },
    methods: {
        cacheSil(){
            localStorage.clear()
        }
    },
    components:{
    }

}
</script>
