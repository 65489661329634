<template>
<div class="overflow">
<div id="xapp">
    <div>
        <div class="snotify snotify-left_top"></div>
        <div class="snotify snotify-left_center"></div>
        <div class="snotify snotify-left_bottom"></div>
        <div class="snotify snotify-right_top"></div>
        <div class="snotify snotify-right_center"></div>
        <div class="snotify snotify-right_bottom"></div>
        <div class="snotify snotify-center_top"></div>
        <div class="snotify snotify-center_center"></div>
        <div class="snotify snotify-center_bottom"></div>
    </div>
    <div>
        <div class="top">
            <div class="menuContainer">
                <div class="mobilMenuBtn" style="display: none"><span></span> <span></span> <span></span></div>
                <div class="mobil-logo">
                    <a href="https://bilet.flyzoneturkey.com/">
                        <svg width="37px" height="30px" viewBox="0 0 37 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <defs>
                                <linearGradient x1="22.8641323%" y1="37.1860257%" x2="72.6372044%" y2="60.7017124%" id="linearGradient-1">
                                    <stop stop-color="#EE7D98" offset="0%"></stop>
                                    <stop stop-color="#D10A53" offset="16.74%"></stop>
                                    <stop stop-color="#C8115A" offset="22.91%"></stop>
                                    <stop stop-color="#B0266C" offset="32.91%"></stop>
                                    <stop stop-color="#89478A" offset="45.52%"></stop>
                                    <stop stop-color="#5375B3" offset="60.07%"></stop>
                                    <stop stop-color="#16A8E1" offset="74.61%"></stop>
                                    <stop stop-color="#7BCBF2" offset="100%"></stop>
                                </linearGradient>
                                <linearGradient x1="-5.56903181%" y1="58.7765423%" x2="102.004238%" y2="35.2264199%" id="linearGradient-2">
                                    <stop stop-color="#EF8205" offset="0%"></stop>
                                    <stop stop-color="#EC6621" offset="6.66%"></stop>
                                    <stop stop-color="#E84840" offset="15.64%"></stop>
                                    <stop stop-color="#E53257" offset="24.38%"></stop>
                                    <stop stop-color="#E42564" offset="32.67%"></stop>
                                    <stop stop-color="#E32069" offset="40.05%"></stop>
                                    <stop stop-color="#D52F76" offset="48.39%"></stop>
                                    <stop stop-color="#B05797" offset="64.49%"></stop>
                                    <stop stop-color="#7597CC" offset="86.51%"></stop>
                                    <stop stop-color="#4EC1EF" offset="100%"></stop>
                                </linearGradient>
                                <linearGradient x1="43.038861%" y1="105.464893%" x2="57.4834735%" y2="2.34844037%" id="linearGradient-3">
                                    <stop stop-color="#EF8205" offset="0%"></stop>
                                    <stop stop-color="#DC3637" stop-opacity="0.3859" offset="61.41%"></stop>
                                    <stop stop-color="#D10A53" stop-opacity="0" offset="100%"></stop>
                                </linearGradient>
                                <linearGradient x1="20.4888348%" y1="40.5298135%" x2="81.0589197%" y2="59.970452%" id="linearGradient-4">
                                    <stop stop-color="#EE7D98" offset="0%"></stop>
                                    <stop stop-color="#D10A53" offset="16.74%"></stop>
                                    <stop stop-color="#C9135B" offset="23.5%"></stop>
                                    <stop stop-color="#B22B70" offset="34.47%"></stop>
                                    <stop stop-color="#8D5293" offset="48.27%"></stop>
                                    <stop stop-color="#5A88C2" offset="64.22%"></stop>
                                    <stop stop-color="#35AFE5" offset="74.61%"></stop>
                                    <stop stop-color="#A4DAF6" offset="100%"></stop>
                                </linearGradient>
                                <linearGradient x1="-2.10096459%" y1="59.980675%" x2="102.532606%" y2="40.6254562%" id="linearGradient-5">
                                    <stop stop-color="#E84553" offset="0%"></stop>
                                    <stop stop-color="#D10A53" offset="19.85%"></stop>
                                    <stop stop-color="#C9135B" offset="26.24%"></stop>
                                    <stop stop-color="#B22B70" offset="36.62%"></stop>
                                    <stop stop-color="#8D5293" offset="49.69%"></stop>
                                    <stop stop-color="#5A88C2" offset="64.78%"></stop>
                                    <stop stop-color="#35AFE5" offset="74.61%"></stop>
                                    <stop stop-color="#A4DAF6" offset="100%"></stop>
                                </linearGradient>
                            </defs>
                            <g id="Güzel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="logo-mobil" fill-rule="nonzero">
                                    <g id="FlyzoneLogo">
                                        <g id="Group" transform="translate(0.276417, 0.000000)">
                                            <path d="M35.9685414,0.0465587752 C35.8162816,0.279352651 33.2479989,4.32870775 24.5402496,5.4404558 C15.5613269,6.58680835 6.07780768,7.14740117 3.74420637,18.9425006 C2.17190395,26.8895802 0.00692089902,29.4748506 0.00692089902,29.4748506 C0.00692089902,29.4748506 2.7431927,28.9652207 5.1944493,26.4434968 C6.62833374,24.968087 7.58970953,23.1007026 8.01503024,21.0873501 C8.26858681,19.8881471 8.67566151,18.3957496 9.49924849,17.6375966 C10.7745814,16.4635604 12.3940718,15.9249886 17.4085777,15.8400503 C25.114055,15.7091824 25.1813765,11.4584921 25.1813765,11.4584921 C23.1894159,13.7040092 20.3071761,12.5475899 16.6403579,12.7124332 C12.6822329,12.890489 10.7909399,14.40302 10.3863819,14.8837079 C10.7645147,14.2331434 12.518648,11.5434304 17.963508,11.2798071 C36.3894579,10.3882694 35.7225349,1.55468559 35.9685414,0.0465587752 Z" id="Path" fill="url(#linearGradient-1)"></path>
                                            <path d="M18.6845398,18.7078192 C20.4997029,17.7628019 21.6422804,16.1810619 21.6687057,16.1684785 C19.6717117,16.9889196 18.9072669,17.2223426 14.7937362,16.9020937 C12.6740536,16.7372505 11.2672236,17.3267853 10.4920829,17.7401517 C9.25135447,18.4020413 8.7492747,20.5317907 8.57876892,21.1263588 C9.37341396,20.4229438 10.2215387,19.8541718 12.7237583,19.8560593 C12.8955224,19.8560593 13.0622531,19.8522842 13.2283547,19.84788 C12.2858541,20.1492537 10.6078507,20.3065469 9.54203223,21.174176 C8.22706141,22.2450278 7.58782201,24.1684085 7.55007166,24.2841763 C7.38397008,24.6327379 7.26820231,24.8038729 7.26820231,24.8038729 C7.26820231,24.8038729 8.66244888,24.9366283 10.1661715,24.6050544 C12.314796,24.1306582 14.2004264,23.3014086 16.7737425,20.0844489 C15.0554721,21.5177042 12.682862,21.4057115 10.7758398,21.7033101 C11.3458702,21.4409452 12.081373,21.1295047 12.8464469,20.9206194 C16.3723304,19.9592436 17.1606837,19.5005767 18.6845398,18.7078192 Z" id="Path" fill="url(#linearGradient-2)"></path>
                                            <path d="M4.95599287,20.9042609 C4.36394142,20.6116956 3.96127093,19.9869272 3.69387256,19.1872488 C2.12597434,26.9405432 0.00692089902,29.4742214 0.00692089902,29.4742214 C0.00692089902,29.4742214 2.74382187,28.9645916 5.1944493,26.4428676 C6.62833374,24.9674578 7.58970953,23.1000734 8.01503024,21.086721 C8.06347653,20.8570729 8.11758538,20.616729 8.17924429,20.3732392 C7.37075745,21.0621832 6.25963858,21.5485337 4.95599287,20.9042609 Z" id="Path" fill="url(#linearGradient-3)"></path>
                                            <path d="M17.963508,11.2798071 C36.3894579,10.3876403 35.7225349,1.55468559 35.9685414,0.0459296026 C35.847111,0.232164703 36.2208396,5.56314447 23.193191,8.48061799 C19.0651893,9.40487259 13.0156944,8.67943654 9.60117446,12.5910028 C7.14865951,15.4008878 6.92907826,20.7381593 5.53671921,23.5725821 C4.50865112,25.6658394 2.95081967,27.5508406 0.580097172,29.3225908 C1.51127268,29.0338006 3.42710336,28.2618057 5.19507847,26.4428676 C6.62896291,24.9674578 7.5903387,23.1000734 8.01565941,21.086721 C8.26921598,19.8875179 8.67629068,18.3951204 9.49987766,17.6369674 C10.7752106,16.4629312 12.394701,15.9243595 17.4092069,15.8394212 C25.1146842,15.7085533 25.1820057,11.4578629 25.1820057,11.4578629 C23.1900451,13.7033801 20.3078052,12.5469607 16.6409871,12.711804 C12.682862,12.8898598 10.7915691,14.4023909 10.3870111,14.8830788 C10.7645147,14.2337726 12.518648,11.5434304 17.963508,11.2798071 Z" id="Path" fill="url(#linearGradient-4)"></path>
                                            <path d="M12.8124716,20.3449264 C12.0046139,20.6186165 11.3207033,21.1156629 10.7758398,21.7045685 C11.3458702,21.4422035 12.081373,21.130763 12.8464469,20.9218777 C16.3723304,19.9592436 17.1613129,19.5012059 18.6845398,18.7078192 C20.4997029,17.7628019 21.6422804,16.1810619 21.6687057,16.1684785 C21.6643014,16.170366 21.6598972,16.1722535 21.6548639,16.174141 C21.0055577,16.7888427 19.7774127,17.8357859 17.635709,18.8154077 C16.031948,19.549023 14.8956622,19.6396239 12.8124716,20.3449264 Z" id="Path" fill="url(#linearGradient-5)"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </a>
                </div>
                <h1 class="logo"><a href="https://bilet.flyzoneturkey.com/" class="router-link-active"><img src="/statik-paket-redirect_files/logo.png" alt="flyzone air sports"></a></h1>
                <div class="tab-menu">
                    <ul>
                        <li class="link">

                            <a href="/nerede"><span>NEREDE</span></a>
                            <span class="menu-ileri">
                           <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                              <g id="Güzel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                 <g id="3-Nerede" transform="translate(-691.000000, -34.000000)">
                                    <g id="Group-2" transform="translate(606.000000, 33.000000)">
                                       <g id="icn-asama" transform="translate(85.000000, 1.000000)">
                                          <path d="M7.5,0 C11.6421356,0 15,3.35786438 15,7.5 C15,11.6421356 11.6421356,15 7.5,15 C3.35786438,15 0,11.6421356 0,7.5 C0,3.35786438 3.35786438,0 7.5,0 Z M6.62045842,4 C6.51811359,4 6.42212982,4.039643 6.35022718,4.11160243 L6.121286,4.34048682 C5.97231237,4.48963083 5.97231237,4.73203245 6.121286,4.88094929 L8.73181744,7.49148073 L6.11838945,10.1049087 C6.04648682,10.1768682 6.00678702,10.2727951 6.00678702,10.3750832 C6.00678702,10.4774848 6.04648682,10.5734118 6.11838945,10.645428 L6.34733063,10.8742556 C6.41929006,10.946215 6.51521704,10.985858 6.61756187,10.985858 C6.71990669,10.985858 6.81589047,10.946215 6.8877931,10.8742556 L9.99948479,7.76267748 C10.0715578,7.69049087 10.111144,7.59410953 10.1109178,7.49165112 C10.111144,7.38879513 10.0715578,7.29247059 9.99948479,7.22034077 L6.89068966,4.11160243 C6.81878702,4.039643 6.72280325,4 6.62045842,4 Z" id="Combined-Shape" fill="#FFFFFF"></path>
                                          <g id="right-arrow-copy-9" transform="translate(6.000000, 4.000000)"></g>
                                       </g>
                                    </g>
                                 </g>
                              </g>
                           </svg>
                        </span>
                            <span class="menu-ok">
                           <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                              <g id="Güzel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                 <g id="3-Nerede" transform="translate(-691.000000, -34.000000)">
                                    <g id="Group-2" transform="translate(606.000000, 33.000000)">
                                       <g id="icn-asama" transform="translate(85.000000, 1.000000)">
                                          <path d="M7.5,0 C11.6421356,0 15,3.35786438 15,7.5 C15,11.6421356 11.6421356,15 7.5,15 C3.35786438,15 0,11.6421356 0,7.5 C0,3.35786438 3.35786438,0 7.5,0 Z M6.62045842,4 C6.51811359,4 6.42212982,4.039643 6.35022718,4.11160243 L6.121286,4.34048682 C5.97231237,4.48963083 5.97231237,4.73203245 6.121286,4.88094929 L8.73181744,7.49148073 L6.11838945,10.1049087 C6.04648682,10.1768682 6.00678702,10.2727951 6.00678702,10.3750832 C6.00678702,10.4774848 6.04648682,10.5734118 6.11838945,10.645428 L6.34733063,10.8742556 C6.41929006,10.946215 6.51521704,10.985858 6.61756187,10.985858 C6.71990669,10.985858 6.81589047,10.946215 6.8877931,10.8742556 L9.99948479,7.76267748 C10.0715578,7.69049087 10.111144,7.59410953 10.1109178,7.49165112 C10.111144,7.38879513 10.0715578,7.29247059 9.99948479,7.22034077 L6.89068966,4.11160243 C6.81878702,4.039643 6.72280325,4 6.62045842,4 Z" id="Combined-Shape" fill="#FFFFFF"></path>
                                          <g id="right-arrow-copy-9" transform="translate(6.000000, 4.000000)"></g>
                                       </g>
                                    </g>
                                 </g>
                              </g>
                           </svg>
                        </span>
                        </li>
                        <li class="link">
                            <a href="/paketler"><span>PAKETLER</span></a>
                            <span class="menu-ileri">
                           <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                              <g id="Güzel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                 <g id="3-Nerede" transform="translate(-691.000000, -34.000000)">
                                    <g id="Group-2" transform="translate(606.000000, 33.000000)">
                                       <g id="icn-asama" transform="translate(85.000000, 1.000000)">
                                          <path d="M7.5,0 C11.6421356,0 15,3.35786438 15,7.5 C15,11.6421356 11.6421356,15 7.5,15 C3.35786438,15 0,11.6421356 0,7.5 C0,3.35786438 3.35786438,0 7.5,0 Z M6.62045842,4 C6.51811359,4 6.42212982,4.039643 6.35022718,4.11160243 L6.121286,4.34048682 C5.97231237,4.48963083 5.97231237,4.73203245 6.121286,4.88094929 L8.73181744,7.49148073 L6.11838945,10.1049087 C6.04648682,10.1768682 6.00678702,10.2727951 6.00678702,10.3750832 C6.00678702,10.4774848 6.04648682,10.5734118 6.11838945,10.645428 L6.34733063,10.8742556 C6.41929006,10.946215 6.51521704,10.985858 6.61756187,10.985858 C6.71990669,10.985858 6.81589047,10.946215 6.8877931,10.8742556 L9.99948479,7.76267748 C10.0715578,7.69049087 10.111144,7.59410953 10.1109178,7.49165112 C10.111144,7.38879513 10.0715578,7.29247059 9.99948479,7.22034077 L6.89068966,4.11160243 C6.81878702,4.039643 6.72280325,4 6.62045842,4 Z" id="Combined-Shape" fill="#FFFFFF"></path>
                                          <g id="right-arrow-copy-9" transform="translate(6.000000, 4.000000)"></g>
                                       </g>
                                    </g>
                                 </g>
                              </g>
                           </svg>
                        </span>
                            <span class="menu-ok">
                           <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                              <g id="Güzel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                 <g id="3-Nerede" transform="translate(-691.000000, -34.000000)">
                                    <g id="Group-2" transform="translate(606.000000, 33.000000)">
                                       <g id="icn-asama" transform="translate(85.000000, 1.000000)">
                                          <path d="M7.5,0 C11.6421356,0 15,3.35786438 15,7.5 C15,11.6421356 11.6421356,15 7.5,15 C3.35786438,15 0,11.6421356 0,7.5 C0,3.35786438 3.35786438,0 7.5,0 Z M6.62045842,4 C6.51811359,4 6.42212982,4.039643 6.35022718,4.11160243 L6.121286,4.34048682 C5.97231237,4.48963083 5.97231237,4.73203245 6.121286,4.88094929 L8.73181744,7.49148073 L6.11838945,10.1049087 C6.04648682,10.1768682 6.00678702,10.2727951 6.00678702,10.3750832 C6.00678702,10.4774848 6.04648682,10.5734118 6.11838945,10.645428 L6.34733063,10.8742556 C6.41929006,10.946215 6.51521704,10.985858 6.61756187,10.985858 C6.71990669,10.985858 6.81589047,10.946215 6.8877931,10.8742556 L9.99948479,7.76267748 C10.0715578,7.69049087 10.111144,7.59410953 10.1109178,7.49165112 C10.111144,7.38879513 10.0715578,7.29247059 9.99948479,7.22034077 L6.89068966,4.11160243 C6.81878702,4.039643 6.72280325,4 6.62045842,4 Z" id="Combined-Shape" fill="#FFFFFF"></path>
                                          <g id="right-arrow-copy-9" transform="translate(6.000000, 4.000000)"></g>
                                       </g>
                                    </g>
                                 </g>
                              </g>
                           </svg>
                        </span>
                        </li>
                        <li class="active">
                            <a href="#"><span>Rezervasyon</span></a>
                            <span class="menu-ileri">
                           <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                              <g id="Güzel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                 <g id="3-Nerede" transform="translate(-691.000000, -34.000000)">
                                    <g id="Group-2" transform="translate(606.000000, 33.000000)">
                                       <g id="icn-asama" transform="translate(85.000000, 1.000000)">
                                          <path d="M7.5,0 C11.6421356,0 15,3.35786438 15,7.5 C15,11.6421356 11.6421356,15 7.5,15 C3.35786438,15 0,11.6421356 0,7.5 C0,3.35786438 3.35786438,0 7.5,0 Z M6.62045842,4 C6.51811359,4 6.42212982,4.039643 6.35022718,4.11160243 L6.121286,4.34048682 C5.97231237,4.48963083 5.97231237,4.73203245 6.121286,4.88094929 L8.73181744,7.49148073 L6.11838945,10.1049087 C6.04648682,10.1768682 6.00678702,10.2727951 6.00678702,10.3750832 C6.00678702,10.4774848 6.04648682,10.5734118 6.11838945,10.645428 L6.34733063,10.8742556 C6.41929006,10.946215 6.51521704,10.985858 6.61756187,10.985858 C6.71990669,10.985858 6.81589047,10.946215 6.8877931,10.8742556 L9.99948479,7.76267748 C10.0715578,7.69049087 10.111144,7.59410953 10.1109178,7.49165112 C10.111144,7.38879513 10.0715578,7.29247059 9.99948479,7.22034077 L6.89068966,4.11160243 C6.81878702,4.039643 6.72280325,4 6.62045842,4 Z" id="Combined-Shape" fill="#FFFFFF"></path>
                                          <g id="right-arrow-copy-9" transform="translate(6.000000, 4.000000)"></g>
                                       </g>
                                    </g>
                                 </g>
                              </g>
                           </svg>
                        </span>
                            <span class="menu-ok">
                           <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                              <g id="Güzel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                 <g id="3-Nerede" transform="translate(-691.000000, -34.000000)">
                                    <g id="Group-2" transform="translate(606.000000, 33.000000)">
                                       <g id="icn-asama" transform="translate(85.000000, 1.000000)">
                                          <path d="M7.5,0 C11.6421356,0 15,3.35786438 15,7.5 C15,11.6421356 11.6421356,15 7.5,15 C3.35786438,15 0,11.6421356 0,7.5 C0,3.35786438 3.35786438,0 7.5,0 Z M6.62045842,4 C6.51811359,4 6.42212982,4.039643 6.35022718,4.11160243 L6.121286,4.34048682 C5.97231237,4.48963083 5.97231237,4.73203245 6.121286,4.88094929 L8.73181744,7.49148073 L6.11838945,10.1049087 C6.04648682,10.1768682 6.00678702,10.2727951 6.00678702,10.3750832 C6.00678702,10.4774848 6.04648682,10.5734118 6.11838945,10.645428 L6.34733063,10.8742556 C6.41929006,10.946215 6.51521704,10.985858 6.61756187,10.985858 C6.71990669,10.985858 6.81589047,10.946215 6.8877931,10.8742556 L9.99948479,7.76267748 C10.0715578,7.69049087 10.111144,7.59410953 10.1109178,7.49165112 C10.111144,7.38879513 10.0715578,7.29247059 9.99948479,7.22034077 L6.89068966,4.11160243 C6.81878702,4.039643 6.72280325,4 6.62045842,4 Z" id="Combined-Shape" fill="#FFFFFF"></path>
                                          <g id="right-arrow-copy-9" transform="translate(6.000000, 4.000000)"></g>
                                       </g>
                                    </g>
                                 </g>
                              </g>
                           </svg>
                        </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="mobilMenu">
            <ul>
                <li><a href="https://bilet.flyzoneturkey.com/paketler">Fiyatlar</a></li>
                <li><a href="https://bilet.flyzoneturkey.com/paketler">Etkinlikler</a></li>
                <li><a href="https://bilet.flyzoneturkey.com/paketler">Güvenlik</a></li>
                <li><a href="https://bilet.flyzoneturkey.com/paketler">Parkurlar</a></li>
                <li><a href="https://bilet.flyzoneturkey.com/paketler">Blog</a></li>
                <li><a href="https://bilet.flyzoneturkey.com/paketler">iletişim</a></li>
            </ul>
            <div class="mobilBiletAlBtn"><a href="https://bilet.flyzoneturkey.com/nerede" class="">Bilet Satın Al</a></div>
        </div>
    </div>
    <div class="main" style="background-color: black">
        <div class="flexer-container">
            <!---->
            <div class="flexer" style="">


                <div class="colUclu colUcluPaket contactCol" style="">
                    <div style="width: 70%;align-self: center">
                        <div class="sonucBaslik"  style="margin:0;">
                            <img src="src/assets/images/icn-odendi.png" alt="" style="width: 25px; float: left; margin-right: 10px;" />
                            <h1>Form bıraktığınız için teşekkür ederiz</h1>
                        </div>
                        <div class="sonucContent">
                            <p style="height: unset;margin-left: unset;text-align: left;color: white;">
                                Temsilcimiz rezervasyon için en kısa sürede sizinle iletişime geçecektir. <br />

                            </p>
                            <a href="https://flyzoneturkey.com" style="margin-left: 2rem;margin-top:2rem;">ANASAYFA</a>
                        </div>
                    </div>

                </div>

                <div class="colUclu colUcluPaket modifyPaket" style="display: flex;flex-direction:column;align-items: center;justify-content: center">
                    <h2 style="font-size: 20px;background-image: linear-gradient(144deg,#70e4fd -4%,#0066dc 61%); -webkit-background-clip: text; background-clip: text; -webkit-text-fill-color: transparent; text-shadow: none; ">Online Rezervasyon Paketlerimiz</h2>

                    <div class="hazirPaketlerWrapper" style="">
                    <span class="hazirPaketler" style="font-size: 1.3rem">Base Flight</span>
                    <span class="hazirPaketler" style="font-size: 1.3rem">Kids Flight</span>
                    <span class="hazirPaketler" style="font-size: 1.3rem; margin-top:19px">Night Flight</span>
                    </div>
                    <router-link class="anasayfaButton"  to="/paketler">PAKETLERİ GÖR</router-link>
                </div>
                <!--<div class="butonlar"><a href="/paketler" class=""><span>GERİ</span></a> </div>-->
            </div>
        </div>
    </div>
</div>
<div id="fb-root" class="fb_reset">
    <div style="position: absolute; top: -10000px; width: 0px; height: 0px;">
        <div></div>
    </div>
</div>

</div>
</template>
<script src="statik-paket-redirect_files/jquery.min.js"></script>
<script>
export default {
  name: 'Tesekkurler',
  data() {
    return {}
  },
  created() {},
  mounted() {
  }
}
</script>

<style scoped>
@import '../../public/statik-paket-redirect_files/jquery.fancybox.min.css';
@import '../../public/statik-paket-redirect_files/style.css';
@import '../../public/statik-paket-redirect_files/media-queries.css';
@import '../../public/statik-paket-redirect_files/animate.css';
@import '../../public/statik-paket-redirect_files/thankYouEmbeded.css';
</style>

