import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueRouter from 'vue-router'
Vue.use(Vuex, axios, VueRouter)

const state = {
  branches: {
    mallofistanbul: {
      id: 1,
      name: "MALL OF İSTANBUL",
      adres: 'Hüseyinağa, İstiklal Cd. No:52, 34435 Beyoğlu/İstanbul',
      telefon: "+90 (212) 231 4354",
      image: "/images/mallof.jpg",
      logo: "/images/mallofistanbulLogo.png",
      url: "/paketler",
      aktifMi: true
    },
    lensistanbul: {
      id: 8,
      name: "LENS İSTANBUL AVM",
      adres: 'Yenişehir, Osmanlı Blv. No:4, 34912 Pendik/İstanbu',
      telefon: "+90 (212) 936 0359",
      image: "/images/kurtkoy.jpg",
      logo: "/images/lensistanbulLogo.png",
      url: "/paketler",
      aktifMi: true
    },
    demiroren: {
      id: 3,
      name: "DEMİRÖREN İSTİKLAL",
      adres: 'İstiklal Caddesi, No: 50-54, Kat: 2 Beyoğlu, İstanbul',
      telefon: "+90 (212) 936 0359",
      image: "/images/demiroren.jpg",
      logo: "/images/demirorenLogo.png",
      url: "/paketler",
      aktifMi: true
    },
    nissara: {
      id: 4,
      name: "NİSSARA AVM",
      adres: 'Yeni, Lale Cd. No:29, 50160 Nevşehir Merkez/Nevşehir',
      telefon: "+90 (212) 936 0359",
      image: "/images/nissara.jpg",
      logo: "/images/nissaraLogo.png",
      url: "/paketler",
      aktifMi: true
    },
    torium: {
      id: 5,
      name: "TURIM AVM ESEN YURT",
      adres: 'Turgut Özal Mahallesi E-5 Üzeri, Haramidere Yolu, 34903 Esenyurt',
      telefon: "+90 (212) 936 0359",
      image: "/images/torium.png",
      logo: "/images/turimLogo.png",
      url: "/paketler",
      aktifMi: true
    }
  },
  packages: {
    baseFlight: {
      name: 'Base Flight'
    },
    kidFlight: {
      name: 'Kid Flight'
    },
    nightFlight: {
      name: 'Night Flight'
    },
    eventFlight: {
      name: 'Event Flight'
    },
    groupFlight: {
      name: 'Group Flight'
    },
    partyFlight: {
      name: 'Party Flight'
    },
    ropePlay: {
      name: 'Ropeplay'
    },
    maxSlide: {
      name: 'Max Slide'
    }
  }
};
const getter = {};
const mutations = {
  CHANGE_LOGIN_STATUS(state, newStatus) {
    state.loggedAdmin.isSuperAdmin = newStatus.isSuperAdmin
  }
};
const actions = {
  changeLoginStatus(context, loginObj) {
    context.commit('CHANGE_LOGIN_STATUS', loginObj)
  }
};

const store = new Vuex.Store({
  state,
  getter,
  mutations,
  actions,
})
export default store;
