import VueRouter from 'vue-router';

import Home from '../components/Home.vue'
import Nerede from '../components/Nerede.vue'
import Paketler from '../components/Paketler.vue'
import FormPost3D from '../components/FormPost3D.vue'
import NeZaman from '../components/NeZaman.vue'
import Gelenler from '../components/Gelenler.vue'
import Kimler from '../components/Kimler.vue'
import Odeme from '../components/Odeme.vue'
import GirisYap from '../components/GirisYap.vue'
import KayitOl from '../components/KayitOl.vue'
import Sepet from '../components/Sepet.vue'
import OdemeSonuc from '../components/OdemeSonuc.vue'
import Biletlerim from '../components/Biletlerim.vue'
import ProfilBilgilerim from '../components/ProfilBilgilerim.vue'
import FaturaBilgileri from '../components/FaturaBilgileri.vue'
import Sepetim from '../components/Sepetim.vue'
import BiletAl from '../components/BiletAl.vue'
import Form from '../components/Form.vue'
import Tesekkurler from '../components/Tesekkurler.vue'
import BiletSorgulama from '../components/BiletSorgulama.vue'
import BiletBilgileri from '../components/BiletBilgileri.vue'

import YillikKartOdeme from '../components/YillikKartOdeme.vue'
import YillikKartOdemeTesekkur from '../components/YillikKartOdemeTesekkur.vue'





const routes = [
    { path: '/', name: 'home', component: Home, meta: { showNavigation: true, girisYapildi: false } },
    { path: '/bilet-al/:plan', name: 'biletal', component: BiletAl, meta: { showNavigation: false, girisYapildi: false } },
    { path: '/odeme3d', name: 'odeme3d', component: FormPost3D, meta: { showNavigation: true, girisYapildi: true } },
    { path: '/nerede', name: 'nerede', component: Nerede, meta: { showNavigation: true, girisYapildi: false } },
    { path: '/paketler', name: 'paketler', component: Paketler, meta: { showNavigation: true, girisYapildi: false } },
    { path: '/ne-zaman', name: 'ne-zaman', component: NeZaman, meta: { showNavigation: true, girisYapildi: false } },
    {
        path: '/gelenler', name: 'gelenler', component: Gelenler,
        children: [
            /*{ path: 'gelenler', name: 'gelenler', component: Gelenler },
            { path: 'coraplar', name: 'coraplar', component: Coraplar }*/
        ],
        meta: { showNavigation: true, corapMi: false, gelenlerMi: true, girisYapildi: false }
    },


    { path: '/yillik-kart-odeme', name: 'yillik-kart-odeme', component: YillikKartOdeme, meta: { showNavigation: true, girisYapildi: false } },
    { path: '/yillik-kart-odeme-tesekkur', name: 'yillik-kart-odeme-tesekkur', component: YillikKartOdemeTesekkur, meta: { showNavigation: true, girisYapildi: false } },


    { path: '/form', name: 'form', component: Form, meta: { showNavigation: false, girisYapildi: false } },
    { path: '/tesekkurler', name: 'tesekkurler', component: Tesekkurler, meta: { showNavigation: false, girisYapildi: false } },
    { path: '/kimler', name: 'kimler', component: Kimler, meta: { showNavigation: true, girisYapildi: false } },
    { path: '/odeme', name: 'odeme', component: Odeme, meta: { showNavigation: true, girisYapildi: true } },
    { path: '/giris-yap', name: 'giris-yap', component: GirisYap, meta: { showNavigation: true, girisYapildi: false } },
    { path: '/sepet', name: 'sepet', component: Sepet, meta: { showNavigation: true, girisYapildi: false } },
    { path: '/odeme-sonuc', name: 'odeme-sonuc', component: OdemeSonuc, meta: { showNavigation: true, girisYapildi: true } },
    { path: '/biletlerim', name: 'biletlerim', component: Biletlerim, meta: { showNavigation: true, girisYapildi: true } },
    { path: '/kayit-ol', name: 'kayit-ol', component: KayitOl, meta: { showNavigation: true, girisYapildi: false } },
    { path: '/profil-bilgilerim', name: 'profil-bilgilerim', component: ProfilBilgilerim, meta: { showNavigation: true, girisYapildi: true } },
    { path: '/fatura-bilgileri', name: 'fatura-bilgileri', component: FaturaBilgileri, meta: { showNavigation: true, girisYapildi: true } },
    { path: '/sepetim', name: 'sepetim', component: Sepetim, meta: { showNavigation: true, girisYapildi: false } },
    { path: '/bilet-sorgulama', name: 'bilet-sorgulama', component: BiletSorgulama, meta: { showNavigation: true, girisYapildi: false } },
    { path: '/bilet-bilgileri', name: 'bilet-bilgileri', component: BiletBilgileri, meta: { showNavigation: true, girisYapildi: false }, props: true },
]

export const router = new VueRouter({
    mode: 'history',
    routes
})