import { render, staticRenderFns } from "./Paketler.vue?vue&type=template&id=b6666ddc&scoped=true&"
import script from "./Paketler.vue?vue&type=script&lang=js&"
export * from "./Paketler.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6666ddc",
  null
  
)

export default component.exports