<template>
  <div>
    <div class="colUclu" v-for="(item, index) in where" :key="index">
      <a
        class="colCheck"
        @click="biletClass(item)"
        :class="{ disable: item.aktifMi === false }"
        :style="{
          'background-image': 'url(' + item.image + ')',
          transform: 'scale(1)',
        }"
      >
        <input
          type="text"
          class="gorunmezForm"
          name="konum"
          v-model="konum"
          @value="item.name"
        />
        <div class="ortala pointer">
          <h2 class="wow fadeInUp" data-wow-delay="0.3s">{{ item.name }}</h2>
          <span v-if="!item.aktifMi">YAKINDA</span>
          <span v-else class="ucusaHazir">UÇUŞA HAZIR</span>
          <svg
            width="22px"
            height="15px"
            v-if="item.aktifMi"
            viewBox="0 0 22 15"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              <linearGradient
                x1="23.2439118%"
                y1="-35.1681991%"
                x2="50%"
                y2="78.5193432%"
                id="linearGradient-1"
              >
                <stop stop-color="#70E4FD" offset="0%"></stop>
                <stop stop-color="#0066DC" offset="100%"></stop>
              </linearGradient>
              <linearGradient
                x1="23.2439118%"
                y1="-36.7599013%"
                x2="50%"
                y2="79.0523391%"
                id="linearGradient-2"
              >
                <stop stop-color="#70E4FD" offset="0%"></stop>
                <stop stop-color="#0066DC" offset="100%"></stop>
              </linearGradient>
            </defs>
            <g
              id="Güzel"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="2-Bilet-Satın-Al"
                transform="translate(-1522.000000, -375.000000)"
                fill="url(#linearGradient-2)"
              >
                <g id="Group-3" transform="translate(1403.000000, 144.000000)">
                  <g
                    id="icn-right"
                    transform="translate(119.000000, 231.000000)"
                  >
                    <path
                      d="M21.3847581,6.82072581 L14.9331452,0.369112903 C14.5395161,-0.024516129 13.901129,-0.024516129 13.5075,0.369112903 C13.1137903,0.762822581 13.1137903,1.40104839 13.5075,1.79475806 L18.2382258,6.52548387 L1.00806452,6.52548387 C0.451370968,6.52548387 0,6.97685484 0,7.53354839 C0,8.09016129 0.451370968,8.5416129 1.00806452,8.5416129 L18.2382258,8.5416129 L13.5076613,13.2723387 C13.1139516,13.6660484 13.1139516,14.3042742 13.5076613,14.6979839 C13.7044355,14.8946774 13.9625,14.9932258 14.2204839,14.9932258 C14.4784677,14.9932258 14.7364516,14.8946774 14.9333065,14.6979839 L21.3847581,8.24637097 C21.7784677,7.85266129 21.7784677,7.21443548 21.3847581,6.82072581 Z"
                      id="Path"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'neredeData',
  data() {
    return {
      konum: null,
    }
  },
  // created(){},
  // mounted() {},
  methods: {


    biletClass(branch) {
      if (branch.aktifMi) {
        this.tickets = JSON.parse(window.localStorage.getItem('sncTickets'))        
        
        this.dataMustBeShown(branch);

/*
        const data = {territoryId: branch.id}
        axios({
           url: `${process.env.VUE_APP_ENV_SERVER}/Services/FlyzoneCrm/B2CServices/GetContentB2C`,
          data: data,
          method: 'post',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Cache-Control': 'no-cache',},
        }).then((response2) => {

          localStorage.setItem('tickets',JSON.stringify(response2.data.ticketProducts))
          localStorage.setItem('products',JSON.stringify(response2.data.gitfProducts))
          localStorage.setItem('content', JSON.stringify(response2.data))
          if(this.$root.bulkTicket != null) {
            const selectedCampaign = response2.data.campaigns.find(x => x.CampaignId == this.$root.bulkTicket.CampaignId)
            localStorage.setItem('campaign', JSON.stringify(selectedCampaign))
          }
          window.localStorage.setItem('konum', branch.name)
          window.localStorage.setItem('adres', branch.adres)
          window.localStorage.setItem('subeTelefon', branch.telefon)
          window.localStorage.setItem('konumId', branch.id)
          this.$router.push('/paketler')
        })
*/

      }
    },

    dataMustBeShown(branch){
      var thisComponent = this;

      const data = {territoryId: branch.id}
      
      $.ajax({
        url: `${process.env.VUE_APP_ENV_SERVER}/Services/FlyzoneCrm/B2CServices/GetContentB2C`,
        method:"post", 
        data: data,
        contentType: 'application/x-www-form-urlencoded', 
        dataType: 'json' ,
        success:
          function(response){
            thisComponent.showMustGoOn(branch, response)
        }});


    },

    showMustGoOn(branch, response) {

          localStorage.setItem('tickets',JSON.stringify(response.ticketProducts))
          localStorage.setItem('products',JSON.stringify(response.gitfProducts))

          let extremeCampaigns = [];
          if(response.campaigns != null){
              extremeCampaigns = response.campaigns.filter(x => x.CampaignTypeId == 2)
              localStorage.setItem('extremeTickets', JSON.stringify(extremeCampaigns))

          }
          localStorage.setItem('content', JSON.stringify(response))
          if(this.$root.bulkTicket != null) {
            const selectedCampaign = response.campaigns.find(x => x.CampaignId == this.$root.bulkTicket.CampaignId)
            localStorage.setItem('campaign', JSON.stringify(selectedCampaign))
          }
          window.localStorage.setItem('konum', branch.name)
          window.localStorage.setItem('adres', branch.adres)
          window.localStorage.setItem('subeTelefon', branch.telefon)
          window.localStorage.setItem('konumId', branch.id)
          this.$router.push('/paketler')

    },



  },
  props: {
    where: {
      type: Array,
      required: true,
    },
  },
}
</script>
