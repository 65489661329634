<template>
  <div>
    <div class="zamanBg">
      <div class="container">
        <Sepetim v-if="$root.tabName === 'sepetim'"></Sepetim>
        <FaturaForm ref="childref" v-if="$root.tabName === 'odeme1'"></FaturaForm>
        <KartOdeme v-if="$root.tabName === 'odeme2'"></KartOdeme>

        <div class="sepetSidebar">
          <Sepet v-if="$root.tabName != 'sepetim'"></Sepet>
          <div class="butonlar" v-if="$root.tabName === 'odeme1'">
            <a @click="sepetim"><span>GERİ</span></a>
            <a @click="ileri"><span>İLERİ</span></a>
          </div>
          <div class="butonlar d-contents odeme-custom-butonlar" v-if="$root.tabName === 'odeme2'" style="margin: 22px 0">
            <a @click="$root.tabDegistir('odeme1')">
              <span>GERİ</span>
            </a>
          </div>
        </div>
      </div>
      <div class="butonlar" v-if="$root.tabName === 'sepetim'">
        <router-link to="/kimler" v-if="!$root.sepetBos"><span>GERİ</span></router-link>
        <a @click="fatura" class="odemeEkrani" v-if="!$root.sepetBos"><span>DEVAM ET</span></a>
        <router-link to="/paketler" class="odemeEkrani" v-if="$root.sepetBos"><span>BİLETİNİ AL</span></router-link>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import HomeHeader from './HomeHeader'
import Footer from './Footer'
import Sepetim from './Sepetim'
import FaturaForm from './FaturaForm'
import KartOdeme from './KartOdeme'
import Sepet from './Sepet'

export default {
  name: 'Odeme',
  data() {
    return {}
  },
  created() {
      (this.$root.menuActive1 = false),
      (this.$root.menuLink1 = true),
      (this.$root.menuActive2 = false),
      (this.$root.menuLink2 = true),
      (this.$root.menuActive3 = false),
      (this.$root.menuLink3 = true),
      (this.$root.menuActive4 = false),
      (this.$root.menuLink4 = true),
      (this.$root.menuActive5 = false),
      (this.$root.menuLink5 = true),
      (this.$root.menuActive6 = true)

    if (
      localStorage.yetiskinSayisi === undefined ||
      localStorage.cocukSayisi === undefined
    ) {
      this.$router.push('/gelenler')
    } else if (
      localStorage.yetiskinSayisi < 1 &&
      localStorage.cocukSayisi < 1
    ) {
      this.$router.push('/gelenler')
    }
  },
  mounted() {
    this.$root.tabName = 'sepetim'
  },
  methods: {
    ileri() {
      this.$root.$refs.childref.checkrequireds()
      console.log('this.$root.tamam', this.$root.tamam)

      if (this.$root.tamam === true) {
        this.$root.tabDegistir('odeme2')
      } else {
        this.$snotify.error(
          // 'Ad soyad, Telefon, E-posta alanları zorunludur',
          'Tüm alanların doldurulması zorunludur',
          'Hata',
          {
            timeout: 2000,
            showProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
          } 
        )
      }
    },
    fatura() {
      this.$root.tabDegistir('odeme1')
    },
    sepetim() {
      this.$root.tabDegistir('sepetim')
    },
  },
  components: {
    HomeHeader,
    Sepetim,
    FaturaForm,
    KartOdeme,
    Sepet,
    Footer,
  },
}
</script>

<style scoped>
@media only screen and (max-width: 479px) {
  .main {
    min-height: unset !important;
  }
}
</style>
