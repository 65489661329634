import Vue from 'vue'
import App from './App.vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'

import VueRouter from 'vue-router'
Vue.use(VueRouter);

// import VueRouterBackButton from 'vue-router-back-button'
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask);

import VueLocalStorage from 'vue-localstorage'
Vue.use(VueLocalStorage);

import FBSignInButton from 'vue-facebook-signin-button'
Vue.use(FBSignInButton)

import { router } from './router/router.js' 

import store from './store/index.js'


// Vue.use(VueRouterBackButton, { router })
Vue.use(VueAwesomeSwiper);

import Snotify from 'vue-snotify';
Vue.use(Snotify);

import 'vue-snotify/styles/dark.css';
import '../public/css/jquery.fancybox.min.css'
import '../public/css/style.css'
import '../public/css/media-queries.css'
import '../public/css/animate.css'



new Vue({
  el: '#app',
  render: h => h(App),
  store,
  router,
  ...App
})
