<template>
  <div>
    <div class="sepetListe">
      <div class="biletTabloContent">
        <div class="sepetimTabloBaslik" v-if="!$root.sepetBos">
          <div class="sepetimCol">Paket / Ürün</div>
          <div class="sepetimCol">Kişi / Adet</div>
          <div class="sepetimCol">Ücret</div>
          <div class="sepetimCol">Sil</div>
        </div>
        <div class="sepetimContent">
          <div class="kimlerAciklama" v-if="$root.sepetBos">
            <img src="images/loading-logo.png" alt="" />
            <span
              >Sepetiniz boş!<br />
              Lütfen uçuş için size uygun paketi seçerek bilet
              oluşturunuz.</span
            >
          </div>
          <div class="sepetimTabloRow" v-if="$root.personCount != 0">
            <div class="sepetimCol">
              <div>
                <span class="xSayi" v-text="$root.personCount"></span> &nbsp;x&nbsp;
                <span v-if="$root.ticketType === 1" class="carpan" style="top: 0" v-text="$root.dakika"></span
                ><span v-if="$root.ticketType === 5" class="carpan" style="top: 0" v-text="$root.kullanimSayisi"></span
                >
                '&nbsp;<span v-if="$root.ticketType === 1" class="carpan" style="top: 0">dk</span
                ><span v-if="$root.ticketType === 5" class="carpan" style="top: 0" >Kul.</span
                >&nbsp;
                <span v-if="$root.ticketType === 10" class="carpan" v-text="this.extremeName + ' (Yetişkin)'"></span>
                <span v-if="$root.ticketType !== 10" class="carpan" v-text="$root.name + ' (Yetişkin)'"></span>
              </div>
              <div class="mobilFiyat">
                Birim Fiyatı:
                <span
                  class="onFiyat" v-if="$root.ticketType !== 10"
                  v-text="calculateDiscount($root.yetiskinToplamTutar) + ' ₺'"
                ></span>
              <span
                class="onFiyat" v-if="$root.ticketType === 10"
                v-text="
                  calculateDiscountExtreme($root.personCount) + ' ₺'
                "
              ></span>
              </div>
            </div>
            <div class="sepetimCol">
              <span
                v-if="!$root.bulkTicket"
                class="sayacBtn"
                @click="yetiskinMinus()"
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="Güzel"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g id="icn-minus" fill="#000000" fill-rule="nonzero">
                      <path
                        d="M23.0820404,12.7985948 L0.899297424,12.7985948 C0.402883504,12.7985948 0,12.3957113 0,11.8992974 C0,11.4028835 0.402883504,11 0.899297424,11 L23.0820404,11 C23.5784543,11 23.9813378,11.4028835 23.9813378,11.8992974 C23.9813378,12.3957113 23.5784543,12.7985948 23.0820404,12.7985948 Z"
                        id="Path"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>
              <span v-text="$root.personCount"></span>
              <span
                v-if="!$root.bulkTicket"
                class="sayacBtn"
                @click="yetiskinPlus()"
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="Güzel"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g id="icn-plus" fill="#000000" fill-rule="nonzero">
                      <g id="plus">
                        <path
                          d="M23.1000027,13 L0.899997253,13 C0.403197026,13 0,12.5520019 0,12 C0,11.4479981 0.403197026,11 0.899997253,11 L23.1000027,11 C23.596803,11 24,11.4479981 24,12 C24,12.5520019 23.596803,13 23.1000027,13 Z"
                          id="Path"
                        ></path>
                        <path
                          d="M12,24 C11.4479981,24 11,23.596803 11,23.1000027 L11,0.899997253 C11,0.403197026 11.4479981,0 12,0 C12.5520019,0 13,0.403197026 13,0.899997253 L13,23.1000027 C13,23.596803 12.5520019,24 12,24 Z"
                          id="Path"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
            </div>
            <div class="sepetimCol">
              <span
                class="onFiyat"  v-if="$root.ticketType !== 10"
                v-text="
                  calculateDiscount($root.personCount * $root.ucret) + ' ₺'
                "
              ></span>
              <span
                class="onFiyat" v-if="$root.ticketType === 10"
                v-text="
                  calculateDiscountExtreme($root.personCount) + ' ₺'
                "
              ></span>
            </div>
            <div class="sepetimCol">
              <div class="sil" @click="yetiskinSil()">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="Güzel"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g id="icn-delete" fill="#000000" fill-rule="nonzero">
                      <g id="close-(1)">
                        <path
                          d="M14.114,11.993 L23.546,2.561 C24.132,1.9755 24.132,1.0255 23.546,0.44 C22.96,-0.146 22.011,-0.146 21.425,0.44 L11.993,9.872 L2.5605,0.44 C1.9745,-0.146 1.0255,-0.146 0.4395,0.44 C-0.1465,1.0255 -0.1465,1.9755 0.4395,2.561 L9.872,11.993 L0.4395,21.425 C-0.1465,22.0105 -0.1465,22.9605 0.4395,23.546 C0.7325,23.8385 1.1165,23.985 1.5,23.985 C1.8835,23.985 2.2675,23.8385 2.5605,23.5455 L11.993,14.1135 L21.425,23.5455 C21.718,23.8385 22.102,23.985 22.4855,23.985 C22.869,23.985 23.253,23.8385 23.546,23.5455 C24.132,22.96 24.132,22.01 23.546,21.4245 L14.114,11.993 Z"
                          id="Path"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <div class="sepetimTabloRow" v-if="$root.childCount != 0">
            <div class="sepetimCol">
              <div>
                <span class="xSayi" v-text="$root.childCount"></span> &nbsp;x&nbsp;
                <span v-if="$root.ticketType === 1" class="carpan" style="top: 0" v-text="$root.dakika"></span
                ><span v-if="$root.ticketType === 5" class="carpan" style="top: 0" v-text="$root.kullanimSayisi"></span
                >
                '&nbsp;<span v-if="$root.ticketType === 1" class="carpan" style="top: 0">dk</span
                ><span v-if="$root.ticketType === 5" class="carpan" style="top: 0" >Kul.</span
                >&nbsp;
                <span v-if="$root.ticketType === 10" class="carpan" v-text="this.extremeName + ' (Çocuk)'"></span>
                <span v-if="$root.ticketType !== 10" class="carpan" v-text="$root.name + ' (Çocuk)'"></span>

              </div>
              <div class="mobilFiyat">
                Birim Fiyatı:
                <span
                  class="onFiyat" v-if="$root.ticketType !== 10"
                  v-text="calculateDiscount($root.cocukToplamTutar) + ' ₺'"
                ></span>
              <span
                class="onFiyat" v-if="$root.ticketType === 10"
                v-text="
                  calculateDiscountExtreme($root.childCount) + ' ₺'
                "
              ></span>
              </div>
            </div>
            <div class="sepetimCol">
              <span
                v-if="!$root.bulkTicket"
                class="sayacBtn"
                @click="cocukMinus()"
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="Güzel"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g id="icn-minus" fill="#000000" fill-rule="nonzero">
                      <path
                        d="M23.0820404,12.7985948 L0.899297424,12.7985948 C0.402883504,12.7985948 0,12.3957113 0,11.8992974 C0,11.4028835 0.402883504,11 0.899297424,11 L23.0820404,11 C23.5784543,11 23.9813378,11.4028835 23.9813378,11.8992974 C23.9813378,12.3957113 23.5784543,12.7985948 23.0820404,12.7985948 Z"
                        id="Path"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>
              <span v-text="$root.childCount"></span>
              <span
                v-if="!$root.bulkTicket"
                class="sayacBtn"
                @click="cocukPlus()"
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="Güzel"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g id="icn-plus" fill="#000000" fill-rule="nonzero">
                      <g id="plus">
                        <path
                          d="M23.1000027,13 L0.899997253,13 C0.403197026,13 0,12.5520019 0,12 C0,11.4479981 0.403197026,11 0.899997253,11 L23.1000027,11 C23.596803,11 24,11.4479981 24,12 C24,12.5520019 23.596803,13 23.1000027,13 Z"
                          id="Path"
                        ></path>
                        <path
                          d="M12,24 C11.4479981,24 11,23.596803 11,23.1000027 L11,0.899997253 C11,0.403197026 11.4479981,0 12,0 C12.5520019,0 13,0.403197026 13,0.899997253 L13,23.1000027 C13,23.596803 12.5520019,24 12,24 Z"
                          id="Path"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
            </div>
            <div class="sepetimCol">
              <span
                class="onFiyat" v-if="$root.ticketType !== 10"
                v-text="
                  calculateDiscount($root.childCount * $root.ucret) + ' ₺'
                "
              ></span>
              <span
                class="onFiyat" v-if="$root.ticketType === 10"
                v-text="
                  calculateDiscountExtreme($root.childCount) + ' ₺'
                "
              ></span>
              
            </div>
            <div class="sepetimCol">
              <div class="sil" @click="cocukSil()">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="Güzel"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g id="icn-delete" fill="#000000" fill-rule="nonzero">
                      <g id="close-(1)">
                        <path
                          d="M14.114,11.993 L23.546,2.561 C24.132,1.9755 24.132,1.0255 23.546,0.44 C22.96,-0.146 22.011,-0.146 21.425,0.44 L11.993,9.872 L2.5605,0.44 C1.9745,-0.146 1.0255,-0.146 0.4395,0.44 C-0.1465,1.0255 -0.1465,1.9755 0.4395,2.561 L9.872,11.993 L0.4395,21.425 C-0.1465,22.0105 -0.1465,22.9605 0.4395,23.546 C0.7325,23.8385 1.1165,23.985 1.5,23.985 C1.8835,23.985 2.2675,23.8385 2.5605,23.5455 L11.993,14.1135 L21.425,23.5455 C21.718,23.8385 22.102,23.985 22.4855,23.985 C22.869,23.985 23.253,23.8385 23.546,23.5455 C24.132,22.96 24.132,22.01 23.546,21.4245 L14.114,11.993 Z"
                          id="Path"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>

          <div
            class="sepetimTabloRow"
            v-for="(item, index) in urunler"
            :key="index"
          >
            <div class="sepetimCol">
              <div>
                <span class="xSayi" v-text="item.Quantity"></span> x
                <span class="carpan" style="top: 0">{{
                  item.ProductName + " (" + item.BodySize + ")"
                }}</span>
              </div>
              <div class="mobilFiyat">
                Birim Fiyatı:
                <span
                  class="onFiyat"
                  v-text="calculateDiscount(item.Price * item.Quantity) + ' ₺'"
                ></span>
              </div>
            </div>
            <div class="sepetimCol">
              <span
                v-if="!$root.bulkTicket"
                class="sayacBtn"
                @click="corapMinus(index)"
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="Güzel"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g id="icn-minus" fill="#000000" fill-rule="nonzero">
                      <path
                        d="M23.0820404,12.7985948 L0.899297424,12.7985948 C0.402883504,12.7985948 0,12.3957113 0,11.8992974 C0,11.4028835 0.402883504,11 0.899297424,11 L23.0820404,11 C23.5784543,11 23.9813378,11.4028835 23.9813378,11.8992974 C23.9813378,12.3957113 23.5784543,12.7985948 23.0820404,12.7985948 Z"
                        id="Path"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>
              <span v-text="item.Quantity"></span>
              <span
                v-if="!$root.bulkTicket"
                class="sayacBtn"
                @click="corapPlus(index)"
              >
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="Güzel"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g id="icn-plus" fill="#000000" fill-rule="nonzero">
                      <g id="plus">
                        <path
                          d="M23.1000027,13 L0.899997253,13 C0.403197026,13 0,12.5520019 0,12 C0,11.4479981 0.403197026,11 0.899997253,11 L23.1000027,11 C23.596803,11 24,11.4479981 24,12 C24,12.5520019 23.596803,13 23.1000027,13 Z"
                          id="Path"
                        ></path>
                        <path
                          d="M12,24 C11.4479981,24 11,23.596803 11,23.1000027 L11,0.899997253 C11,0.403197026 11.4479981,0 12,0 C12.5520019,0 13,0.403197026 13,0.899997253 L13,23.1000027 C13,23.596803 12.5520019,24 12,24 Z"
                          id="Path"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
            </div>
            <div class="sepetimCol">
              <span
                class="onFiyat"
                v-text="calculateDiscount(item.Price * item.Quantity) + ' ₺'"
              ></span>
            </div>
            <div class="sepetimCol">
              <div class="sil" @click="deleteCorap(index)">
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="Güzel"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g id="icn-delete" fill="#000000" fill-rule="nonzero">
                      <g id="close-(1)">
                        <path
                          d="M14.114,11.993 L23.546,2.561 C24.132,1.9755 24.132,1.0255 23.546,0.44 C22.96,-0.146 22.011,-0.146 21.425,0.44 L11.993,9.872 L2.5605,0.44 C1.9745,-0.146 1.0255,-0.146 0.4395,0.44 C-0.1465,1.0255 -0.1465,1.9755 0.4395,2.561 L9.872,11.993 L0.4395,21.425 C-0.1465,22.0105 -0.1465,22.9605 0.4395,23.546 C0.7325,23.8385 1.1165,23.985 1.5,23.985 C1.8835,23.985 2.2675,23.8385 2.5605,23.5455 L11.993,14.1135 L21.425,23.5455 C21.718,23.8385 22.102,23.985 22.4855,23.985 C22.869,23.985 23.253,23.8385 23.546,23.5455 C24.132,22.96 24.132,22.01 23.546,21.4245 L14.114,11.993 Z"
                          id="Path"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobilSepet">
        <Sepet></Sepet>
      </div>
    </div>
    <div class="desktopSepet">
      <Sepet></Sepet>
    </div>
  </div>
</template>

<script>
import HomeHeader from "./HomeHeader";
import Footer from "./Footer";
import Sepet from "./Sepet";
export default {
  name: "Sepetim",
  data() {
    return {
      isActive: false,
      corapToplamSayi: 0,
      urunler: [],
      extremeName:null
    };
  },
  created() {
    var itemid = this.$root.itemid;
    this.$root.toplamSayi =
      parseInt(this.$root.personCount) + parseInt(this.$root.childCount);
    this.urunler = this.$root.urunler;
    console.log("urunler", this.urunler);
        if(this.$root.ticketType === 10){
            let camp = JSON.parse(localStorage.seciliKampanya);
            this.CampaignDetailList=camp.CampaignDetailList
            this.extremeName = camp.Name;
        }
  },
  mounted() {
    this.$root.sepetAraToplam = parseFloat(
      parseFloat(this.$root.yetiskinToplamTutar) +
        parseFloat(this.$root.cocukToplamTutar) +
        parseFloat(this.$root.corapSepetToplam)
    ).toFixed(2);
    this.urunler = this.$root.urunler;
    this.$root.sepetToplam = parseFloat(this.$root.sepetAraToplam).toFixed(2);
  },
  methods: {
    calculateDiscount(total) {
       let finalPrice =  Number(total) - Math.round((Number(total) * 0.1) )
        if (this.$root.bulkTicket != null) {
          const campaign = JSON.parse(window.localStorage.getItem("campaign"));
          finalPrice = Number( campaign.CampaignDetailList[0].DiscountedPrice);
        }
        return finalPrice;
    },
        calculateDiscountExtreme(quant) {
            let finalPrice=0
            if(this.$root.ticketType === 10)
            {
                //finalPrice=total;
                const campaign = JSON.parse(window.localStorage.getItem('seciliKampanya'))
                for (let i = 0; i < campaign.CampaignDetailList.length; i++) {
                    finalPrice += campaign.CampaignDetailList[i].DiscountedPrice
                }
            }
            
            return finalPrice*quant
        },
        calculateCartTotalExtreme() {
            let finalPrice=0
            let uruntoplam=0
            if(this.$root.ticketType === 10)
            {
                //finalPrice=total;
                const campaign = JSON.parse(window.localStorage.getItem('seciliKampanya'))
                for (let i = 0; i < campaign.CampaignDetailList.length; i++) {
                    finalPrice += campaign.CampaignDetailList[i].DiscountedPrice
                }
            }
            
            var seciliurunler=JSON.parse(localStorage.RestrictionProducts||'[]')
            for(var i=0;i<seciliurunler.length;i++){
                uruntoplam +=this.calculateDiscount(seciliurunler[i].Quantity*seciliurunler[i].Price)
            }
            finalPrice = finalPrice  *(parseInt(localStorage.yetiskinSayisi||0)+parseInt(localStorage.cocukSayisi||0))
            
            return finalPrice+uruntoplam
        }
    ,
    yetiskinMinus() {
      if (this.$root.personCount > 0) {
        this.$root.personCount = this.$root.personCount - 1;
        this.$root.yetiskinToplamTutar =
          this.$root.personCount * this.$root.ucret;
        this.corapHesapla();
      }
    },
    yetiskinPlus() {
      this.$root.personCount = parseInt(this.$root.personCount) + 1;
      this.$root.yetiskinToplamTutar =
        this.$root.personCount * this.$root.ucret;
      this.corapHesapla();
    },
    cocukMinus() {
      if (this.$root.childCount > 0) {
        this.$root.childCount = parseInt(this.$root.childCount) - 1;
        this.$root.cocukToplamTutar = this.$root.childCount * this.$root.ucret;
        this.corapHesapla();
      }
    },
    cocukPlus() {
      this.$root.childCount = parseInt(this.$root.childCount) + 1;
      this.$root.cocukToplamTutar = this.$root.childCount * this.$root.ucret;
      this.corapHesapla();
    },

    corapPlus(i) {
      if (this.$root.toplamSayi === 0) {
        return false;
      } else {
        this.urunler[i].Quantity++;
        this.corapToplamSayi++;
        this.$root.toplamSayi--;
        this.$root.silikonluCorapSayi++;
      }
      this.$root.corapUcret = this.urunler[i].Price;

      this.corapHesapla();
      this.$root.urunler = JSON.parse(localStorage.RestrictionProducts);
    },
    corapMinus(i) {
      if (this.urunler[i].Quantity === 0) {
        return false;
      } else {
        this.urunler[i].Quantity--;
        this.corapToplamSayi--;
        this.$root.toplamSayi++;
        this.$root.silikonluCorapSayi--;
      }
      this.$root.corapUcret = this.urunler[i].Price;
      this.corapHesapla();
      this.$root.urunler = JSON.parse(localStorage.RestrictionProducts);
    },
    corapHesapla() {
      this.urunler = this.urunler.filter(function (x) {
        if (x.Quantity > 0) {
          return x;
        }
      });
      localStorage.RestrictionProducts = JSON.stringify(this.urunler);
      this.$root.corapSepetToplam = 0;
      for (var i = 0; i < this.urunler.length; i++) {
        this.$root.corapSepetToplam +=
          this.urunler[i].Quantity * this.urunler[i].Price;
      }

      this.$root.sepetAraToplam = parseFloat(
        parseFloat(this.$root.yetiskinToplamTutar) +
          parseFloat(this.$root.cocukToplamTutar) +
          parseFloat(this.$root.corapSepetToplam)
      ).toFixed(2);
      // this.$root.kdvHesapla = parseFloat(parseFloat(this.$root.sepetAraToplam) * parseFloat(this.$root.kdv)).toFixed(2)
      this.$root.sepetToplam = parseFloat(this.$root.sepetAraToplam).toFixed(2);
      if (
        this.$root.childCount == 0 &&
        this.$root.personCount == 0 &&
        this.urunler.length == 0
      ) {
        this.$root.sepetBos = true;
      }
    },
    yetiskinSil() {
      localStorage.yetiskinSayisi = 0;
      this.$root.personCount = localStorage.yetiskinSayisi;
      this.$root.yetiskinToplamTutar =
        this.$root.personCount * this.$root.ucret;
      this.corapHesapla();
    },
    cocukSil() {
      localStorage.cocukSayisi = 0;
      this.$root.childCount = localStorage.cocukSayisi;
      this.$root.cocukToplamTutar = this.$root.childCount * this.$root.ucret;
      this.corapHesapla();
    },
    deleteCorap: function (index) {
      this.urunler.splice(index, 1);
      localStorage.RestrictionProducts = JSON.stringify(this.urunler);
      this.$root.urunler = JSON.parse(localStorage.RestrictionProducts);
      this.corapHesapla();
    },
  },

  components: {
    HomeHeader,
    Sepet,
    Footer,
  },
};
</script>
