<template>
<div class="gelenlerForm gelenlerForm2" style="overflow-y: auto;">
    <h1>FATURA & İLETİŞİM BİLGİLERİ</h1>
    <div class="kimlerAciklama">
        <img src="images/info.png" alt="">
        <span>Lütfen <strong>Ad, Soyad, E-mail ve Telefon</strong> bilgilerinizi doldurunuz. Aksi taktirde ödeme işlemi gerçekleşmeyecektir.</span>
    </div>
    <div class="faturaRow">
        <div class="row">
        <div class="form-group">
        <div class="select-box">
            <div class="select-box__current" tabindex="1">
                <div class="select-box__value">
                <input class="select-box__input" @change="faturaSec('Bireysel Fatura')" type="radio" id="f2" value="Bireysel Fatura" name="Fatura" />
                <p class="select-box__input-text">Bireysel Fatura</p>
                </div>
                <div class="select-box__value">
                <input class="select-box__input" @change="faturaSec('Kurumsal Fatura')" type="radio" id="f1" value="Kurumsal Fatura" name="Fatura" />
                <p class="select-box__input-text">Kurumsal Fatura</p>
                </div>
                
                <div class="select-box__value">
                <input class="select-box__input" @change="faturaSec('Fatura İstemiyorum')" type="radio" id="f0" value="0" name="Fatura"  checked="checked"/>
                <p class="select-box__input-text">Fatura İstemiyorum</p>
                </div>
                
                <img class="select-box__icon" src="images/icn-down.png" alt="Arrow Icon" aria-hidden="true"/>
            </div>
            <ul class="select-box__list">
                <li><label class="select-box__option" for="f0" aria-hidden="aria-hidden" @click="faturaYok">Fatura İstemiyorum</label></li>
                <li><label class="select-box__option" for="f2" aria-hidden="aria-hidden" @click="bireysel">Bireysel Fatura</label></li>
                <li><label class="select-box__option" for="f1" aria-hidden="aria-hidden" @click="kurumsal">Kurumsal Fatura</label></li>
                
            </ul>
        </div>
        </div>
  
        </div>
        <div class="row" >
            <div class="form-group yarimText">
                <input type="text" name="Ad" id="Ad" required="required" autocomplete="off" v-model="ad" @change="adKayit(ad)" :class="{'error': this.errorAd}" @focus="errorSilAd()"/>
                <label for="input" class="control-label">Ad</label><i class="bar"></i>
            </div>
            <div class="form-group yarimText">
                <input type="text" name="Soyad" id="Soyad" required="required" autocomplete="off" v-model="soyad" @change="soyadKayit(soyad)" :class="{'error': this.errorSoyad}" @focus="errorSilSoyad()"/>
                <label for="input" class="control-label">Soyad</label><i class="bar"></i>
            </div>
        </div>
        <div class="row" >
            <div class="form-group yarimText">
                <input type="email" name="Email" required="required" autocomplete="off" v-model="email" @change="emailKayit(email)" :class="{'error': this.errorMail}" @focus="errorSilMail()"/>
                <label for="input" class="control-label">Email</label><i class="bar"></i>
            </div>
            <div class="form-group yarimText">
                <input type="text" name="Telefon" v-mask="'+90 (###) ### ## ##'" required="required" autocomplete="off" v-model="telefon" @change="telefonKayit(telefon)" :class="{'error': this.errorTelefon}"/>
                <label for="input" class="control-label">Telefon</label><i class="bar"></i>
            </div>
        </div>
    
        <div class="row" v-if="detailVisable && tcGoster">
            <div class="form-group">
                <input type="text" name="TcNo" required="required" v-mask="'###########'" v-model="tcNo" @change="tcNoKayit(tcNo)" autocomplete="off" @focus="errorSilTcNo()"/>
                <label for="input" class="control-label">TC Numarası</label><i class="bar"></i>
            </div>
            <div class="form-radio tcRadio">
                <div class="radio">
                    <label>
                    <input id="notTC" type="checkbox" name="radio" @change="turkDegil('TC Vatandaşı Değilim')" /><i class="helper"></i>TC Vatandaşı Değilim
                    </label>
                </div>
            </div>
        </div>
        <div class="row" v-if="detailVisable && kurumsalMi">
            <div class="form-group yarimText">
                <input type="text" name="VergiNo" required="required" v-mask="'##########'" v-model="vergiNo" @change="vergiNoKayit(vergiNo)" autocomplete="off"/>
                <label for="input" class="control-label">Vergi Numarası</label><i class="bar"></i>
            </div>
            <div class="form-group yarimText">
                <input type="text" name="Unvan" required="required" v-model="unvan" @change="unvanKayit(unvan)" autocomplete="off"/>
                <label for="input" class="control-label">Ünvan</label><i class="bar"></i>
            </div>
        </div>
        <div class="row" v-if="detailVisable && kurumsalMi">
            <div class="form-group">
                <input type="text" name="VergiDairesi" required="required" v-model="vergiDaire" @change="vergiDaireKayit(vergiDaire)" autocomplete="off"/>
                <label for="input" class="control-label">Vergi Dairesi</label><i class="bar"></i>
            </div>
            
        </div>
        
        <div class="row" v-if="detailVisable">
            <div class="form-group yarimText">
                
        <div class="select-box">
            <div class="select-box__current" tabindex="1">
                
                <div class="select-box__value">
                <input class="select-box__input" @change="cinsiyetSec('1')" type="radio" value="1" id="c2" name="Cinsiyet" :checked="this.cinsiyet === '1'"/>
                <p class="select-box__input-text">Erkek</p>
                </div>
                <div class="select-box__value">
                <input class="select-box__input" @change="cinsiyetSec('2')" type="radio" value="2" id="c1" name="Cinsiyet" :checked="this.cinsiyet === '2'"/>
                <p class="select-box__input-text">Kadın</p>
                </div>
                
                <div class="select-box__value">
                <input class="select-box__input" @change="cinsiyetSec('0')" type="radio" id="c0" value="0" name="Cinsiyet"  :checked="this.cinsiyet === '0'"/>
                <p class="select-box__input-text">Cinsiyet Seçiniz</p>
                </div>
                
                <img class="select-box__icon" src="images/icn-down.png" alt="Arrow Icon" aria-hidden="true"/>
            </div>
            <ul class="select-box__list">
                <li><label class="select-box__option" for="c0" aria-hidden="aria-hidden">Cinsiyet</label></li>
                <li><label class="select-box__option" for="c2" aria-hidden="aria-hidden">Erkek</label></li>
                <li><label class="select-box__option" for="c1" aria-hidden="aria-hidden">Kadın</label></li>
            </ul>
        </div>
            </div>
            <div class="form-group yarimText" v-if="!kurumsalMi">
                <input type="text" name="DogumTarihi" v-mask="'##.##.####'" required="required" autocomplete="off" v-model="dogumTarihi" @change="dogumTarihiKayit(dogumTarihi)" @focus="errorSilDogumTarihi()"/>
                <label for="input" class="control-label">Doğum Tarihi</label><i class="bar"></i>
            </div>
        </div>
        <div class="row" v-if="detailVisable">
            <div class="form-group">
                <input type="text" name="Adres" required="required" autocomplete="off" v-model="faturaAdres" @change="adresKayit(faturaAdres)"/>
                <label for="input" class="control-label">Adres</label><i class="bar"></i>
            </div>
        </div>
        <div class="row" v-if="detailVisable">
            <div class="iller yarimText">
                <div class="select-box">
                    <div class="select-box__current" tabindex="2">
                        <template v-for="(item, index) in iller" >
                        <div class="select-box__value" :key="index+'il'">
                            <input class="select-box__input" type="radio" :id="item.plaka" @change="ilKayit(item.il, item.plaka)" :value="item.il" name="Il" />
                            <p class="select-box__input-text">{{item.il}}</p>
                        </div>
                        </template>
                        
                        <div class="select-box__value">
                            <input class="select-box__input" type="radio" id="0" value="0" name="Il" checked="checked"/>
                            <p class="select-box__input-text">İl Seçiniz</p>
                        </div>
                        <img class="select-box__icon" src="images/icn-down.png" alt="Arrow Icon" aria-hidden="true"/>
                    </div>
                    <ul class="select-box__list">
                        <li><label class="select-box__option" for="0" aria-hidden="aria-hidden">İl Seçiniz</label></li>
                        <li v-for="(il, index) in iller" :key="index+'il2'">
                        <label class="select-box__option" @click="ilceleriGetir(il.plaka)" :for="il.plaka" aria-hidden="aria-hidden">{{il.il}}</label>
                        </li>
                        
                    </ul>
                </div>
            </div>
            <div class="iller yarimText">
                <div class="select-box">
                    <div class="select-box__current" tabindex="3">
                        <template v-for="(item, index) in ilceler">
                            <div class="select-box__value"  :key="index+'ilce'">
                            <input class="select-box__input" type="radio" :id="'ilce'+index" @change="ilceKayit(item)"  :value="item" name="Ilce"/>
                            <p class="select-box__input-text">{{item}}</p>
                        </div>
                        </template>
                        
                        <div class="select-box__value">
                            <input class="select-box__input" type="radio" id="0" value="0" name="Ilce" checked="checked"/>
                            <p class="select-box__input-text">İlçe Seçiniz</p>
                        </div>
                        <img class="select-box__icon" src="images/icn-down.png" alt="Arrow Icon" aria-hidden="true"/>
                    </div>
                    <ul class="select-box__list" id="ilceLabelList">
                        <li><label class="select-box__option" for="ilceSecim" aria-hidden="aria-hidden">İlçe Seçiniz</label></li>
                        <li v-for="(i, index) in ilceler" :key="index+'ilce2'">
                        <label class="select-box__option"  :for="'ilce'+index" aria-hidden="aria-hidden">{{i}}</label>
                        </li>
                        
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>    
</template>

<script>
import {TheMask} from 'vue-the-mask'
import ililce from '../ililce'
export default {
    name:"FaturaForm",
    data(){
        return{
            iller:[],
            ilceler:[],
            selected:0,
            ilSecildi:false,
            ad:"",
            soyad:"",
            tcNo:"",
            telefon:"",
            postaKodu:null,
            faturaAdres:"",
            dogumTarihi:null,
            detailVisable:false,
            tcGoster:false,
            kurumsalMi:false,
            vergiNo:null,
            vergiDaire:"",
            unvan:null,
            email: '',
            cinsiyet:'0',
            kimler:[],

            sehir:"",
            sehirPlaka:0,
            ilce:"",
            ilceId:null,

            errorAd:false,
            errorSoyad:false,
            errorTelefon:false,
            errorMail:false,

            errorTcNo:false,
            errorCinsiyet:false,
            errorDogumTarihi:false,
            errorAdres:false,

            errorIl:false,
            errorIlce:false,

            errorVergiNo:false,
            errorVergiDaire:false,
            errorUnvan:false,


        }
    },
     mounted(){

        console.log('MOUNTED')

        console.log('this.$root', this.$root.tamam)

        this.kimler.push(localStorage.kisiler)
        this.ad=JSON.parse(this.kimler[0])[0].ad
        this.soyad=JSON.parse(this.kimler[0])[0].soyad
        this.telefon= localStorage.telefon||JSON.parse(this.kimler[0])[0].telefon
        this.email = localStorage.email || ''

        this.tcNo = localStorage.tcNo || ''
        this.cinsiyet = localStorage.cinsiyet || '0'
        this.dogumTarihi = localStorage.dogumTarihi || null
        this.faturaAdres = localStorage.faturaAdres || ''

        this.sehir = localStorage.il || ''
        this.sehirPlaka = localStorage.ilPlaka || 0
        this.ilce = localStorage.ilce || ''
        this.ilceId = localStorage.ilceId || 0

        this.vergiNo = localStorage.vergiNo || null
        this.vergiDaire = localStorage.vergiDaire || ""
        this.unvan = localStorage.unvan || null

        localStorage.faturaAd = this.ad
        localStorage.faturaSoyad = this.soyad
        localStorage.telefon = this.telefon

        this.faturaChecked(localStorage.fatura)
        
     },
     updated(){
        this.formDoldur(localStorage.fatura);
        
     },
    created(){
        this.iller=ililce;
        this.$root.$refs.childref = this;
        
        console.log('CREATED')
/*
        if(this.ad.length < 3){ this.errorAd = true }
        if(this.soyad.length < 3){ this.errorSoyad = true}
        if(this.telefon.length < 10){ this.errorTelefon = true}
        if(this.email.length < 8){ this.errorMail = true}
        
        if(this.tcNo.length < 11){ this.errorTcNo = true}
        if(this.errorCinsiyet === '0'){ this.errorCinsiyet = true}
*/
        
    }, 
    methods: {
    
    validateEmail(email){
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    faturaChecked(type) {

        console.log('faturaChecked RUN', type)

        if(type === 'Bireysel Fatura') { 
            document.getElementById("f2").click();
            this.bireysel()
        }
        else if(type  === 'Kurumsal Fatura') { 
            document.getElementById("f1").click();
            this.kurumsal()
        }
        else if(type === 'Fatura İstemiyorum') { 
            document.getElementById("f0").click();
            this.faturaYok()                        
        } 
        this.faturaSec(type)
        // this.formDoldur(type)

    },
    formDoldur(type) {

        console.log('formDoldur RUN')


        if(this.ad.length < 3){ this.errorAd = true }
        if(this.soyad.length < 3){ this.errorSoyad = true}
        if(this.telefon.length < 10){ this.errorTelefon = true}
        if(!this.validateEmail(this.email)){ this.errorMail = true}


        console.log('form doldur', type, this.ad, this.soyad, this.email, this.telefon)
        console.log('form doldur 2', this.tcNo, this.cinsiyet, this.dogumTarihi)
        console.log('form doldur 3', this.faturaAdres, this.sehir, this.ilce, this.vergiNo, this.vergiDaire, this.unvan)
        
        if(type === 'Bireysel Fatura'){            
            if(this.tcNo.length < 11){ 
                this.errorTcNo = true
            } else {
                this.errorTcNo = false
            }
            if(!this.dogumTarihi || this.dogumTarihi.length < 10) {
                this.errorDogumTarihi = true // error
            } else {
                this.errorDogumTarihi = false
            }

        }
        
        if(type === 'Bireysel Fatura' || type  === 'Kurumsal Fatura') { 
            

            if(this.cinsiyet === '1' || this.cinsiyet === '2') { 
                this.errorCinsiyet = false
            } else {
                this.errorCinsiyet = true
            }

            if(this.faturaAdres === '' || !this.faturaAdres) {
                this.errorAdres = true // error
            } else {
               this.errorAdres = false
            }

            if(this.sehir === '' || !this.sehir) {
                this.errorIl = true // error
            } else {
               this.errorIl = false
               
                setTimeout(() => {
                    if(document.getElementById(this.sehirPlaka)) {
                        document.getElementById(this.sehirPlaka).click();
                    }
                }, 500)               

               this.ilceleriGetir(this.sehirPlaka)

            }
            
            if(!this.ilce) {
                this.errorIlce = true
            } else {
               this.errorIlce = false
               
            }
        }

        if(type  === 'Kurumsal Fatura') { 
            if(!this.vergiNo) {
                this.errorVergiNo = true // error
            } else {
               this.errorVergiNo = false
            }            

            if(!this.vergiDaire) {
                this.errorVergiDaire = true // error
            } else {
               this.errorVergiDaire = false
            }          

            if(!this.unvan) {
                this.errorUnvan = true // error
            } else {
               this.errorUnvan = false
            }            

        }

        this.checkrequireds()

    },





    checkrequireds(){

        console.log('checkrequireds-localStorage.fatura',localStorage.fatura)

        const faturaValidType = localStorage.fatura;
        let faturaType = 'yok';
        if(faturaValidType === 'Bireysel Fatura') {
            faturaType = 'bireysel';
             console.log('bireysel atama')
        } else if(faturaValidType === 'Kurumsal Fatura') {
            faturaType = 'kurumsal';
             console.log('kurumsal atama')
        }

        console.log('checkrequireds-faturaType', faturaType)


        // if(this.detailVisable){  this.$root.tamam=true }

        if(faturaType === 'yok') {

            if(this.errorAd || this.errorSoyad || this.errorTelefon || this.errorMail){
                this.$root.tamam=false
            } else {
                this.$root.tamam=true                
            }


        }
        else if (faturaType === 'bireysel') {

            console.log('checkrequireds-bireysel-run')

            if(
                this.errorAd || this.errorSoyad || this.errorTelefon || this.errorMail || 
                this.errorTcNo || this.errorCinsiyet || this.errorDogumTarihi || this.errorAdres || this.errorIl || this.errorIlce) 
            {
                this.$root.tamam=false
            } else {
                this.$root.tamam=true
            }

            

        }
        else if (faturaType === 'kurumsal') {

            if(
                this.errorAd || this.errorSoyad || this.errorTelefon || this.errorMail || 
                this.errorCinsiyet || this.errorDogumTarihi || this.errorAdres || this.errorIl || this.errorIlce || 
                this.errorVergiNo || this.errorVergiDaire || this.errorUnvan ) 
            {
                this.$root.tamam=false
                console.log('KURUMSAL 11111')
            } else {
                this.$root.tamam=true
                console.log('KURUMSAL 22222')
            }

        }
        
        
/*        
        if(this.errorAd && this.errorSoyad && this.errorTelefon && this.errorMail){
            this.$root.tamam=false
        } else {
            this.$root.tamam=true
        }
*/

        console.log('checkrequireds-err', this.errorAd  , this.errorSoyad , this.errorTelefon , this.errorMail)
        console.log('checkrequireds-err BIREYSEK', this.errorTcNo, this.errorCinsiyet, this.errorDogumTarihi, this.errorAdres, this.errorIl, this.errorIlce)
        console.log('checkrequireds-err KURUMSAL', this.errorVergiNo, this.errorVergiDaire, this.errorUnvan)

        console.log('this.$root.tamam', this.$root.tamam)
    },
    ilceleriGetir(index){
        this.iller.find(item => {
            if(item.plaka == index){
                this.ilceler = item.ilceleri
            }
        });
        
        if(localStorage.ilce) {
            setTimeout(() => {
                $('#ilceLabelList label').each(function( index ) {
                    if($(this).text() === localStorage.ilce){
                        $(this).click(); 
                    } else {
                        $('#ilceLabelList label[for="ilceSecim"]').click()
                        // localStorage.removeItem('ilce'); console.log('ILCE SEC 22222')
                    }
                });           
            }, 1000)   
        }


        // this.ilce = null
    },
    kurumsal(){
        this.detailVisable = true
        this.tcGoster = false
        this.kurumsalMi = true
        //this.$root.tamam=false

        // this.$root.tamam=true
        // console.log('kurumsal', this.$root.tamam)

        //this.checkrequireds()
    },
    bireysel(){
        this.detailVisable = true
        this.tcGoster = true
        this.kurumsalMi = false
        //this.$root.tamam=false

        // this.$root.tamam=true
        // console.log('bireysel', this.$root.tamam)

        //this.checkrequireds()
    },
    faturaYok(){
        this.detailVisable = false
        this.tcGoster = false
        this.kurumsalMi = false
        // this.$root.tamam=true
        // console.log('yok', this.$root.tamam)

        //this.checkrequireds()
    },
    faturaSec(e){
        localStorage.fatura=e
        this.$root.fatura.push({faturaTipi: e});
        
        console.log('faturaSec RUN', e)
        this.formDoldur(e);

        
    },
    cinsiyetSec(e){
        this.cinsiyet=e
        localStorage.cinsiyet=e
        if(this.cinsiyet === '0'){ this.errorCinsiyet = true} else {this.errorCinsiyet = false}
        this.checkrequireds()
    },
    adKayit(ad){
        localStorage.faturaAd=ad
        this.$root.fatura.push({ad: ad});
        if(this.ad.length < 3){ this.errorAd = true} else {this.errorAd = false}
        this.checkrequireds()
    },
    soyadKayit(soyad){
        localStorage.faturaSoyad=soyad
        this.$root.fatura.push({soyad: soyad});
        if(this.soyad.length < 3){ this.errorSoyad = true} else {this.errorSoyad = false}
        this.checkrequireds()
    },
    tcNoKayit(tcNo){
        localStorage.tcNo=tcNo
        this.$root.fatura.push({tcNo: tcNo});

        document.getElementById('notTC').checked = false;
        if(tcNo.length < 11){ this.errorTcNo = true}

        this.checkrequireds()
    },
    turkDegil(e){
        localStorage.tcNo=e
        this.tcNo = e
        this.$root.fatura.push({tcDegil: e});
        this.checkrequireds()
    },
    telefonKayit(telefon){
        localStorage.telefon=telefon.replace(/[^0-9]/g, '').substring(2)
        this.$root.fatura.push({telefon: telefon.replace(/[^0-9]/g, '').substring(2)});
        if(localStorage.telefon.length < 10){ this.errorTelefon = true} else { this.errorTelefon = false}
        this.checkrequireds()
    },
    emailKayit(email){
        localStorage.email=email
/*         
        var eposta = this.email;
        var atpos=eposta.indexOf("@");
        var dotpos=eposta.lastIndexOf(".");
        if(atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= eposta.length){ this.errorMail = true}
*/        
        if(!this.validateEmail(email)) 
        { this.errorMail = true }
        else
        { this.errorSilMail()}
        
        console.log('this.errorMail', this.errorMail);
        
        this.checkrequireds()
    },
    dogumTarihiKayit(dogumTarihi) {
        localStorage.dogumTarihi = dogumTarihi
        this.dogumTarihi = dogumTarihi
        if(!this.dogumTarihi || this.dogumTarihi.length < 10){ this.errorDogumTarihi = true}
        this.checkrequireds()
    },


    postaKoduKayit(postaKodu){
        localStorage.postaKodu=postaKodu
        this.$root.fatura.push({postaKodu: postaKodu});
    },
    adresKayit(faturaAdres){
        localStorage.faturaAdres=faturaAdres
        this.faturaAdres = faturaAdres
        this.$root.fatura.push({adres: faturaAdres});
        if(this.faturaAdres === '' || !this.faturaAdres){ this.errorAdres = true}
        this.checkrequireds()

        console.log('this.faturaAdres', this.faturaAdres)

    },
    ilKayit(e, plaka){
        
        localStorage.il=e
        localStorage.ilPlaka=plaka
        this.$root.fatura.push({il: e});
        this.sehir = e
        this.sehirPlaka = plaka
        if(this.sehir) { this.errorIl = false } else { this.errorIl = true;  }

        this.ilceleriGetir(plaka)
        this.checkrequireds()
    },
    ilceKayit(e){
        localStorage.ilce=e
        this.ilce = e
        this.$root.fatura.push({ilce: e});
        if(this.ilce) { this.errorIlce = false } else { this.errorIlce = true;  }

        this.checkrequireds()
    },
    vergiNoKayit(vergiNo){
        localStorage.vergiNo = vergiNo
        this.vergiNo = vergiNo
        this.$root.fatura.push({vergiNo: vergiNo});
        if(this.vergiNo) { this.errorVergiNo = false } else { this.errorVergiNo = true }
        this.checkrequireds()
    },
    vergiDaireKayit(vergiDaire){
        localStorage.vergiDaire = vergiDaire
        this.vergiDaire = vergiDaire
        this.$root.fatura.push({vergiDaire: vergiDaire});
        if(this.vergiDaire) { this.errorVergiDaire = false } else { this.errorVergiDaire = true }
        this.checkrequireds()
    },
    unvanKayit(unvan){
        localStorage.unvan=unvan
        this.unvan = unvan
        this.$root.fatura.push({unvan: unvan});
        if(this.unvan) { this.errorUnvan = false } else { this.errorUnvan = true }
        this.checkrequireds()
    },
    errorSilAd:function(){ this.errorAd=false},
    errorSilSoyad:function(){ this.errorSoyad=false},
    // errorSilTelefon:function(){ this.errorTelefon=false},
    errorSilMail:function(){ this.errorMail=false},
    errorSilTcNo:function(){ this.errorTcNo=false},
    errorSilDogumTarihi:function(){ this.errorDogumTarihi=false}
    },
    components:{
        TheMask
    }
}
</script>
