<template>
    <div>
        <div class="bgSiyah">
            <div class="container">
                <div class="girisYapCol">
                    <div class="girisYapButons">
                        <h1>HIZLI GİRİŞ YAP</h1>
                        <div class="loginButons">
                            <a href="" class="googleGiris">
                                <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Güzel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="8-Giriş-Yap" transform="translate(-468.000000, -220.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                            <g id="Group-3" transform="translate(468.000000, 218.000000)">
                                                <g id="icn-google" transform="translate(0.000000, 2.000000)">
                                                    <path d="M15.9776931,7.10929101 L8.52143915,7.10929101 L8.52143915,9.59470899 L13.0704762,9.59470899 C12.391873,11.7554709 10.3735873,13.322836 7.98878307,13.322836 C5.04732275,13.322836 2.6629418,10.9382857 2.6629418,7.99695238 C2.6629418,5.05549206 5.04740741,2.67111111 7.98878307,2.67111111 C9.47001058,2.67111111 10.8097354,3.27593651 11.7751111,4.25189418 L13.5792169,2.29092063 C12.1383704,0.879068783 10.1654603,0.00821164021 7.98878307,0.00821164021 C3.57671958,0.00821164021 0,3.58488889 0,7.99695238 C0,12.4090159 3.57671958,15.9858201 7.9888254,15.9858201 C11.853672,15.9858201 15.237418,13.2411005 15.9776931,9.59475132 L15.9776931,7.10933333 L15.9776931,7.10929101 L15.9776931,7.10929101 Z" id="Path"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <span>Google</span>
                            </a>
                            <a href="" class="facebookGiris">
                                <svg width="9px" height="16px" viewBox="0 0 9 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Güzel" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="8-Giriş-Yap" transform="translate(-760.000000, -220.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                            <g id="Group-2" transform="translate(760.000000, 218.000000)">
                                                <g id="icn-facebook" transform="translate(0.000000, 2.000000)">
                                                    <path d="M8.26210309,0.00329896907 L6.20602062,0 C3.89608247,0 2.40329897,1.53154639 2.40329897,3.90202062 L2.40329897,5.7011134 L0.336,5.7011134 C0.157360825,5.7011134 0.0127010309,5.84593814 0.0127010309,6.02457732 L0.0127010309,8.63125773 C0.0127010309,8.80989691 0.157525773,8.9545567 0.336,8.9545567 L2.40329897,8.9545567 L2.40329897,15.5320412 C2.40329897,15.7106804 2.54795876,15.8553402 2.72659794,15.8553402 L5.42383505,15.8553402 C5.60247423,15.8553402 5.74713402,15.7105155 5.74713402,15.5320412 L5.74713402,8.9545567 L8.16428866,8.9545567 C8.34292784,8.9545567 8.48758763,8.80989691 8.48758763,8.63125773 L8.48857732,6.02457732 C8.48857732,5.93880412 8.45443299,5.85665979 8.39389691,5.79595876 C8.33336082,5.73525773 8.2508866,5.7011134 8.1651134,5.7011134 L5.74713402,5.7011134 L5.74713402,4.176 C5.74713402,3.44296907 5.92181443,3.07084536 6.87670103,3.07084536 L8.2617732,3.07035052 C8.44024742,3.07035052 8.58490722,2.92552577 8.58490722,2.74705155 L8.58490722,0.326597938 C8.58490722,0.14828866 8.44041237,0.00362886598 8.26210309,0.00329896907 Z" id="Path"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <span>Facebook</span>
                            </a>
                        </div>
                    </div>
                    
                    <div class="yada">ya da</div>
                    <h2>GİRİŞ YAP</h2>
                    <div class="girisYapFormu">
                        <div class="row">
                            <div class="form-group">
                                <input type="text" v-model="email" name="Eposta" required="required" autocomplete="off" :class="{'inputError': hasError}" />
                                <label for="input" class="control-label">E-posta</label><i class="bar"></i>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group">
                                <input type="password" v-model="password" name="Sifre" required="required" autocomplete="off" :class="{'inputError': hasError}"/>
                                <label for="input" class="control-label">Parola</label><i class="bar"></i>
                            </div>
                        </div>
                        <div class="row"><a href="/sifremi-unuttum" class="unuttum">Şifremi unuttum</a></div>
                        <span v-if="hasError" style="display:block; color:red; margin-bottom:10px;">Bu alanlar boş bırakılamaz.</span>
                        <div class="row">
                            <a class="girisBtn" @click="giris"><span>GİRİŞ YAP</span></a>
                        </div>
                    </div>
                </div>
                <div class="biletlerimSag kayitOlCol">
                    <h2>UÇUŞA GEÇMEK İÇİN BİZE KATIL!</h2>
                    <a href="/kayit-ol" class="odemeBtn"><span>HEMEN KAYIT OL</span></a>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from './Footer'
import axios from "axios";
export default {
    name:'GirisYap',
    components:{
        Footer
    },
    data(){
        return {
            hasError: false,
            email: '',
            password: ''
        }
    },
    methods:{
        giris(){
            
            if(this.email === '' || this.password === ''){
                this.hasError = true;
            } else {
                this.hasError = false;
                let userInfo = {email: this.email, password: this.password};
                console.log(userInfo);
                const params = new URLSearchParams();
                params.append('Username', this.email);
                params.append('Password', this.password);
                axios({
                    url: `${process.env.VUE_APP_ENV_SERVER}/Account/LoginB2C`,
                    method:'post',
                    data:params,
                    headers: {
                    }
                }).then((response)=>{
                    var aspauth=response.data.aspNetAuth
                    axios({
                        url: `${process.env.VUE_APP_ENV_SERVER}/Services/FlyzoneCrm/B2CServices/GetContentB2C`,
                        method:"post",
                        headers:{
                            "Content-Type":"application/x-www-form-urlencoded",
                            // Cookie:".AspNetAuth="+aspauth
                            
                        }
                    }).then((response2)=>{ 
                        localStorage.setItem("data",response2.data)

                    })
                })
            }
        }
    }
}

</script>