<template>
  <div>
    <div class="load" v-if="isLoading">
      <div class="loadImgs">
        <img src="images/loading-logo.png" alt="" />
        <img src="images/loading.png" class="loadImg" alt="" />
      </div>
    </div>
    <NeredeData :where="where"></NeredeData>
  </div>
</template>

<script>



import NeredeData from "./NeredeData"
import data from "../data"
export default {
  name: "Nerede",
  components: { NeredeData },
  data() {
    return {
      isLoading: true,
      where: data.where,
    }
  },
  created() {
    window.localStorage.clear()

    this.isLoading = false
    this.$root.menuActive1 = true
  },
  mounted() {
    document.body.style.backgroundColor ='black'
  },
  beforeDestroy() {
    document.body.style.backgroundColor =''
  }
}
</script>
